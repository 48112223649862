import React, { useState, useEffect } from 'react';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import MacroDistributionChart from '../../../makroChart';
//import { ClientCard } from '../../../pages/specificMealPlanPage';
import CheckmarkIcon from '../../checkmarkIcon';
import BackUpImage from '../../../../images/mat.png';
import { GetMealsAndInstances } from '../../../../hooks/newHooks/coachSide/createMealPlanQueries/getMealsAndInstances';
import styles from './styles.module.scss';

export default function EditMealPlanForm({ initialData, coachId, onSubmitSuccess, planId }) {

    console.log(planId);
    
  // Setter opp initial state basert på initialData hvis den finnes
  const [name, setName] = useState(initialData?.name || '');
  const [description, setDescription] = useState(initialData?.description || '');
  const [goal, setGoal] = useState(initialData?.goal || '');
  const [duration, setDuration] = useState(initialData?.duration || '');
  const [notes, setNotes] = useState(initialData?.notes || '');
  //eslint-disable-next-line
  const [tags, setTags] = useState(initialData?.tags || []);
  const [allMeals, setAllMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //eslint-disable-next-line
  const [selectedClients, setSelectedClients] = useState(initialData?.clients || []);
  //const [clientSearchTerm, setClientSearchTerm] = useState('');
  // Her forventer vi at initialData har property 'meal_slot' med den eksisterende strukturen
  const [mealSlots, setMealSlots] = useState(initialData?.meal_slot || []);

  // const filteredClients = clients.filter(client =>
  //   client.attributes.username.toLowerCase().includes(clientSearchTerm.toLowerCase())
  // );

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      try {
        const mealData = await GetMealsAndInstances(coachId);
        console.log('mealData:', mealData);
        setAllMeals(mealData.coach.data.attributes.meals.data);
        //setClients(mealData.coach.data.attributes.clients.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Feil ved henting av Meals og Instances:", error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [coachId]);

  const addMealSlot = () => {
    const newSlotIndex = mealSlots.length;
    setMealSlots([
      ...mealSlots,
      {
        meal_name: `Måltid ${newSlotIndex + 1}`,
        selectedMealsAndInstances: [],
        mealSearchTerm: ''
      }
    ]);
  };

  const updateMealSlot = (index, updatedSlot) => {
    const updatedSlots = [...mealSlots];
    updatedSlots[index] = updatedSlot;
    setMealSlots(updatedSlots);
  };

  const removeMealSlot = (index) => {
    const updatedSlots = mealSlots.filter((_, i) => i !== index);
    setMealSlots(updatedSlots);
  };

  const toggleMealSelection = (mealId, slotIndex) => {
    const slot = mealSlots[slotIndex];
    // Sjekker om måltidet (enten som hovedmåltid eller med tilknyttet instance) allerede er valgt
    const alreadySelected = slot.selectedMealsAndInstances.some(
      item =>
        (item.type === 'meal' && item.id === mealId) ||
        (item.type === 'instance' && item.mealId === mealId)
    );

    let updatedSelected = [...slot.selectedMealsAndInstances];

    if (alreadySelected) {
      // Fjern alle valg for dette måltidet
      updatedSelected = updatedSelected.filter(
        item =>
          !(
            (item.type === 'meal' && item.id === mealId) ||
            (item.type === 'instance' && item.mealId === mealId)
          )
      );
    } else {
      // Fjern eventuelle instance-valg for dette måltidet og legg til hovedmåltidet
      updatedSelected = updatedSelected.filter(
        item => !(item.type === 'instance' && item.mealId === mealId)
      );
      updatedSelected.push({ id: mealId, type: 'meal' });
    }
    updateMealSlot(slotIndex, { ...slot, selectedMealsAndInstances: updatedSelected });
  };

  const computePlanMacros = () => {
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;
    let totalKcals = 0;

    mealSlots.forEach((slot) => {
      if (slot.selectedMealsAndInstances && slot.selectedMealsAndInstances.length > 0) {
        // Bruk kun det første valget i sloten for beregning
        const firstSelection = slot.selectedMealsAndInstances[0];

        if (firstSelection.type === 'instance') {
          const mealWithInstance = allMeals.find((meal) =>
            meal.attributes.meal_instances?.data.some((inst) => inst.id === firstSelection.id)
          );
          if (mealWithInstance) {
            const instance = mealWithInstance.attributes.meal_instances.data.find(
              (inst) => inst.id === firstSelection.id
            );
            if (instance) {
              totalProtein += instance.attributes.proteins || 0;
              totalCarbs += instance.attributes.carbs || 0;
              totalFat += instance.attributes.fats || 0;
              totalKcals += instance.attributes.kcals || 0;
            }
          }
        } else if (firstSelection.type === 'meal') {
          const meal = allMeals.find((m) => m.id === firstSelection.id);
          if (meal) {
            totalProtein += meal.attributes.proteins || 0;
            totalCarbs += meal.attributes.carbs || 0;
            totalFat += meal.attributes.fats || 0;
            totalKcals += meal.attributes.kcals || 0;
          }
        }
      }
    });

    return {
      totalProtein: Math.round(totalProtein),
      totalCarbs: Math.round(totalCarbs),
      totalFat: Math.round(totalFat),
      totalKcals: Math.round(totalKcals),
    };
  };

  const planMacros = computePlanMacros();

  // const toggleClientSelection = (clientId) => {
  //   setSelectedClients((prev) => {
  //     if (prev.includes(clientId)) {
  //       return prev.filter((id) => id !== clientId);
  //     } else {
  //       return [...prev, clientId];
  //     }
  //   });
  // };

  const toggleInstanceSelection = (instanceId, mealId, slotIndex) => {
    const slot = mealSlots[slotIndex];
    const alreadySelected = slot.selectedMealsAndInstances.some(
      item => item.type === 'instance' && item.id === instanceId
    );

    let updatedSelected = [...slot.selectedMealsAndInstances];

    if (alreadySelected) {
      updatedSelected = updatedSelected.filter(
        item => !(item.type === 'instance' && item.id === instanceId)
      );
      const hasOtherInstances = updatedSelected.some(
        item => item.type === 'instance' && item.mealId === mealId
      );
      if (!hasOtherInstances && !updatedSelected.some(item => item.type === 'meal' && item.id === mealId)) {
        updatedSelected.push({ id: mealId, type: 'meal' });
      }
    } else {
      updatedSelected = updatedSelected.filter(
        item => !((item.type === 'meal' && item.id === mealId) || 
                  (item.type === 'instance' && item.mealId === mealId))
      );
      updatedSelected.push({ id: instanceId, type: 'instance', mealId });
    }
    updateMealSlot(slotIndex, { ...slot, selectedMealsAndInstances: updatedSelected });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !description) {
      alert('Navn og beskrivelse er obligatorisk!');
      return;
    }
    setIsLoading(true);

    const transformedMealSlots = mealSlots.map((slot) => {
      const meals = slot.selectedMealsAndInstances
        ? slot.selectedMealsAndInstances
            .filter(item => item.type === 'meal')
            .map(item => item.id)
        : [];
      const meal_instances = slot.selectedMealsAndInstances
        ? slot.selectedMealsAndInstances
            .filter(item => item.type === 'instance')
            .map(item => item.id)
        : [];
      return {
        meal_name: slot.meal_name,
        meals,          
        meal_instances, 
      };
    });

    const mealPlanData = {
      name,
      description,
      goal,
      duration,
      protein: planMacros.totalProtein,
      carbs: planMacros.totalCarbs,
      fat: planMacros.totalFat,
      total_kcals: planMacros.totalKcals,
      notes,
      tags,
      coach: coachId ? coachId : null,
      meal_slot: transformedMealSlots,
      clients: selectedClients,
    };

    try {
      // Bruk PATCH for å oppdatere den eksisterende matplanen
      const updateResponse = await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${planId}`,
        { data: mealPlanData },
        'PUT'
      );
      console.log('Oppdatert MealPlan:', updateResponse);
      alert('Matplanen er oppdatert!');
      if (onSubmitSuccess) {
        onSubmitSuccess(updateResponse.data);
      }
    } catch (error) {
      console.error('Feil ved oppdatering av MealPlan:', error);
      alert('Kunne ikke oppdatere matplanen. Sjekk konsollen for mer info.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`${styles.wrapper} ${styles.form} col-12 d-flex flex-column align-items-center`}>
      {isLoading && (<div className={styles.lasteTekst}>Laster...</div>)}

      {/* Renderer mealSlots – her kan du se og endre de eksisterende måltidene/variantene */}
      {mealSlots.map((slot, slotIndex) => {
        const slotFilteredMeals = allMeals.filter((meal) =>
          meal.attributes.name.toLowerCase().includes(slot.mealSearchTerm.toLowerCase())
        );
        return (
          <div key={slotIndex} className={styles.categorySection}>
            <div className={`col-12 d-flex flex-column align-items-center`}>
              <input
                type="text"
                placeholder="Navn på måltid"
                value={slot.meal_name}
                onChange={(e) => {
                  const updatedSlot = { ...slot, meal_name: e.target.value };
                  updateMealSlot(slotIndex, updatedSlot);
                }}
                className={`${styles.input} col-10 p-2 rounded-4 my-3`}
              />
              <input
                type="text"
                placeholder="Søk etter måltid ..."
                value={slot.mealSearchTerm}
                onChange={(e) => {
                  const updatedSlot = { ...slot, mealSearchTerm: e.target.value };
                  updateMealSlot(slotIndex, updatedSlot);
                }}
                className={`${styles.input} col-10 p-2 rounded-4 my-3`}
              />
            </div>
            {/* Liste over måltider */}
            <div className={styles.horizontalScrollContainer}>
              {slotFilteredMeals.map((meal) => {
                const mealId = meal.id;
                const mealName = meal.attributes.name;
                const mealSelected = slot.selectedMealsAndInstances.some(
                  (item) => item.type === 'meal' && item.id === mealId
                );
                const instanceSelected = meal.attributes.meal_instances?.data?.some((inst) =>
                  slot.selectedMealsAndInstances.some(
                    (item) => item.type === 'instance' && item.id === inst.id
                  )
                );
                const isSelected = mealSelected || instanceSelected;
                const mealImages = meal.attributes.meal_images?.data;
                const imageUrl =
                  mealImages && mealImages.length > 0
                    ? mealImages[0]?.attributes?.image?.data?.attributes?.url
                    : BackUpImage;
                const backgroundGradient = isSelected
                  ? 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))'
                  : 'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0))';
                return (
                  <div key={mealId} onClick={() => toggleMealSelection(mealId, slotIndex)}>
                    <div 
                      className={`${styles.mealCard} ${isSelected ? styles.selected : ""}`}
                      style={{
                        backgroundImage: `${backgroundGradient}, url(${imageUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    >
                      {isSelected && (
                        <div className={styles.overlayCheck}>
                          <span className={styles.checkmark}><CheckmarkIcon/></span>
                        </div>
                      )}
                    </div>
                    <h4 className="text-center mt-2">{mealName}</h4>
                    {/* Viser varianter om måltidet har noen */}
                    {isSelected && meal.attributes.meal_instances?.data?.length > 0 && (
                      <div className={styles.instanceList}>
                        <strong>Varianter:</strong>
                        {meal.attributes.meal_instances.data.map((instance) => {
                          const instanceId = instance.id;
                          const isInstanceSelected = slot.selectedMealsAndInstances.some(
                            (item) => item.type === 'instance' && item.id === instanceId
                          );
                          return (
                            <div
                              key={instanceId}
                              className={`${styles.instanceItem} ${isInstanceSelected ? styles.instSelected : ""}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleInstanceSelection(instanceId, mealId, slotIndex);
                              }}
                            >
                              <span>{instance.attributes.variant_name}</span>
                              {isInstanceSelected && (
                                <span className={styles.instanceCheckmark}><CheckmarkIcon/></span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={`col-12 d-flex flex-column align-items-center mt-2`}>
              <button className={`${styles.removeButton} col-8`} onClick={() => removeMealSlot(slotIndex)}>
                Fjern måltid
              </button>
            </div>
          </div>
        );
      })}
  
      <button className={`${styles.submitButton} col-10`} onClick={addMealSlot}>
        Legg til nytt måltid
      </button>
  
      {/* Vis makrooversikten */}
      <div className={`${styles.macroOverview} d-flex flex-column align-items-center`}>
        <h3 className="text-center">Daglig Makrooversikt</h3>
        <MacroDistributionChart 
          protein={planMacros.totalProtein}
          carbs={planMacros.totalCarbs}
          fat={planMacros.totalFat}
          kcals={planMacros.totalKcals}
        />
        <p>Protein: {planMacros.totalProtein} g</p>
        <p>Karbohydrater: {planMacros.totalCarbs} g</p>
        <p>Fett: {planMacros.totalFat} g</p>
        <p>Kalorier: {planMacros.totalKcals} kcal</p>
      </div>
  
      {/* Skjema for øvrig informasjon */}
      <form className={`${styles.form} col-10 col-md-12 d-flex flex-column align-items-center`} onSubmit={handleSubmit}>
        <input
          className={`${styles.input} col-10 p-2 rounded-4`}
          type="text"
          placeholder="Navn på planen"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <textarea
          className={`${styles.textArea} col-10 p-2 rounded-4`}
          placeholder="Beskrivelse"
          rows={6}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          className={`${styles.input} col-10 p-2 rounded-4`}
          type="text"
          placeholder="Mål (f.eks vektnedgang, muskelbygging)"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        />
        <input
          className={`${styles.input} col-10 p-2 rounded-4`}
          type="text"
          placeholder="Varighet (f.eks. 4 uker)"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        />
        <textarea
          className={`${styles.input} col-10 p-2 rounded-4`}
          placeholder="Notater (internt)"
          value={notes}
          rows={6}
          onChange={(e) => setNotes(e.target.value)}
        />
        <button className={`${styles.submitButton} col-10`} type="submit">
          Oppdater matplan
        </button>
      </form>
    </div>
  );
}

