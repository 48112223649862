// import { useState, useEffect} from 'react';
// import { getLocalStorageItem } from '../../components/global/storage';
// import { API_GRAPHQL } from '../../constants';
// import { axiosAuthFetch } from '../../helpers/axios';

// export function useClientInformation() {
//     const user = getLocalStorageItem('limitless-user');
//     const coachID = user.user.coachID;
//     const url = API_GRAPHQL;
//     const [isLoading, setIsLoading] = useState(false);
//     const [isError, setIsError] = useState(false);
//     const [clients, setClients] = useState([]);
//     const [sessions, setSessions] = useState([]);
//     const [checkIns, setCheckIns] = useState([]);

//     useEffect(() => {
//         async function getPrograms(url) {

//             const query = `query {
//                 workoutSessions(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: { limit: 5 }, sort:"date_performed:desc"){
//                     data{
//                         id
//                         attributes{
//                             date_performed
//                             client{
//                                 data{
//                                     id
//                                     attributes{
//                                         username
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 checkIns(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: {limit: 5}, sort:"date:desc"){
//                     data{
//                         id
//                         attributes{
//                             date
//                             client{
//                                 data{
//                                     id
//                                     attributes{
//                                         username
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 coach(id:${coachID}){
//                     data{
//                         id
//                         attributes{
//                             clients(pagination: { limit: 100 }) {
//                                 data{
//                                     id
//                                     attributes{
//                                         username
//                                         blocked
//                                         profile_picture{
//                                             data{
//                                                 id
//                                                 attributes{
//                                                     url
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }`;
//             ;
//             try {
//                 setIsError(false);
//                 setIsLoading(true);

//                 const response = await axiosAuthFetch(url, { query }, 'post')
//                 console.log(response);
//                 setSessions(response.data.workoutSessions.data);
//                 setClients(response.data.coach.data.attributes.clients.data);
//                 setCheckIns(response.data.checkIns.data);
//             } catch(error) {
//                 console.error(error);
//             }
//             finally {
//                 setIsLoading(false);
//             }
//         }

//         getPrograms(url)
//     }, [coachID, url])

//     return {clients, checkIns, sessions, isLoading, isError};

// }

import { useState, useEffect } from 'react';
import { getLocalStorageItem } from '../../components/global/storage';
import { API_GRAPHQL } from '../../constants';
import { axiosAuthFetch } from '../../helpers/axios';

export function useClientInformation() {
    const user = getLocalStorageItem('limitless-user');
    const coachID = user.user.coachID;
    const url = API_GRAPHQL;
    const LIMIT = 5;

    const [hasMoreSessions, setHasMoreSessions] = useState(true);
    const [hasMoreCheckIns, setHasMoreCheckIns] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [clients, setClients] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [checkIns, setCheckIns] = useState([]);

    // Hent de første øktene og check-ins
    useEffect(() => {
        async function getPrograms(url) {
            const query = `query {
                workoutSessions(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: { limit: ${LIMIT} }, sort:"date_performed:desc"){
                    data{
                        id
                        attributes{
                            date_performed
                            client{
                                data{
                                    id
                                    attributes{
                                        username
                                    }
                                }
                            }
                        }
                    }
                }
                checkIns(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: { limit: ${LIMIT} }, sort:"date:desc"){
                    data{
                        id
                        attributes{
                            date
                            client{
                                data{
                                    id
                                    attributes{
                                        username
                                    }
                                }
                            }
                        }
                    }
                }
                coach(id:${coachID}){
                    data{
                        id
                        attributes{
                            clients(pagination: { limit: 100 }) {
                                data{
                                    id
                                    attributes{
                                        username
                                        blocked
                                        profile_picture{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`;

            try {
                setIsError(false);
                setIsLoading(true);

                const response = await axiosAuthFetch(url, { query }, 'post');
                console.log(response);
                const fetchedSessions = response.data.workoutSessions.data;
                const fetchedCheckIns = response.data.checkIns.data;
                setSessions(response.data.workoutSessions.data);
                setClients(response.data.coach.data.attributes.clients.data);
                setCheckIns(response.data.checkIns.data);

                if (fetchedSessions.length < LIMIT) {
                    setHasMoreSessions(false);
                }
                if (fetchedCheckIns.length < LIMIT) {
                    setHasMoreCheckIns(false);
                }
            } catch (error) {
                console.error(error);
                setIsError(true);
            }
            finally {
                setIsLoading(false);
            }
        }

        getPrograms(url);
    }, [coachID, url]);

    // Funksjon for å hente flere økter
    const loadMoreSessions = async () => {
        const query = `query {
            workoutSessions(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: { limit: ${LIMIT}, start: ${sessions.length} }, sort:"date_performed:desc"){
                data{
                    id
                    attributes{
                        date_performed
                        client{
                            data{
                                id
                                attributes{
                                    username
                                }
                            }
                        }
                    }
                }
            }
        }`;

        try {
            // setIsError(false);
            // setIsLoading(true);

            const response = await axiosAuthFetch(url, { query }, 'post');
            const newSessions = response.data.workoutSessions.data;
            // Dersom vi får nye økter, legg dem til de eksisterende
            if(newSessions.length > 0) {
                setSessions(prevSessions => [...prevSessions, ...newSessions]);
            }
            if(newSessions.length < LIMIT) {
                setHasMoreSessions(false);
            }
        } catch(error) {
            console.error(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // Funksjon for å hente flere check-ins
    const loadMoreCheckIns = async () => {
        const query = `query {
            checkIns(filters: {client: {coach: {id: {eq:${coachID}}}}}, pagination: { limit: ${LIMIT}, start: ${checkIns.length} }, sort:"date:desc"){
                data{
                    id
                    attributes{
                        date
                        client{
                            data{
                                id
                                attributes{
                                    username
                                }
                            }
                        }
                    }
                }
            }
        }`;

        try {
            // setIsError(false);
            // setIsLoading(true);

            const response = await axiosAuthFetch(url, { query }, 'post');
            const newCheckIns = response.data.checkIns.data;
            if(newCheckIns.length > 0) {
                setCheckIns(prevCheckIns => [...prevCheckIns, ...newCheckIns]);
            }
            if(newCheckIns.length < LIMIT) {
                setHasMoreCheckIns(false);
            }
        } catch(error) {
            console.error(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return { clients, checkIns, sessions, isLoading, isError, loadMoreSessions, loadMoreCheckIns, hasMoreCheckIns, hasMoreSessions };
}
