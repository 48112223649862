import React, { useState, useEffect } from 'react';
import ResponsiveModal from '../../global/responsiveModal';
import CreateMealPlanForm from '../../global/forms/createMealPlan';
import { getLocalStorageItem } from '../../global/storage';
import { GetMealPlanList } from '../../../hooks/newHooks/coachSide/mealPlans';
import MealPlansList from '../../coaches/mealPlansList';
import Loader from '../../global/loader';
import styles from './styles.module.scss';
import BackButton from '../../global/backButton';

export default function MealPlanPage(){

    const [isModalOpen, setIsModalOpen] = useState(false);
    const userInfo = getLocalStorageItem('limitless-user');
    const coachId = userInfo.user.coachID;
    const [mealPlans, setMealPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        async function getMealPlans() {
            const response = await GetMealPlanList(coachId);
            console.log('MEALPLANS',response);
            setMealPlans(response);
            setIsLoading(false);
        }
        getMealPlans()
        //eslint-disable-next-line
    },[]);

    const handleModalClick = () => {
        setIsModalOpen(true);
    }
    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    if(isLoading) {
        return(
            <Loader />
        )
    }

    return(
        < div className={`${styles.container} pt-2 pb-5 `}>
            <div className="col-3 col-md-1"><BackButton/></div>
            <div className="col-12 col-md-5 m-auto">
                <div className="col-12 py-2 d-flex align-items-center justify-content-between px-3">
                    <div className={`${styles.overskrift}`}>
                        Dine matplaner
                    </div>
                    <div className={`rounded-3 px-3 shadow-lg py-2 ${styles.lagNy}`} onClick={handleModalClick}>Lag ny plan</div>
                </div>
                <div>
                    {mealPlans.length > 0 ? (
                        mealPlans.map((mealplan) => (
                            <MealPlansList key={mealplan.id} mealplan={mealplan}/>
                        ))
                    ): (
                        <div>Ingen matplaner tilgjengelig</div>
                    )}
                    
                </div>
            </div>
            <ResponsiveModal isOpen={isModalOpen} onClose={handleCloseModal}>
                <CreateMealPlanForm />
            </ResponsiveModal>
        </div>
    )
}