import React, { useState } from 'react';
import styles from './styles.module.scss';
import Loader from '../../../global/loader';
//import {ReactComponent as RightArrow} from '../../../../images/icons/right-arrow.svg';
import { GetSingleCheckInInfo } from '../../../../hooks/newHooks/coachSide/fetchClientCheckIn';
import CheckInDetails from '../checkInDetails';
import ResponsiveModal from '../../../global/responsiveModal';

export default function SpecificClientCheckInList({ checkIn }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCheckIn, setSelectedCheckIn] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleCheckInClick = async () => {
        setIsLoading(true);
        try {
            const checkInData = await GetSingleCheckInInfo(checkIn.id);
            setSelectedCheckIn(checkInData);
            setIsModalOpen(true);
            } catch (error) {
            console.error('Failed to fetch workout:', error);
        } finally{
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCheckIn(null);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long' };
        return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
    };

      //console.log(workout);

    if(isLoading) {
        return( <Loader />)
    }
        return(
            <>
                <div className={`${styles.card} col-11 p-3 d-flex align-items-center justify-content-between`} onClick={handleCheckInClick}>
                    <div className="col-10 d-flex justify-content-between">
                        <div>{formatDate(checkIn?.attributes?.date)}</div>
                        <div>{checkIn?.attributes?.client?.data?.attributes?.username}</div>
                    </div>
                    <div className="chevron">
                        
                    </div>
                </div>

                <ResponsiveModal isOpen={isModalOpen} onClose={handleCloseModal} title="">
                    <CheckInDetails selectedCheckIn={selectedCheckIn} checkInId={checkIn.id} />
                </ResponsiveModal>
            </>
        )
    }