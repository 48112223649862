import React from 'react';
//import {ReactComponent as RightArrow} from '../../../images/icons/right-arrow.svg';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router';
import { getLocalStorageItem } from '../../global/storage';

export default function MealPlansList({ mealplan }) {
    
    //console.log(mealplan);
    //eslint-disable-next-line
    const { name , total_kcals, fat, protein, carbs} = mealplan?.attributes || [];

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;
    const isCoach = user.isCoach;

    console.log(isCoach);

    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/coach/mealplan/${mealplan.id}`);
    }

    const handleClientClick = () => {
        navigate(`/client/mealplan/${mealplan.id}`);
    }

    return(
            <div className={`${styles.card} col-11 col-md-12 p-3 d-flex align-items-center justify-content-between`} onClick={isCoach ? handleCardClick : handleClientClick}>
                <div className={`col-10 ${styles.sub}`}>
                    <div className={`col-7 ${styles.name}`}>{name}</div>
                    <div className={` ${styles.kcals}`}>kcals: {total_kcals}</div>
                </div>
                {/* <div className={`col-5 ${styles.makro}`}>
                    <div className="">F: {fat}</div>
                    <div className="">P: {protein}</div>
                    <div className="">C: {carbs}</div>
                </div> */}
                {/* <div className="col-1">
                    <RightArrow style={{ fill: 'white' }}/>
                </div> */}
                <div className="chevron"/>
            </div>
    )
}