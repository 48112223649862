import React, { useState, useEffect } from 'react';
import styling from './searchable.module.css';
import { filterObjects } from '../searchFunctionality';

export function SearchableUserDropdown ({ objects, onSelect }) {

    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const filteredObjects = filterObjects(searchText, objects);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const handleInputChange = (e) => {
        setSearchText(e.target.value);
        setIsOpen(true);
    }

    const handleSelect = (selectedObject) => {
        onSelect(selectedObject);
        setSearchText(selectedObject.attributes.username);
        setIsOpen(false);
    }

    return(
        <div className={styling.container}>
            <input 
                type="text"
                placeholder="Søk..."
                value={searchText}
                onChange={handleInputChange}
                onClick={toggleDropdown}
                className={styling.userInput}
            />
            <div className={styling.container2}>
                <ul className={`${styling.userList} ${isOpen? `${styling.open}` : `${styling.closed}`}`}>
                    {filteredObjects.map(object => (
                        <li key={object.id} className={styling.li} onClick={() => handleSelect(object)}>
                            <div>{object.attributes.username}</div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

// export function SearchableExercisesDropdown ({ objects, onSelect }) {
//     const [searchText, setSearchText] = useState('');
//     const [isOpen, setIsOpen] = useState(false);
//     const filteredObjects = filterObjects(searchText, objects);


//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     }

//     const handleInputChange = (e) => {
//         setSearchText(e.target.value);
//     }

//     const handleSelect = (selectedObject) => {
//         onSelect(selectedObject);
//         setSearchText(selectedObject.attributes.name);
//         setIsOpen(false);
//     }

//     return (
//         <div className={styling.container}>
//             <input 
//                 type="text"
//                 placeholder="Søk..."
//                 value={searchText}
//                 onChange={handleInputChange}
//                 onClick={toggleDropdown}
//                 className={styling.input}
//             />
//             <div className={styling.container2}>
//                 <ul className={`${styling.list} ${isOpen ? styling.open : styling.closed}`}>
//                     {filteredObjects.map(object => (
//                         <li key={object.id} className={styling.li} onClick={() => handleSelect(object)}>
//                             <div>{object.attributes.name}</div>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         </div>
//     );
// }





// const filterObjects = (searchText, objects) => {
//     return objects.filter(object => object.attributes.name.toLowerCase().includes(searchText.toLowerCase()));
// };

export function SearchableExercisesDropdown({ objects, onSelect, selectedValue }) {
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    // Definer filterObjects funksjonen inni komponenten
    const filterObjects = (searchText, objects) => {
        return objects.filter(object => object.attributes.name.toLowerCase().includes(searchText.toLowerCase()));
    };

    const filteredObjects = filterObjects(searchText, objects);

    useEffect(() => {
        if (selectedValue && selectedValue.attributes) {
            setSearchText(selectedValue.attributes.name);
        }
    }, [selectedValue]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = (e) => {
        setSearchText(e.target.value);
        setIsOpen(true);
    };

    const handleSelect = (selectedObject) => {
        onSelect(selectedObject);
        setSearchText(selectedObject.attributes.name);
        setIsOpen(false);
    };

    return (
        <div className={styling.container}>
            <input 
                type="text"
                placeholder="Søk..."
                value={searchText}
                onChange={handleInputChange}
                onClick={toggleDropdown}
                className={styling.input}
            />
            <div className={styling.container2}>
                <div className={`${styling.cardList2} ${isOpen ? styling.dropdownOpen : styling.closed}`}>
                    {filteredObjects.map(object => (
                        <div key={object.id} className={styling.li2} onClick={() => handleSelect(object)}>
                            {/* <div>{object.attributes.name}</div> */}
                            <div className={styling.imageContainer} style={{ backgroundImage: `url(${object.attributes.image.data[0].attributes.url})`}}></div>
                            <div className={styling.nameDiv}>{object.attributes.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}