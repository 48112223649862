import React, {useState} from 'react';
import css from './check-in.module.css';
import { axiosImageAuthFetch, axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import Loader from '../../loader';
import CustomAlertModal from '../../customModal';

const StarRating = ({ rating, setRating }) => {
    const handleRating = (index) => {
        setRating(index + 1);
    }

    return (
        <div className={css.stars}>
            {[...Array(5)].map((star, index) => {
                return (
                    <span
                        key={index}
                        className={`${css.star} ${index < rating ? css.filled : ''}`}
                        onClick={() => handleRating(index)}
                    >
                        ★
                    </span>
                );
            })}
        </div>
    );
};

const NewCheckIn = ({ user }) => {
    const userId = user.id;
    const [weight, setWeight] = useState('');
    const [comment, setComment] = useState('');
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sleepRating, setSleepRating] = useState(3);
    const [nutritionRating, setNutritionRating] = useState(3);
    const [motivationalRating, setMotivationalRating] = useState(3);
    const [trainingRating, setTrainingRating] = useState(3);

    // const handleSubmit = async () => {
    //     setIsLoading(true);

    //     let formattedWeight = weight.replace(',', '.');
    //     let weightValue = parseFloat(formattedWeight);

    //     // Valider at vekten er et gyldig tall
    //     if (isNaN(weightValue)) {
    //     alert('Vennligst skriv inn en gyldig vekt');
    //     setIsLoading(false);
    //     return;
    //     }

    //     // Formatere vekten til én desimal
    //     weightValue = parseFloat(weightValue.toFixed(1));


    //     //const formattedWeight = weight.replace(',','.');
    //     const formData = new FormData();
    //     formData.append('data', JSON.stringify({
    //         client: userId,
    //         weight: weightValue,
    //         comment,
    //         rating_training: trainingRating,
    //         rating_nutrition: nutritionRating,
    //         rating_motivation: motivationalRating,
    //         rating_sleep: sleepRating,
    //         date: new Date(),
    //     }));

    //     images.forEach((image, index) => {
    //         formData.append('files.progress_pic', image);
    //     });

    //     try {
    //         const response = await axiosImageAuthFetch(`${API_BASE_URL}/check-ins`, formData);
    //         console.log(response);
    //         alert('Check-in submitted successfully');
    //         setIsLoading(false);
    //         setIsModalOpen(false); // Close modal on successful submission
    //     } catch (error) {
    //         console.error(error);
    //         setIsLoading(false);
    //         setIsError(true);
    //     }
    // }

    const handleSubmit = async () => {
        setIsLoading(true);
      
        // Konverter og valider vekt
        let formattedWeight = weight.replace(',', '.');
        let weightValue = parseFloat(formattedWeight);
        if (isNaN(weightValue)) {
          alert('Vennligst skriv inn en gyldig vekt');
          setIsLoading(false);
          return;
        }
        weightValue = parseFloat(weightValue.toFixed(1));
      
        // 1. Last opp bildene via Strapi sitt upload-endepunkt
        let uploadedFiles = [];
        if (images && images.length > 0) {
          const uploadFormData = new FormData();
          images.forEach((image) => {
            // Bruk feltet 'files' – Strapi forventer ofte dette
            uploadFormData.append('files', image);
          });
          try {
            // Bruk gjerne axiosImageAuthFetch (en variant av axios med riktig konfigurasjon for opplastning)
            const uploadResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload`, uploadFormData);
            console.log("Upload response:", uploadResponse.data);
            // Anta at responsen er et array med opplastede filobjekter
            uploadedFiles = uploadResponse.data;
          } catch (error) {
            console.error("Feil ved opplasting av bilder:", error);
            setIsLoading(false);
            return;
          }
        }
      
        // 2. Ekstraher fil-IDer fra opplastingsresponsen
        const fileIds = uploadedFiles.map((file) => file.id);
        // Du kan også legge til ekstra logging:
        console.log("Opplastede fil-IDer:", fileIds);
      
        // 3. Opprett check-in posten med en JSON-payload som inkluderer fil-IDene
        const checkInPayload = {
          data: {
            client: userId,  // ID til klienten (bruker-ID)
            weight: weightValue,
            comment,
            rating_training: trainingRating,
            rating_nutrition: nutritionRating,
            rating_motivation: motivationalRating,
            rating_sleep: sleepRating,
            date: new Date(),
            // Legg til filreferansen til bildet/bildene, antatt at feltet heter "progress_pic" i check-in-modellen
            progress_pic: fileIds,
          },
        };
      
        try {
          // Bruk vanlig axiosAuthFetch for å sende en JSON-kall til check-ins-endepunktet
          const response = await axiosAuthFetch(`${API_BASE_URL}/check-ins`, checkInPayload, 'POST');
          console.log("Check-in opprettet:", response.data);
          alert('Check-in submitted successfully');
          setIsLoading(false);
          setIsModalOpen(false); // Lukker modalen ved suksess
        } catch (error) {
          console.error("Feil ved opprettelse av check-in:", error);
          setIsLoading(false);
          setIsError(true);
        }
      };
      

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleWeightChange = (e) => {
        let value = e.target.value;
        // Erstatt komma med punktum
        value = value.replace(',', '.');
    
        // Tillat kun tall og ett punktum
        if (!/^\d*\.?\d*$/.test(value)) {
          // Ugyldig inndata, ignorer endringen
          return;
        }
        if (value.includes('.')) {
            //eslint-disable-next-line
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > 1) {
              // Hvis mer enn én desimal, ignorer endringen
              return;
            }
          }
      
          setWeight(value);
        };
    

    if (isLoading) {
        return (<Loader />);
    }
    if (isError) {
        return (
            <div>Oida, her gikk noe galt! Vennligst prøv å last inn siden på nytt!</div>
        );
    }

    return (
        <div className={css.container}>
            <button className={css.submitButton} onClick={openModal}>Ny Check-In</button>
            <CustomAlertModal
                show={isModalOpen}
                handleClose={closeModal}
                content={
                    <form className={css.form}>
                        <div className={css.weightInput}>
                            <label>Vekt (i kg)</label>
                            <input className={css.weightInputfield} type="text" inputMode='decimal' value={weight} onChange={handleWeightChange} required />
                        </div>
                        <div className={css.inputRating}>
                            <label>Søvn</label>
                            <StarRating rating={sleepRating} setRating={setSleepRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Trening</label>
                            <StarRating rating={trainingRating} setRating={setTrainingRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Kosthold</label>
                            <StarRating rating={nutritionRating} setRating={setNutritionRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Motivasjon</label>
                            <StarRating rating={motivationalRating} setRating={setMotivationalRating} />
                        </div>
                        <div className={css.commentInput}>
                            <label>Kommentar</label>
                            <textarea rows="5" className={css.inputInput} type="input" value={comment} onChange={(e) => setComment(e.target.value)} />
                        </div>
                        <div className={css.imageInput}>
                            <label>Last opp bilder:</label>
                            <input type="file" multiple onChange={handleImageChange} />
                        </div>
                        <div className={css.submitButton} onClick={handleSubmit}>SEND INN</div>
                    </form>
                }
                submit={handleSubmit}
                modalTitle="Hvordan har din uke vært?"
            />
        </div>
    );
}

export default NewCheckIn;


const CheckInForm = ({ user, coachId, checkIns }) => {
    const userId = user.id;
    console.log(user);
    const [weight, setWeight] = useState('');
    const [comment, setComment] = useState('');
    const [images, setImages] = useState([]);
     const [isLoading, setIsLoading] = useState(false);
    // const [isError, setIsError] = useState(false);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [sleepRating, setSleepRating] = useState(3);
    const [nutritionRating, setNutritionRating] = useState(3);
    const [motivationalRating, setMotivationalRating] = useState(3);
    const [trainingRating, setTrainingRating] = useState(3);

    const now = new Date();
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1)); // Mandag som startdag
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    // const hasCheckInThisWeek = checkIns.some((checkIn) => {
    //     const checkInDate = new Date(checkIn.attributes.date);
    //     return checkInDate >= startOfWeek && checkInDate <= endOfWeek;
    // });

    const handleSubmit = async () => {
        // if (hasCheckInThisWeek) {
        //     alert("Du har allerede sendt inn en check-in denne uken.");
        //     return;
        // }
        
        setIsLoading(true);

        let formattedWeight = weight.replace(',', '.');
        let weightValue = parseFloat(formattedWeight);

        // Valider at vekten er et gyldig tall
        if (isNaN(weightValue)) {
        alert('Vennligst skriv inn en gyldig vekt');
        //setIsLoading(false);
        return;
        }

        // Formatere vekten til én desimal
        weightValue = parseFloat(weightValue.toFixed(1));


        //const formattedWeight = weight.replace(',','.');
        const formData = new FormData();
        formData.append('data', JSON.stringify({
            client: userId,
            weight: weightValue,
            comment,
            coach: coachId,
            rating_training: trainingRating,
            rating_nutrition: nutritionRating,
            rating_motivation: motivationalRating,
            rating_sleep: sleepRating,
            date: new Date(),
        }));

        images.forEach((image, index) => {
            formData.append('files.progress_pic', image);
        });

        try {
            const response = await axiosImageAuthFetch(`${API_BASE_URL}/check-ins`, formData);
            console.log(response);
            alert('Check-in submitted successfully');
            setIsLoading(false);
            window.location.reload();
            // setIsModalOpen(false); // Close modal on successful submission
        } catch (error) {
            console.error(error);
             setIsLoading(false);
            // setIsError(true);
        }
    }

    // const handleSubmit = async () => {
    //     if (hasCheckInThisWeek) {
    //       alert("Du har allerede sendt inn en check-in denne uken.");
    //       return;
    //     }
        
    //     setIsLoading(true);
      
    //     // Konverter og valider vekt
    //     let formattedWeight = weight.replace(',', '.');
    //     let weightValue = parseFloat(formattedWeight);
    //     if (isNaN(weightValue)) {
    //       alert('Vennligst skriv inn en gyldig vekt');
    //       setIsLoading(false);
    //       return;
    //     }
    //     weightValue = parseFloat(weightValue.toFixed(1));
      
        
    //     let uploadedFileIds = [];
    //     if (images && images.length > 0) {
    //       const uploadFormData = new FormData();
         
    //       images.forEach((image) => {
    //         uploadFormData.append('files', image);
    //       });
          
    //       try {
            
    //         const uploadResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload`, uploadFormData);
    //         console.log("Upload response:", JSON.stringify(uploadResponse, null, 2));
            
    //         const filesArray = uploadResponse.data ? uploadResponse.data : uploadResponse;
    //         if (!filesArray || !Array.isArray(filesArray)) {
    //             console.error("Ingen fildata mottatt fra opplastningen");
    //             setIsLoading(false);
    //             return;
    //         }
            
    //         const uploadedFileIds = filesArray.map((file) => file.id);
    //         console.log("Opplastede fil-IDer:", uploadedFileIds);
    //       } catch (error) {
    //         console.error("Feil ved opplasting av bilder:", error.response ? error.response.data : error);
    //         setIsLoading(false);
    //         return;
    //       }
    //     }
      
    //     // 2. Opprett check-in med en JSON-payload som inkluderer referansen til de opplastede bildene.
    //     // Sørg for at feltet "progress_pic" i modellen din forventer en array med fil-IDer.
    //     const checkInPayload = {
    //       data: {
    //         client: userId,
    //         weight: weightValue,
    //         comment,
    //         rating_training: trainingRating,
    //         rating_nutrition: nutritionRating,
    //         rating_motivation: motivationalRating,
    //         rating_sleep: sleepRating,
    //         date: new Date(),
    //         progress_pic: uploadedFileIds,  // Her sender du referansen til de opplastede bildene
    //       },
    //     };
      
    //     console.log("Check-in payload:", JSON.stringify(checkInPayload, null, 2));
      
    //     try {
    //       // Bruk en vanlig axios-kall (JSON) for å opprette check-in
    //       const response = await axiosAuthFetch(`${API_BASE_URL}/check-ins`, checkInPayload, 'POST');
    //       console.log("Check-in opprettet:", response);
    //       alert('Check-in submitted successfully');
    //       setIsLoading(false);
    //       window.location.reload();
    //     } catch (error) {
    //       console.error("Feil ved opprettelse av check-in:", error.response ? error.response.data : error);
    //       setIsLoading(false);
    //     }
    //   };
      

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    }

    const handleWeightChange = (e) => {
        let value = e.target.value;
        // Erstatt komma med punktum
        value = value.replace(',', '.');
    
        // Tillat kun tall og ett punktum
        if (!/^\d*\.?\d*$/.test(value)) {
          // Ugyldig inndata, ignorer endringen
          return;
        }
        if (value.includes('.')) {
            //eslint-disable-next-line
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > 1) {
              // Hvis mer enn én desimal, ignorer endringen
              return;
            }
          }
      
          setWeight(value);
        };

        if (isLoading) {
            return (<Loader />);
        }

        return(
            <form className={css.form}>
                <h2>Ny Check-In</h2>
                        <div className={css.weightInput}>
                            <label>Vekt (i kg)</label>
                            <input className={css.weightInputfield} type="text" inputMode='decimal' value={weight} onChange={handleWeightChange} required />
                        </div>
                        <div className={css.inputRating}>
                            <label>Søvn</label>
                            <StarRating rating={sleepRating} setRating={setSleepRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Trening</label>
                            <StarRating rating={trainingRating} setRating={setTrainingRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Kosthold</label>
                            <StarRating rating={nutritionRating} setRating={setNutritionRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Motivasjon</label>
                            <StarRating rating={motivationalRating} setRating={setMotivationalRating} />
                        </div>
                        <div className={css.commentInput}>
                            <label>Kommentar</label>
                            <textarea rows="5" className={css.inputInput} type="input" value={comment} onChange={(e) => setComment(e.target.value)} />
                        </div>
                        <div className={css.imageInput}>
                            <label>Last opp bilder:</label>
                            <input type="file" multiple onChange={handleImageChange} />
                        </div>
                        <div className={css.submitButton} onClick={handleSubmit}>SEND INN</div>
                    </form>
        )
}

export { CheckInForm };