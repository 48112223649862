import React from 'react';
import ExerciseBank from '../../global/exerciseBank';
import styles from './styles.module.scss';
import BackButton from '../../global/backButton';

export default function ExercisePage() {
    return(
        <div className={styles.parent}>
            <div className="col-2 ms-2 my-3">
                <BackButton />
            </div>
            <div className={`col-11 col-md-5 p-3 m-auto d-flex justify-content-center ${styles.container}`}>
                <ExerciseBank />
            </div>
        </div>
    )
}