import React, { useState, useEffect } from 'react';
import { ReactComponent as BackIcon } from '../../../images/icons/back.svg';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

export default function BackButton({ route }) {

    const navigate = useNavigate();
    const [canGoBack, setCanGoBack] = useState(false);

    useEffect(() => {
      if (route) {
        setCanGoBack(true);
      } else if (window.history.length >= 2) {
        setCanGoBack(true);
      }
    }, [route]);
  
    const goBack = () => {
      if (route) {
        navigate(route);
      } else {
        navigate(-1);
      }
    };
  
    if (!canGoBack) {
      return null;
    }

    return(
        <div className={`d-flex align-items-center justify-content-center col-12 ${styles.container}`} onClick={goBack}>
            <div className="col-3 col-md-1">
                <BackIcon />
            </div>
            <div className="d-flex align-items-center pt-1 my-0 ms-2 ms-md-3">
              Tilbake
            </div>
        </div>
    )
}