import React, {useState, useEffect, useRef} from 'react';
import css from '../commu.module.scss';
import { API_GRAPHQL } from '../../../../constants';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { getLocalStorageItem } from '../../storage';

const ClientSideCommunication = () => {
    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo?.user;
    const userID = user.id;
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);
    const [communicationID, setCommunicationID] = useState(null);

    const query = `query {
        communications(filters:{users:{id:{eq:${userID}}}}) {
          data{
            id
            attributes{
              messages
            }
          }
        }
      }`;

    useEffect(() => {
        async function getMessages() {
            setLoadingMessages(true);
            setMessageError(false);
            try {
                const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
                const communicationData = response.data.communications.data[0];

                if (communicationData) {
                    const parsedMessages = JSON.parse(communicationData.attributes.messages);
                    setCommunicationID(communicationData.id);
                    setMessages(parsedMessages);
                }
            } catch (error) {
                console.error(error);
                setMessageError(true);
            } finally {
                setLoadingMessages(false);
            }
        }
        getMessages();
        //eslint-disable-next-line
    }, [userID]);

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        const messageToSend = {
            message: newMessage,
            senderId: userID,
            timestamp: new Date().toISOString(),
        };

        const mutation = `
            mutation UpdateCommunication($id: ID!, $messages: JSON) {
                updateCommunication(id: $id, data: { messages: $messages }) {
                    data {
                        attributes {
                            messages
                        }
                    }
                }
            }
        `;

        const updatedMessages = [...messages, messageToSend];

        const variables = {
            id: communicationID, // Assuming all messages belong to the same communication
            messages: JSON.stringify(updatedMessages),
        };

        try {
            const response = await axiosAuthFetch(API_GRAPHQL, {
                query: mutation,
                variables: variables
            }, 'POST');

            const newMessages = JSON.parse(response.data.updateCommunication.data.attributes.messages);
            setMessages(newMessages);
            setNewMessage('');
        } catch (error) {
            console.error("Failed to send message:", error);
            setMessageError(true);
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    if (loadingMessages || !messages) {
        return (<div>Laster meldinger...</div>);
    }
    if (messageError) {
        return (<div>Oida, meldingene kunne ikke lastes! Vennligst prøv igjen!</div>);
    }

    return (
        <div className={`${css.container}`}>
            <div className={css.overskrift}>CHAT MED DIN COACH</div>
            <div className={css.clientWindow}>
                <div className={css.innerWindow}>
                    <div className={css.messagesWindow}>
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={`${css.message} ${msg.senderId === userID ? css.userMessage : css.otherMessage}`}
                        >
                            {msg.message}
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                    </div>
                    <div className={css.messageInputContainer}>
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            className={css.messageInput}
                            placeholder="Skriv en melding..."
                        />
                        <button onClick={handleSendMessage} className={css.sendButton}>Send</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientSideCommunication;

// const ClientSideCommunication = ({ user }) => {
//     const userID = user.id;
//     const [loadingMessages, setLoadingMessages] = useState(false);
//     const [messageError, setMessageError] = useState(false);
//     const [messages, setMessages] = useState([]);

//     const query = `query{
//         communications(filters: {users:{id:{eq:${userID}}}}){
//             data{
//                 id
//                 attributes{
//                     messages
//                 }
//             }
//         }
//     }`;

//     useEffect(() => {
//         async function getMessages() {
//             setLoadingMessages(true);
//             setMessageError(false);
//             try {
//                 const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
//                 console.log(response);
//                 setMessages(response.data.communications.data[0].attributes.messages.messages);
//             } catch(error) {
//                 console.error(error);
//                 setMessageError(true);
//             } finally {
//                 setLoadingMessages(false);
//             }
//         }
//         getMessages();
//     }, [query]);

//     if (loadingMessages || !messages) {
//         return (<div>Laster meldinger...</div>);
//     }
//     if (messageError) {
//         return (<div>Oida, meldingene kunne ikke lastes! Venligst prøv igjen!</div>);
//     }

//     return (
//         <div className={css.container}>
//             <div className={css.overskrift}>CHAT MED DIN COACH</div>
//             <div className={css.window}>
//                 {messages.map((msg, index) => (
//                     <div
//                         key={index}
//                         className={`${css.message} ${msg.senderId === userID ? css.userMessage : css.otherMessage}`}
//                     >
//                         {msg.message}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default ClientSideCommunication;