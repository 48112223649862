import React from 'react';
import createCss from './create.module.scss';
import CreateProgramForm from '../../../global/forms/createProgram';
import { useExercises } from '../../../../hooks/exercises';
import { getLocalStorageItem } from '../../../global/storage';
import BackButton from '../../../global/backButton';

export default function CreatePage() {

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo?.user;
    const isCoach = user.isCoach;

    const exercises = useExercises();

    if(isCoach === true){
        return(
            <div className={`${createCss.container1} appearing-container`}>
                <div className="col-2 py-2 ms-2">
                    <BackButton />
                </div>
                <h1 className={createCss.h1}>LAG PROGRAM</h1>
                <div>
                    <CreateProgramForm
                        options={exercises}
                    />
                </div>
            </div>  
        )
    }

    else {
            alert('Ikke tilgang! Omdirigerer');
            window.location.href="/home";
    }
    
}