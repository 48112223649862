import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { getLocalStorageItem, removeLocalStorageItem } from '../storage';
import Workouts from '../workouts';
import WeeklyCalendar from '../weeklyCal';
import { useWorkouts } from '../../../hooks/workouts';
import Loader from '../loader';
import WorkoutHistoryPage from '../workoutHistory';
import Notifications from '../notifications';

export default function ClientHomePage() {
    const [totalVolume, setTotalVolume] = useState(0);
    const [totalWeightLifted, setTotalWeightLifted] = useState(0);
    const [workoutCount, setWorkoutCount] = useState(0);
    const [filteredWorkouts, setFilteredWorkouts] = useState([]);

    const [hasIncompleteWorkout, setHasIncompleteWorkout] = useState(false);

    const today = new Date();
    const options = { month: 'short' };
    const monthAbbreviation = today.toLocaleString('no-NO', options);
    const date = today.getDate();
    const user = getLocalStorageItem('limitless-user');
    const username = user.user.username;
    const { workouts, isLoading } = useWorkouts();

    useEffect(() => {
        if (workouts && workouts.length > 0) {
            const currentDate = new Date();

            // Calculate Monday as the start of the week
            const firstDayOfWeek = new Date(currentDate);
            firstDayOfWeek.setDate(
                currentDate.getDate() - (currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1)
            );
            // Reset time to 00:00:00.000
            firstDayOfWeek.setHours(0, 0, 0, 0);

            // Calculate Sunday as the end of the week
            const lastDayOfWeek = new Date(firstDayOfWeek);
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
            // Set time to 23:59:59.999
            lastDayOfWeek.setHours(23, 59, 59, 999);

            let volumeInSeconds = 0;
            let weightLifted = 0;

            // Filter workouts that belong to the current week
            const currentWeekWorkouts = workouts.filter((workout) => {
                const workoutDate = new Date(workout.attributes.date_performed);

                return workoutDate >= firstDayOfWeek && workoutDate <= lastDayOfWeek;
            });

            // Calculate volume and total weight for workouts in the current week
            currentWeekWorkouts.forEach((workout) => {
                const workoutDurationStr = workout.attributes.duration || '00:00:00';
                const workoutDurationSeconds = parseDurationToSeconds(workoutDurationStr);

                const exerciseLogs = workout.attributes.exercise_logs.data;

                exerciseLogs.forEach((log) => {
                    const sets = parseInt(log.attributes.sett, 10) || 0;
                    const reps = parseInt(log.attributes.reps, 10) || 0;
                    const weight = parseFloat(log.attributes.vekt) || 0;
                    weightLifted += sets * reps * weight;
                });

                volumeInSeconds += workoutDurationSeconds;
            });

            // Update state with filtered workouts and statistics
            setFilteredWorkouts(currentWeekWorkouts);
            setTotalVolume(volumeInSeconds);
            setTotalWeightLifted(weightLifted);
            setWorkoutCount(currentWeekWorkouts.length);
        }
    }, [workouts]);

  useEffect(() => {
    const savedWorkout = getLocalStorageItem('incompleteWorkout');
    if(savedWorkout) {
      setHasIncompleteWorkout(true);
    }
  }, []);

    // Function to parse duration from "HH:MM:SS" to seconds
    const parseDurationToSeconds = (durationStr) => {
        const [hours, minutes, seconds] = durationStr.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    // Function to format total volume to "X timer Y minutter"
    const formatTotalVolume = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        let result = '';
        if (hours > 0) {
            result += `${hours} timer`;
        }
        if (minutes > 0) {
            result += hours > 0 ? ` ${minutes} minutter` : `${minutes} minutter`;
        }
        if (result === '') {
            result = '0 minutter';
        }
        return result;
    };

    const handleResumeWorkout = () => {
        window.location.href="/training"
    };

    const handleDiscardWorkout = () => {
        if(window.confirm('Er du sikker på at du vil slette økta?')) {
            removeLocalStorageItem('incompleteWorkout');
            setHasIncompleteWorkout(false);
        }
    }

    // If data is still loading, show a loader
    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className={`appearing-container ${styles.parent}`}>
            <div className={styles.child1}>
                <div className={`${styles.subChild1} pt-3`}>
                    I DAG, {date}. {monthAbbreviation}
                </div>
                <div className={styles.subChild2}>Hei, {username}! 👋🏼</div>
            </div>
            {hasIncompleteWorkout && (
                <div className={`custom-shadow rounded-3 p-5 ${styles.incompleteContainer}`}>
                  <p className="text-center">Du har en ufullstendig økt lagret.</p>
                  <div className={styles.resumeBanner}>
                    <div className={`${styles.resumeButton} p-2 col-6 me-3`} onClick={handleResumeWorkout}>Fortsett økt</div>
                    <div className={`${styles.discardButton} p-2 col-6 ms-3`} onClick={handleDiscardWorkout}>Slett økt</div>
                  </div>
                </div>
                
              )}
            <div className={`${styles.child2} pb-3 custom-shadow`}>
                <h5 className={styles.paddingTop}>Din treningsuke</h5>
                <WeeklyCalendar workouts={filteredWorkouts} />
            </div>
            <div className={styles.stats}>
                <h5 className={styles.paddingTop}>Statistikk for uken</h5>
                <p>Antall økter: {workoutCount}</p>
                <p>Total treningstid: {formatTotalVolume(totalVolume)}</p>
                <p>
                    Totalt volum (sett x reps x vekt): {totalWeightLifted.toFixed(2)} kg
                </p>
            </div>
            <div className={styles.child3}>
                <Notifications />
            </div>
            <div className={`${styles.child2} pb-3`}>
                <Workouts user={user} />
            </div>
            <WorkoutHistoryPage />
        </div>
    );
}
