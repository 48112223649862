import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Clock from '../../../../../images/icons/timer.png';
import Chef from '../../../../../images/icons/chef-hat.png';
import Serving from '../../../../../images/icons/user (2).png';
import IngredientIcon from '../../../../../images/icons/harvest.png';
import Image from 'react-bootstrap/Image';
import { axiosAuthFetch, axiosImageAuthFetch } from '../../../../../helpers/axios';
import { API_BASE_URL, API_GRAPHQL } from '../../../../../constants';
import Loader from '../../../../global/loader';
//import ClientsList from '../clientList';
import VariantsList from '../variants';
//import { ReactComponent as BackIcon } from '../../../../../images/icons/back.svg';
import { useParams } from 'react-router';
import { GetSingleMealInfo } from '../../../../../hooks/specificMealPage';
import useIsDesktop from '../../../../../hooks/useIsDesktop';
import BackButton from '../../../../global/backButton';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { getLocalStorageItem } from '../../../../global/storage';
import { useNavigate } from 'react-router';

Chart.register(ArcElement, Tooltip, Legend);

export default function CoachMealDetail({  onBack, }) {
  
    const [clients, setClients] = useState([]);
    const isDesktop = useIsDesktop();
    const { mealId } = useParams();
    const [mealData, setMealData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [mealName, setMealName] = useState("");
    const [mealDescription, setMealDescription] = useState("");
    const [mealPrepTime, setMealPrepTime] = useState(0);
    const [mealCookingTime, setMealCookingTime] = useState(0);
    const [mealServing, setMealServing] = useState(1);
    const [mealPreparation, setMealPreparation] = useState("");
    const [mealCookingInstructions, setMealCookingInstructions] = useState("");
    const [mealCategory, setMealCategory] = useState("");
    const [mealIngredients, setMealIngredients] = useState([]);
    const [ingredientsToRemove, setIngredientsToRemove] = useState([]);
    const [ingredientSearch, setIngredientSearch] = useState('');
    const [filteredIngredients, setFilteredIngredients] = useState([]);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [newIngredientAmount, setNewIngredientAmount] = useState('');
    const [newIngredientUnit, setNewIngredientUnit] = useState('g');
    const [activeTab, setActiveTab] = useState("description");
    const [isEditing, setIsEditing] = useState(false);
    const [allIngredients, setAllIngredients] = useState([]);
    const [nutritionView, setNutritionView] = useState("total");
    const [ingredientView, setIngredientView] = useState("total");
    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;
    const coachId = user.coachID;
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(mealData?.attributes?.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url || '');
    const [uploadedImage, setUploadedImage] = useState(null); 

    const query = `
      query{
        usersPermissionsUsers(filters: {coach: {id: {eq: ${coachId}}}}){
          data{
            id
            attributes{
              username
            }
          }
        }
      }
    `
    ;
    
        useEffect(() => {
            async function getMeals() {
                setIsLoading(true);
                try{
                    const mealData = await GetSingleMealInfo(mealId);
                    setMealData(mealData);
                    const ingredientResponse = await axiosAuthFetch(`${API_BASE_URL}/ingredients?populate=*`);
                    const clientResponse = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'post');
                    console.log('CLIENTREPONSE',clientResponse.data.usersPermissionsUsers.data);
                    setClients(clientResponse.data.usersPermissionsUsers.data)
                    //setIngredients(ingredientResponse.data.map(item => ({ ...item.attributes, id: item.id })));
                    setAllIngredients(ingredientResponse.data);
                    //console.log('MEAL DATA', mealData);
                    setMealServing(mealData.attributes.serving);
                } catch(error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            }
            getMeals();
          //eslint-disable-next-line  
        },[mealId]);

        useEffect(() => {
          if (mealData?.attributes) {
            const attrs = mealData.attributes;
            setMealName(attrs.name || "");
            setMealDescription(attrs.description || "");
            setMealPrepTime(attrs.prep_time || 0);
            setMealCookingTime(attrs.cooking_time || 0);
            setMealServing(attrs.serving || 1);
            setMealPreparation(attrs.preparation || "");
            setMealCookingInstructions(attrs.cooking_instructions || "");
            setMealCategory(attrs.category || "");
            setMealIngredients(attrs.meal_ingredients?.data || []);
            const imgUrl = attrs.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url;
            setSelectedImage(imgUrl || "");
          }
        }, [mealData]);
        
    //const mealAttributes = mealData.attributes;
    // const {
    //     name, prep_time, cooking_time, serving, meal_images, category, preparation, description, cooking_instructions,
    // } = mealAttributes;

    const imageUrl = mealData?.attributes?.meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;
    // const [activeTab, setActiveTab] = useState("description");
    // const [isEditing, setIsEditing] = useState(false);

    // const [mealName, setMealName] = useState(name);
    // const [mealDescription, setMealDescription] = useState(description);
    // const [mealPrepTime, setMealPrepTime] = useState(prep_time);
    // const [mealCookingTime, setMealCookingTime] = useState(cooking_time);
    // const [mealServing, setMealServing] = useState(serving);
    // const [mealPreparation, setMealPreparation] = useState(preparation);
    // const [mealCookingInstructions, setMealCookingInstructions] = useState(cooking_instructions);
    // const [mealCategory, setMealCategory] = useState(category);
    // const [mealIngredients, setMealIngredients] = useState(mealAttributes?.meal_ingredients.data);
    // const [ingredientsToRemove, setIngredientsToRemove] = useState([]);
    // const [ingredientSearch, setIngredientSearch] = useState('');
    // const [filteredIngredients, setFilteredIngredients] = useState([]);
    // const [selectedIngredient, setSelectedIngredient] = useState(null);
    // const [newIngredientAmount, setNewIngredientAmount] = useState('');
    // const [newIngredientUnit, setNewIngredientUnit] = useState('g');
    const variants = mealData?.attributes?.meal_instances?.data;

    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      setUploadedImage(file);

      setSelectedImage("");
    };

    const calculateNutrition = () => {
        let totalProtein = 0;
        let totalCarbs = 0;
        let totalFat = 0;
        let totalKcals = 0;
    
        mealIngredients.forEach(ingredient => {
            const amount = parseFloat(ingredient.attributes.amount);
            const macros = ingredient.attributes.macros
                ? ingredient.attributes.macros[0]
                : ingredient.attributes.ingredients.data[0].attributes.macros[0];
            const unit = ingredient.attributes.unit;
    
            if (unit === "stk") {
                totalProtein += macros.protein * amount;
                totalCarbs += macros.carbs * amount;
                totalFat += macros.fat * amount;
                totalKcals += macros.kcals * amount;
            } else if (unit === "g") {
                totalProtein += (macros.protein / 100) * amount;
                totalCarbs += (macros.carbs / 100) * amount;
                totalFat += (macros.fat / 100) * amount;
                totalKcals += (macros.kcals / 100) * amount;
            } else if (unit === "dl") {
                const gramsEquivalent = amount * 100;
                totalProtein += (macros.protein / 100) * gramsEquivalent;
                totalCarbs += (macros.carbs / 100) * gramsEquivalent;
                totalFat += (macros.fat / 100) * gramsEquivalent;
                totalKcals += (macros.kcals / 100) * gramsEquivalent;
            }
        });

        // const perServing = Math.max(1, mealServing);
    
        // return {
        //     totalKcals: Math.round(totalKcals / perServing),
        //     totalProtein: Math.round(totalProtein / perServing),
        //     totalCarbs: Math.round(totalCarbs / perServing),
        //     totalFat: Math.round(totalFat / perServing)
        // };
        return {
          totalCarbs,
          totalFat,
          totalProtein,
          totalKcals
        }
    };
    
    // const nutritionValues = calculateNutrition();

    const { totalProtein, totalCarbs, totalFat, totalKcals } = calculateNutrition();

    const perServing = Math.max(1, mealServing);

    const displayedNutrition = nutritionView === "perServing" ? {
        protein: Math.round(totalProtein / perServing),
        carbs: Math.round(totalCarbs / perServing),
        fat: Math.round(totalFat / perServing),
        kcals: Math.round(totalKcals / perServing),
    } : {
        protein: Math.round(totalProtein),
        carbs: Math.round(totalCarbs),
        fat: Math.round(totalFat),
        kcals: Math.round(totalKcals),
    };

    const handleEditToggle = () => {
        if (isEditing) {
            handleEditMeal();
        } else {
            setIsEditing(true);
        }
    };

    const handleDeleteMeal = async () => {
        const confirmed = window.confirm("Er du sikker på at du vil slette dette måltidet?");
        if (!confirmed) return;

        setIsLoading(true);
        try {
            await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {}, 'DELETE');
            alert("Måltid slettet!");
            navigate('/meals/home');
        } catch (error) {
            console.error("Error deleting meal:", error);
            alert("Kunne ikke slette måltidet. Prøv igjen senere.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleIngredientSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setIngredientSearch(searchValue);
        setFilteredIngredients(
            searchValue
                ? allIngredients.filter((ingredient) =>
                    ingredient.attributes.name.toLowerCase().includes(searchValue)
                  )
                : []
        );
    };

    const handleAddIngredient = () => {
        if (!selectedIngredient || !newIngredientAmount) return;
        console.log(selectedIngredient);
        // const newIngredient = {
        //     ...selectedIngredient,
        //     attributes: {
        //         ...selectedIngredient.attributes,
        //         amount: newIngredientAmount,
        //         unit: newIngredientUnit,
        //     },
        // };

        const newIngredient = {
            attributes: {
                amount: newIngredientAmount,
                unit: newIngredientUnit,
                ingredients: {
                    data: [
                        {
                            id: selectedIngredient.id,
                            attributes: selectedIngredient.attributes
                        }
                    ]
                }
            }
        };

        setMealIngredients((prevIngredients) => [...prevIngredients, newIngredient]);
        setIngredientSearch('');
        setSelectedIngredient(null);
        setNewIngredientAmount('');
        setNewIngredientUnit('g');
    };

    const handleIngredientChange = (index, field, value) => {
        const updatedIngredients = [...mealIngredients];
        updatedIngredients[index].attributes[field] = value;
        setMealIngredients(updatedIngredients);
    };

    // const handleEditMeal = async () => {
    //     try {
    //       setIsLoading(true);
      
    //       // Oppdater måltidsinformasjon
    //       await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {
    //         data: {
    //           name: mealName,
    //           description: mealDescription,
    //           prep_time: mealPrepTime,
    //           cooking_time: mealCookingTime,
    //           preparation: mealPreparation,
    //           cooking_instructions: mealCookingInstructions,
    //           serving: mealServing,
    //           category: mealCategory
    //         }
    //       }, 'PUT');
      
    //       // Oppdater eksisterende ingredienser
    //       const existingIngredients = mealIngredients.filter(ingredient => ingredient.id);
    //       const newIngredients = mealIngredients.filter(ingredient => !ingredient.id);
      
    //       await Promise.all(existingIngredients.map(async (ingredient) => {
    //         const { amount, unit } = ingredient.attributes;
    //         await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients/${ingredient.id}`, {
    //           data: { amount, unit }
    //         }, 'PUT');
    //       }));
      
    //       // Legg til nye ingredienser
    //       await Promise.all(newIngredients.map(async (ingredient) => {
    //         const { amount, unit } = ingredient.attributes;
    //         await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients`, {
    //           data: {
    //             meals: [mealData.id],
    //             ingredients: [ingredient.attributes.ingredients.data[0].id],
    //             amount,
    //             unit
    //           }
    //         }, 'POST');
    //       }));
      
    //       // Fjern ingredienser som er merket for sletting
    //       await Promise.all(ingredientsToRemove.map(async (id) => {
    //         await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients/${id}`, {}, 'DELETE');
    //       }));
      
    //       const nutritionValues = calculateNutrition();

    //       // 6. Oppdater måltidet med de beregnede macros
    //       await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {
    //         data: {
    //           proteins: nutritionValues.totalProtein,
    //           carbs: nutritionValues.totalCarbs,
    //           fats: nutritionValues.totalFat,
    //           kcals: nutritionValues.totalKcals
    //         }
    //       }, 'PUT');

    //       alert("Måltid og ingredienser oppdatert!");
    //       setIsLoading(false);
    //       setIsEditing(false);
    //       setIngredientsToRemove([]);
    //     } catch (error) {
    //       console.error("Error updating meal or ingredients:", error);
    //       alert("Det oppstod en feil ved oppdatering av måltidet eller ingrediensene.");
    //       setIsLoading(false);
    //     }
    //   };

    const handleEditMeal = async () => {
      try {
        setIsLoading(true);
        // Oppdater måltidsinformasjon
        await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {
          data: {
            name: mealName,
            description: mealDescription,
            prep_time: mealPrepTime,
            cooking_time: mealCookingTime,
            preparation: mealPreparation,
            cooking_instructions: mealCookingInstructions,
            serving: mealServing,
            category: mealCategory
          }
        }, 'PUT');
  
        // Håndter bildeoppdatering:
        let mealImageInstanceId = null;
        if (uploadedImage) {
          const formData = new FormData();
          formData.append('files', uploadedImage);
          const uploadResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload/`, formData, 'POST');
          const uploadedImageId = uploadResponse[0].id;
          const mealImageInstanceResponse = await axiosAuthFetch(
            `${API_BASE_URL}/meal-images`,
            { data: { image: uploadedImageId } },
            'POST'
          );
          mealImageInstanceId = mealImageInstanceResponse.data.id;
        } else if (selectedImage) {
          mealImageInstanceId = selectedImage;
        }
        if (mealImageInstanceId) {
          await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {
            data: {
              meal_images: [mealImageInstanceId]
            }
          }, 'PUT');
        }
  
        // Oppdater eksisterende ingredienser
        const existingIngredients = mealIngredients.filter(ingredient => ingredient.id);
        const newIngredients = mealIngredients.filter(ingredient => !ingredient.id);
        await Promise.all(existingIngredients.map(async (ingredient) => {
          const { amount, unit } = ingredient.attributes;
          await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients/${ingredient.id}`, {
            data: { amount, unit }
          }, 'PUT');
        }));
        await Promise.all(newIngredients.map(async (ingredient) => {
          const { amount, unit } = ingredient.attributes;
          await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients`, {
            data: {
              meals: [mealData.id],
              ingredients: [ingredient.attributes.ingredients.data[0].id],
              amount,
              unit
            }
          }, 'POST');
        }));
        await Promise.all(ingredientsToRemove.map(async (id) => {
          await axiosAuthFetch(`${API_BASE_URL}/meal-ingredients/${id}`, {}, 'DELETE');
        }));
        
        const nutritionValues = calculateNutrition();
        await axiosAuthFetch(`${API_BASE_URL}/meals/${mealData.id}`, {
          data: {
            proteins: nutritionValues.totalProtein,
            carbs: nutritionValues.totalCarbs,
            fats: nutritionValues.totalFat,
            kcals: nutritionValues.totalKcals
          }
        }, 'PUT');
  
        alert("Måltid og ingredienser oppdatert!");
        navigate(-1);
        setIsLoading(false);
        setIsEditing(false);
        setIngredientsToRemove([]);
      } catch (error) {
        console.error("Error updating meal or ingredients:", error);
        alert("Det oppstod en feil ved oppdatering av måltidet eller ingrediensene.");
        setIsLoading(false);
      }
    };

    const getIngredientAttribute = (ingredient, attribute) => {
        // Hvis ingrediensen kommer fra mealData
        if (ingredient.attributes.ingredients) {
            return ingredient.attributes.ingredients.data[0].attributes[attribute];
        }
        // Hvis ingrediensen kommer fra allIngredients (props)
        return ingredient.attributes[attribute];
    };

    const handleRemoveIngredient = (index) => {
        const ingredientToRemove = mealIngredients[index];
        if (ingredientToRemove.id) {
          setIngredientsToRemove([...ingredientsToRemove, ingredientToRemove.id]);
        }
        setMealIngredients(mealIngredients.filter((_, i) => i !== index));
      };

      //console.log(mealData);
    
    if(isLoading) {
        return <Loader />;
    }

    if (!mealData) {
      return <Loader />;
    }

    const caloriesFromProtein = totalProtein * 4;
    const caloriesFromCarbs = totalCarbs * 4;
    const caloriesFromFat = totalFat * 9;
    const totalCalories = caloriesFromProtein + caloriesFromCarbs + caloriesFromFat;

    const proteinPercentage = totalCalories > 0 ? (caloriesFromProtein / totalCalories) * 100 : 0;
    const carbsPercentage = totalCalories > 0 ? (caloriesFromCarbs / totalCalories) * 100 : 0;
    const fatPercentage = totalCalories > 0 ? (caloriesFromFat / totalCalories) * 100 : 0;

    const doughnutData = {
      labels: ['Protein', 'Karbohydrater', 'Fett'],
      datasets: [
          {
              data: [proteinPercentage, carbsPercentage, fatPercentage],
              backgroundColor: ['#2ecc71', '#e4e4e4', '#FF6384'],
              hoverBackgroundColor: ['#FFCE56', '#e9e9e9', '#FF6384'],
          },
      ],
  };

  const options = {
      cutout: '75%', // Øker cutout for en tynnere ring
      plugins: {
        legend: {
          position: 'bottom'
        }
      }
    };

    return (
        <div className={`${styles.detailContainer} pt-2 pb-5 appearing-container`}>

            {/* === DESKTOP-VISNING === */}
      {isDesktop && (
        <>
          {/* "Header" øverst */}
          <div className={styles.desktopHeader}>
            <div className={styles.headerLeft}>
              <BackButton/>
            </div>
            <div className={styles.headerCenter}>
              {isEditing ? (
                <input
                  value={mealName}
                  onChange={(e) => setMealName(e.target.value)}
                  className={styles.inputName}
                />
              ) : (
                <h2>{mealName}</h2>
              )}
              <div className={styles.category}>
                {isEditing ? (
                  <select
                    value={mealCategory}
                    onChange={(e) => setMealCategory(e.target.value)}
                    className={styles.inputName}
                  >
                    <option value="Frokost">Frokost</option>
                    <option value="Lunsj">Lunsj</option>
                    <option value="Middag">Middag</option>
                    <option value="Kveldsmat">Kveldsmat</option>
                    <option value="Snack">Snack</option>
                    <option value="Mellommåltid">Mellommåltid</option>
                  </select>
                ) : (
                  <p>{mealCategory}</p>
                )}
              </div>
            </div>
          </div>

          {/* To-kolonners layout med uavhengig scrolling */}
          <div className={styles.desktopContent}>
            {/* Venstre kolonne */}
            <div className={styles.leftColumn}>
              <div className={styles.mealImage} style={{ backgroundImage: `url(${imageUrl})` }} />

              <div className={`${styles.prepInfo}`}>
                {/* For enkelhet, map over prep_time / cooking_time / serving */}
                {["prep_time", "cooking_time", "serving"].map((field, idx) => (
                  <div className={styles.prepChild} key={idx}>
                    <Image
                      src={
                        field === "prep_time"
                          ? Clock
                          : field === "cooking_time"
                            ? Chef
                            : Serving
                      }
                      fluid
                      alt={`${field} icon`}
                      className={styles.iconContainer}
                    />
                    {isEditing ? (
                      <input
                        type="number"
                        value={
                          field === "prep_time"
                            ? mealPrepTime
                            : field === "cooking_time"
                              ? mealCookingTime
                              : mealServing
                        }
                        onChange={(e) => {
                          if (field === "prep_time") setMealPrepTime(e.target.value);
                          else if (field === "cooking_time") setMealCookingTime(e.target.value);
                          else setMealServing(e.target.value);
                        }}
                        className={styles.editInputIcons}
                      />
                    ) : (
                      <p>
                        {
                          field === "prep_time"
                            ? mealPrepTime
                            : field === "cooking_time"
                              ? mealCookingTime
                              : mealServing
                        } {field === "serving" ? "porsjoner" : "min"}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              {isEditing && <div className={styles.imageUploadSection}>
                <h3>Last opp et eget bilde</h3>
                <input 
                  type="file" 
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                {uploadedImage && (
                  <div className={styles.imagePreview}>
                    <img 
                      src={URL.createObjectURL(uploadedImage)} 
                      alt="Forhåndsvisning" 
                      className={styles.previewImage}
                    />
                    <p>Nytt bilde valgt</p>
                  </div>
                )}
              </div>}

              {/* Næringsinnhold */}
              <div className={styles.nutritionContainer}>
                <h3>Næringsinnhold</h3>
                {mealServing > 1 && (
                    <div className={styles.customTabs}>
                        <button className={`${styles.tabButton} ${nutritionView === "perServing" ? styles.activeTab : ""}`}
                            onClick={() => setNutritionView("perServing")}>
                            Per porsjon
                        </button>
                        <button className={`${styles.tabButton} ${nutritionView === "total" ? styles.activeTab : ""}`}
                            onClick={() => setNutritionView("total")}>
                            Totalt
                        </button>
                    </div>
                )}
                <div className={styles.nutriChild}>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.kcals} kcal</div>
                        <div className={styles.nutriS}>Kalorier</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.protein}g</div>
                        <div className={styles.nutriS}>Protein</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.carbs}g</div>
                        <div className={styles.nutriS}>Karbo</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.fat}g</div>
                        <div className={styles.nutriS}>Fett</div>
                    </div>
                </div>
                </div>
            {/* <div className={styles.ingredientsContainer}>
              <h3>Ingredienser</h3>
              {mealServing > 1 && (
                <div className={styles.customTabs}>
                  <button
                    className={`${styles.tabButton} ${ingredientView === "perServing" ? styles.activeTab : ""}`}
                    onClick={() => setIngredientView("perServing")}
                  >
                    Per porsjon
                  </button>
                  <button
                    className={`${styles.tabButton} ${ingredientView === "total" ? styles.activeTab : ""}`}
                    onClick={() => setIngredientView("total")}
                  >
                    Totalt
                  </button>
                </div>
              )}
              {(mealData.isMealInstance
                ? mealData.attributes.custom_ingredients
                : mealData.attributes.meal_ingredients.data
              ).map((ingredient, index) => {
                const amount =
                  ingredientView === "perServing"
                    ? (ingredient.amount || ingredient.attributes.amount) / perServing
                    : (ingredient.amount || ingredient.attributes.amount);

                return (
                  <div className={styles.ingredientChild} key={ingredient.ingredient_name || ingredient.id}>
                    <div className={styles.ingredientIconContainer}>
                      <Image fluid alt="nutrients icon" src={IngredientIcon} />
                    </div>
                    <div>
                      {ingredient.ingredient_name ||
                        ingredient.attributes.ingredients.data[0].attributes.name}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className={styles.inputSubContainer}>
                          <span>
                            {amount} {ingredient.unit || ingredient.attributes.unit}
                          </span>
                          <button
                            onClick={() => handleRemoveIngredient(index)}
                            className={styles.removeIngredientButton}
                          >
                            Fjern
                          </button>
                        </div>
                      ) : (
                        <span>
                          {amount} {ingredient.unit || ingredient.attributes.unit}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
              {isEditing && (
                <div className={styles.addIngredientContainer}>
                    <h3>Legg til ingrediens</h3>
                    {!selectedIngredient ? ( // Hvis ingen ingrediens er valgt
                    <>
                        <input
                        type="text"
                        value={ingredientSearch}
                        onChange={handleIngredientSearch}
                        placeholder="Søk etter ingrediens"
                        className={styles.ingredientSearchInput}
                        />
                        <div className={styles.filteredIngredients}>
                        {filteredIngredients.map((ingredient) => (
                            <div
                            key={ingredient.id}
                            className={`${styles.ingredientOption} ${
                                selectedIngredient && selectedIngredient.id === ingredient.id
                                ? styles.selected
                                : ''
                            }`}
                            onClick={() => setSelectedIngredient(ingredient)} // Velg ingrediens
                            >
                            {ingredient.attributes.name}
                            </div>
                        ))}
                        </div>
                    </>
                    ) : (
                    // Når en ingrediens er valgt, vis kun inputfeltet for mengde og legg-til-knappen
                    <div className={styles.addIngredientDetails}>
                        <h4>Valgt ingrediens: {selectedIngredient.attributes.name}</h4>
                        <div className={styles.row}>
                        <input
                            type="number"
                            value={newIngredientAmount}
                            onChange={(e) => setNewIngredientAmount(e.target.value)}
                            placeholder="Mengde"
                            className={styles.ingredientAmountInput}
                        />
                        {newIngredientUnit}
                        </div>
                        <button
                        onClick={() => {
                            handleAddIngredient();
                            setSelectedIngredient(null); // Tilbakestill valget
                            setIngredientSearch(''); // Tilbakestill søkefeltet
                        }}
                        className={styles.addIngredientButton}
                        >
                        Legg til ingrediens
                        </button>
                        <button
                        onClick={() => setSelectedIngredient(null)} // Tilbakestill hvis brukeren ombestemmer seg
                        className={styles.cancelButton}
                        >
                        Avbryt
                        </button>
                    </div>
                    )}
                </div>
                )} */}
                <div className={styles.ingredientsContainer}>
                    <h3>Ingredienser</h3>
                    {mealServing > 1 && (
                        <div className={styles.customTabs}>
                            <button className={`${styles.tabButton} ${ingredientView === "perServing" ? styles.activeTab : ""}`}
                                onClick={() => setIngredientView("perServing")}>
                                Per porsjon
                            </button>
                            <button className={`${styles.tabButton} ${ingredientView === "total" ? styles.activeTab : ""}`}
                                onClick={() => setIngredientView("total")}>
                                Totalt
                            </button>
                        </div>
                    )}

                    {/* Eksisterende ingredienser */}
                    {mealIngredients.map((ingredient, index) => {
                        const amount = ingredientView === "perServing"
                            ? ingredient.attributes.amount / perServing
                            : ingredient.attributes.amount;

                        return (
                            <div className={styles.ingredientChild} key={ingredient.id || index}>
                                <div className={styles.ingredientIconContainer}>
                                    <Image fluid alt="nutrients icon" src={IngredientIcon} />
                                </div>
                                <div>{getIngredientAttribute(ingredient, "name")}</div>

                                <div className={styles.ingredientSubContainer}>
                                    {isEditing ? (
                                        <div className={styles.inputSubContainer}>
                                            <input
                                                type="number"
                                                value={ingredient.attributes.amount}
                                                onChange={(e) => handleIngredientChange(index, 'amount', e.target.value)}
                                                className={styles.inputEditAmounts}
                                            />
                                            <div className={styles.ingredientText}>
                                                {ingredient.attributes.unit}
                                            </div>
                                            <button
                                                onClick={() => handleRemoveIngredient(index)}
                                                className={styles.removeIngredientButton}
                                            >
                                                Fjern
                                            </button>
                                        </div>
                                    ) : (
                                        <div className={styles.ingredientText}>
                                            {amount} {ingredient.attributes.unit}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                      {/* Søk og legg til nye ingredienser */}
                      {isEditing && (
                          <div className={styles.addIngredientContainer}>
                              <h3>Legg til ingrediens</h3>
                              {!selectedIngredient ? (
                                  <>
                                      <input
                                          type="text"
                                          value={ingredientSearch}
                                          onChange={handleIngredientSearch}
                                          placeholder="Søk etter ingrediens"
                                          className={styles.ingredientSearchInput}
                                      />
                                      <div className={styles.filteredIngredients}>
                                          {filteredIngredients.map((ingredient) => (
                                              <div
                                                  key={ingredient.id}
                                                  className={`${styles.ingredientOption} ${
                                                      selectedIngredient && selectedIngredient.id === ingredient.id ? styles.selected : ''
                                                  }`}
                                                  onClick={() => setSelectedIngredient(ingredient)}
                                              >
                                                  {ingredient.attributes.name}
                                              </div>
                                          ))}
                                      </div>
                                  </>
                              ) : (
                                  <div className={styles.addIngredientDetails}>
                                      <h4>Valgt ingrediens: {selectedIngredient.attributes.name}</h4>
                                      <div className={styles.row}>
                                          <input
                                              type="number"
                                              value={newIngredientAmount}
                                              onChange={(e) => setNewIngredientAmount(e.target.value)}
                                              placeholder="Mengde"
                                              className={styles.ingredientAmountInput}
                                          />
                                          <select
                                              value={newIngredientUnit}
                                              onChange={(e) => setNewIngredientUnit(e.target.value)}
                                              className={styles.unitSelect}
                                          >
                                              <option value="g">g</option>
                                              <option value="stk">stk</option>
                                              <option value="dl">dl</option>
                                          </select>
                                      </div>
                                      <button
                                          onClick={() => {
                                              handleAddIngredient();
                                              setSelectedIngredient(null);
                                              setIngredientSearch('');
                                          }}
                                          className={styles.addIngredientButton}
                                      >
                                          Legg til ingrediens
                                      </button>
                                      <button
                                          onClick={() => setSelectedIngredient(null)}
                                          className={styles.cancelButton}
                                      >
                                          Avbryt
                                      </button>
                                  </div>
                              )}
                          </div>
                      )}
                </div>

              {/* Tabs (beskrivelse, prep, instruksjoner) */}
              <div className={styles.tabsContainer}>
                <div className={styles.customTabs}>
                  {["preparation", "description", "cooking_instructions"].map((tab, idx) => (
                    <button
                      key={idx}
                      className={`${styles.tabButton} ${
                        activeTab === tab ? styles.activeTab : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab === "preparation"
                        ? "Prep"
                        : tab === "description"
                          ? "Beskrivelse"
                          : "Instruksjoner"}
                    </button>
                  ))}
                </div>

                <div className={styles.tabContent}>
                  {isEditing ? (
                    <textarea
                      value={
                        activeTab === "preparation"
                          ? mealPreparation
                          : activeTab === "description"
                            ? mealDescription
                            : mealCookingInstructions
                      }
                      onChange={(e) => {
                        if (activeTab === "preparation") setMealPreparation(e.target.value);
                        else if (activeTab === "description") setMealDescription(e.target.value);
                        else setMealCookingInstructions(e.target.value);
                      }}
                      className={styles.inputTextArea}
                    />
                  ) : (
                    <p>
                      {activeTab === "preparation"
                        ? mealPreparation
                        : activeTab === "description"
                          ? mealDescription
                          : mealCookingInstructions || "Ingen info tilgjengelig"}
                    </p>
                  )}
                </div>
              </div>

              {/* Knapper (rediger / slett / lagre / avbryt) */}
              <div className={`${styles.buttonContainer} ${isEditing ? styles.editMode : ''}`}>
                {isEditing ? (
                  <>
                    <button onClick={handleEditToggle} className={styles.saveButton}>
                      Lagre
                    </button>
                    <button onClick={() => setIsEditing(false)} className={styles.cancelButton}>
                      Avbryt
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={() => setIsEditing(true)} className={styles.editButton}>
                      Rediger
                    </button>
                    <button onClick={handleDeleteMeal} className={styles.deleteButton}>
                      Slett
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* Høyre kolonne */}
            <div className={styles.rightColumn}>
              {/* <div className={styles.nutritionContainer}>
                <ClientsList allIngredients={allIngredients} mealId={mealData.id} />
              </div> */}
              <div className={styles.nutritionContainer}>
                <VariantsList
                  mealData={mealData}
                  allIngredients={allIngredients}
                  variants={variants}
                  clients={clients}
                  mealId={mealData.id}
                />
              </div>
            </div>
          </div>
        </>
      )}
            {!isDesktop && (
                <>
                <div className={styles.headerLeft}>
                  {/* <BackButton /> */}
                </div>
                <div className="col-12 d-flex d-md-none">
                    <div className="col-2 ms-2">
                        <BackButton />
                    </div>
                </div>
                <div className={styles.name}>
                    {isEditing ? (
                        <input
                            value={mealName}
                            onChange={(e) => setMealName(e.target.value)}
                            className={styles.inputName}
                        />
                    ) : (
                        <h2>{mealName}</h2>
                    )}
                </div>
                <div className={styles.category}>
                    {isEditing ? (
                        <select value={mealCategory} onChange={(e) => setMealCategory(e.target.value)} className={styles.inputName}>
                            <option value="Frokost">Frokost</option>
                            <option value="Lunsj">Lunsj</option>
                            <option value="Middag">Middag</option>
                            <option value="Kveldsmat">Kveldsmat</option>
                            <option value="Snack">Snack</option>
                            <option value="Mellommåltid">Mellommåltid</option>
                        </select>
                    ) : (
                        <p>{mealCategory}</p>
                    )}
                </div>
                <div className={styles.mealImage} style={{ backgroundImage: `url(${imageUrl})` }} />
                <div className={styles.prepInfo}>
                    {["prep_time", "cooking_time", "serving"].map((field, idx) => (
                        <div className={styles.prepChild} key={idx}>
                            <Image src={field === "prep_time" ? Clock : field === "cooking_time" ? Chef : Serving} fluid alt={`${field} icon`} className={styles.iconContainer} />
                            {isEditing ? (
                                <input
                                    type="number"
                                    value={field === "prep_time" ? mealPrepTime : field === "cooking_time" ? mealCookingTime : mealServing}
                                    onChange={(e) => field === "prep_time" ? setMealPrepTime(e.target.value) : field === "cooking_time" ? setMealCookingTime(e.target.value) : setMealServing(e.target.value)}
                                    className={styles.editInputIcons}
                                />
                            ) : (
                                <p>{field === "prep_time" ? mealPrepTime : field === "cooking_time" ? mealCookingTime : mealServing} {field === "serving" ? "måltider" : "min"}</p>
                            )}
                        </div>
                    ))}
                </div>
                {isEditing && <div className={`${styles.imageUploadSection} my-5`}>
                <h3>Last opp nytt bilde</h3>
                <input 
                  type="file" 
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                {uploadedImage && (
                  <div className={`${styles.imagePreview} col-12`}>
                    <Image 
                      fluid
                      src={URL.createObjectURL(uploadedImage)} 
                      alt="Forhåndsvisning" 
                      className={styles.previewImage}
                    />
                    <p className="text-center">Nytt bilde valgt</p>
                  </div>
                )}
              </div>}
                <div className={styles.nutritionContainer}>
                    <h3>Næringsinnhold</h3>

                    <div className={`${styles.chartContainer} d-flex flex-column align-items-center`}>
                    <h4 className="text-center">Makrofordeling</h4>
                    <div style={{ width: '250px', height: '250px' }}>
                        <Doughnut data={doughnutData} options={options} />
                    </div>
                </div>
                    {mealServing > 1 && (
                    <div className={styles.customTabs}>
                        <button className={`${styles.tabButton} ${nutritionView === "perServing" ? styles.activeTab : ""}`}
                            onClick={() => setNutritionView("perServing")}>
                            Per måltid
                        </button>
                        <button className={`${styles.tabButton} ${nutritionView === "total" ? styles.activeTab : ""}`}
                            onClick={() => setNutritionView("total")}>
                            Totalt
                        </button>
                    </div>
                    )}
                    <div className={styles.nutriChild}>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.kcals} kcal</div>
                        <div className={styles.nutriS}>Kalorier</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.protein}g</div>
                        <div className={styles.nutriS}>Protein</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.carbs}g</div>
                        <div className={styles.nutriS}>Karbo</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{displayedNutrition.fat}g</div>
                        <div className={styles.nutriS}>Fett</div>
                    </div>
                </div>
                </div>
                  <div className={styles.ingredientsContainer}>
                    <h3>Ingredienser</h3>
                    {mealServing > 1 && (
                        <div className={styles.customTabs}>
                            <button className={`${styles.tabButton} ${ingredientView === "perServing" ? styles.activeTab : ""}`}
                                onClick={() => setIngredientView("perServing")}>
                                Per porsjon
                            </button>
                            <button className={`${styles.tabButton} ${ingredientView === "total" ? styles.activeTab : ""}`}
                                onClick={() => setIngredientView("total")}>
                                Totalt
                            </button>
                        </div>
                    )}

                    {/* Eksisterende ingredienser */}
                    {mealIngredients.map((ingredient, index) => {
                        const amount = ingredientView === "perServing"
                            ? ingredient.attributes.amount / perServing
                            : ingredient.attributes.amount;

                        return (
                            <div className={styles.ingredientChild} key={ingredient.id || index}>
                                <div className={styles.ingredientIconContainer}>
                                    <Image fluid alt="nutrients icon" src={IngredientIcon} />
                                </div>
                                <div>{getIngredientAttribute(ingredient, "name")}</div>

                                <div className={styles.ingredientSubContainer}>
                                    {isEditing ? (
                                        <div className={styles.inputSubContainer}>
                                            <input
                                                type="number"
                                                value={ingredient.attributes.amount}
                                                onChange={(e) => handleIngredientChange(index, 'amount', e.target.value)}
                                                className={styles.inputEditAmounts}
                                            />
                                            <div className={styles.ingredientText}>
                                                {ingredient.attributes.unit}
                                            </div>
                                            <button
                                                onClick={() => handleRemoveIngredient(index)}
                                                className={styles.removeIngredientButton}
                                            >
                                                Fjern
                                            </button>
                                        </div>
                                    ) : (
                                        <div className={styles.ingredientText}>
                                            {amount} {ingredient.attributes.unit}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                      {/* Søk og legg til nye ingredienser */}
                      {isEditing && (
                          <div className={styles.addIngredientContainer}>
                              <h3>Legg til ingrediens</h3>
                              {!selectedIngredient ? (
                                  <>
                                      <input
                                          type="text"
                                          value={ingredientSearch}
                                          onChange={handleIngredientSearch}
                                          placeholder="Søk etter ingrediens"
                                          className={styles.ingredientSearchInput}
                                      />
                                      <div className={styles.filteredIngredients}>
                                          {filteredIngredients.map((ingredient) => (
                                              <div
                                                  key={ingredient.id}
                                                  className={`${styles.ingredientOption} ${
                                                      selectedIngredient && selectedIngredient.id === ingredient.id ? styles.selected : ''
                                                  }`}
                                                  onClick={() => setSelectedIngredient(ingredient)}
                                              >
                                                  {ingredient.attributes.name}
                                              </div>
                                          ))}
                                      </div>
                                  </>
                              ) : (
                                  <div className={styles.addIngredientDetails}>
                                      <h4>Valgt ingrediens: {selectedIngredient.attributes.name}</h4>
                                      <div className={styles.row}>
                                          <input
                                              type="number"
                                              value={newIngredientAmount}
                                              onChange={(e) => setNewIngredientAmount(e.target.value)}
                                              placeholder="Mengde"
                                              className={styles.ingredientAmountInput}
                                          />
                                          <select
                                              value={newIngredientUnit}
                                              onChange={(e) => setNewIngredientUnit(e.target.value)}
                                              className={styles.unitSelect}
                                          >
                                              <option value="g">g</option>
                                              <option value="stk">stk</option>
                                              <option value="dl">dl</option>
                                          </select>
                                      </div>
                                      <button
                                          onClick={() => {
                                              handleAddIngredient();
                                              setSelectedIngredient(null);
                                              setIngredientSearch('');
                                          }}
                                          className={styles.addIngredientButton}
                                      >
                                          Legg til ingrediens
                                      </button>
                                      <button
                                          onClick={() => setSelectedIngredient(null)}
                                          className={styles.cancelButton}
                                      >
                                          Avbryt
                                      </button>
                                  </div>
                              )}
                          </div>
                      )}
                </div>

                <div className={styles.tabsContainer}>
                    <div className={styles.customTabs}>
                        {["preparation", "description", "cooking_instructions"].map((tab, idx) => (
                            <button key={idx} className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ""}`} onClick={() => setActiveTab(tab)}>
                                {tab === "preparation" ? "Prep" : tab === "description" ? "Beskrivelse" : "Instruksjoner"}
                            </button>
                        ))}
                    </div>

                    <div className={styles.tabContent}>
                        {isEditing ? (
                            <textarea
                                value={activeTab === "preparation" ? mealPreparation : activeTab === "description" ? mealDescription : mealCookingInstructions}
                                onChange={(e) => activeTab === "preparation" ? setMealPreparation(e.target.value) : activeTab === "description" ? setMealDescription(e.target.value) : setMealCookingInstructions(e.target.value)}
                                className={styles.inputTextArea}
                            />
                        ) : (
                            <p>{activeTab === "preparation" ? mealPreparation : activeTab === "description" ? mealDescription : mealCookingInstructions || "Ingen info tilgjengelig"}</p>
                        )}
                    </div>
                </div>
                <div className={`${styles.buttonContainer} ${isEditing ? styles.editMode : ''}`}>
                    {isEditing ? (
                        <>
                            <button onClick={handleEditToggle} className={`${styles.saveButton} col-12`}>
                                Lagre
                            </button>
                            <button onClick={() => setIsEditing(false)} className={`${styles.cancelButton} col-12`}>
                                Avbryt
                            </button>
                        </>
                    ) : (
                        <>
                        <button onClick={() => setIsEditing(true)} className={`${styles.editButton} col-12`}>
                            Rediger
                        </button>
                        <button onClick={handleDeleteMeal} className={`${styles.deleteButton} col-12`}>Slett</button>
                        </>
                    )}
                </div>
                {/* <div className={styles.nutritionContainer}>
                    <ClientsList allIngredients={allIngredients} mealId={mealData?.id} />
                </div> */}
                <div className={styles.nutritionContainer}>
                    <VariantsList mealData={mealData} allIngredients={allIngredients} variants={variants} clients={clients} mealId={mealData?.id} />
                </div>
                </>
            )}
        </div>
    );
}