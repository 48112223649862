import React, { useEffect, useState, useRef } from 'react';
import css from './profile.module.scss';
//import ClientSideCommunication from '../../../global/communication/clientSide';
import { axiosAuthFetch, axiosImageAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
import Loader from '../../../global/loader';
import defaultImage from '../../../../images/blue_man.png';
import { ReactComponent as DiskIcon } from '../../../../images/icons/floppy-disk.svg';
import { ReactComponent as GalleryIcon } from '../../../../images/icons/gallery.svg';
import { ReactComponent as CameraIcon } from '../../../../images/icons/camera.svg';
// import NewCheckIn from '../../../global/forms/check-in';
import CheckInCards from '../../../global/forms/check-in/check-In-cards';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Image } from 'react-bootstrap';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { getLocalStorageItem } from '../../../global/storage';
import BottomSheetModal from '../../../global/bottomSheetModal';
import { CheckInForm } from '../../../global/forms/check-in';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const ClientSideProfilePage = () => {

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo?.user;
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    //const [programs, setPrograms] = useState([]);
    //const [workouts, setWorkouts] = useState([]);
    const [userImage, setUserImage] = useState('');
    const [checkIns, setCheckIns] = useState([]);
    const [weightDifference, setWeightDifference] = useState('');
    const [startWeight, setStartWeight] = useState('');
    const [currentWeight, setCurrentWeight] = useState('');
    const [coach, setCoach] = useState('');
    const [aboutCoach, setAboutCoach] = useState('');
    const [response, setResponse] = useState('');
    const [image, setImage] = useState();
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [coachId, setCoachId] = useState('');

    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

    const openModal = () => setBottomSheetOpen(true);
    const closeModal = () => setBottomSheetOpen(false);

    const query = `
            query{
                usersPermissionsUser(id:${user.id}){
                    data{
                        id
                        attributes{
                            profile_picture{
                                data{
                                    id
                                    attributes{
                                        url
                                    }
                                }
                            }
                            coach{
                                data{
                                    id
                                    attributes{
                                        About
                                        user{
                                            data{
                                                id
                                                attributes{
                                                    username
                                                    profile_picture{
                                                        data{
                                                            id
                                                            attributes{
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            check_ins(pagination: {limit: 100}){
                                data{
                                    id
                                    attributes{
                                        weight
                                        date
                                        comment
                                        rating_sleep
                                        rating_nutrition
                                        rating_motivation
                                        rating_training
                                        coach_comment
                                        progress_pic{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            workout_sessions(pagination: {limit: 100}){
                                data{
                                    id
                                    attributes{
                                        date_performed
                                        program{
                                            data{
                                                id
                                                attributes{
                                                    programName
                                                    cover_image{
                                                        data{
                                                            id
                                                            attributes{
                                                                images{
                                                                    data{
                                                                        id
                                                                        attributes{
                                                                            url
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            programs(pagination: {limit: 20}){
                                data{
                                    id
                                    attributes{
                                        programName
                                        cover_image{
                                            data{
                                                id
                                                attributes{
                                                    images{
                                                        data{
                                                            id
                                                            attributes{
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            `;
        
            useEffect(() => {
                async function getInfo() {
                    try{
                        setIsLoading(true);
                        const response = await axiosAuthFetch(`${API_GRAPHQL}`,{ query }, 'POST');
                        setResponse(response);
                        const attributes = response.data.usersPermissionsUser.data.attributes;
                        //setWorkouts(attributes.workout_sessions.data);
                        //setPrograms(attributes.programs.data);
                        setCheckIns(attributes.check_ins.data);
                        setCoach(attributes.coach.data.attributes.user.data.attributes);
                        setAboutCoach(attributes.coach.data.attributes.About);
                        setCoachId(attributes.coach.data.id);
                    } catch(error){
                        console.error(error);
                        setIsError(true);
                    } finally{
                        setIsLoading(false);
                    }
                }

                getInfo();
                //eslint-disable-next-line
            }, [query]);

            useEffect(() => {
                if (response && response.data && response.data.usersPermissionsUser && response.data.usersPermissionsUser.data.attributes) {
                    const attributes = response.data.usersPermissionsUser.data.attributes;
                    if (attributes.profile_picture && attributes.profile_picture.data && attributes.profile_picture.data.attributes) {
                        setUserImage(attributes.profile_picture.data.attributes.url);
                    }
                    if (checkIns.length > 1) {
                        const sortedCheckIns = [...checkIns].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));
                        const oldestWeight = sortedCheckIns[sortedCheckIns.length - 1].attributes.weight;
                        const newestWeight = sortedCheckIns[0].attributes.weight;
                        const difference = newestWeight - oldestWeight;
                        setCurrentWeight(newestWeight);
                        setStartWeight(oldestWeight);
                        setWeightDifference(difference);
                    } else if (checkIns.length === 1) {
                        const onlyWeight = checkIns[0].attributes.weight;
                        setCurrentWeight(onlyWeight);
                        setStartWeight(onlyWeight);
                        setWeightDifference(0);
                    }
                }
            }, [response, checkIns]);


            // const redirectToPrograms = () => {
            //     window.location.href="/training";
            // }
            const formatDate = (dateString) => {
                const options = { day: '2-digit', month: '2-digit' };
                return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
            };

            // const filterCheckInsByPeriod = () => {
            //     const now = new Date();
            //     const filteredCheckIns = checkIns.filter(checkIn => {
            //         const checkInDate = new Date(checkIn.attributes.date);
            //         if (period === 'month') {
            //             return checkInDate >= new Date(now.getFullYear(), now.getMonth(), 1);
            //         } else if (period === 'year') {
            //             return checkInDate >= new Date(now.getFullYear(), 0, 1);
            //         }
            //         return true;
            //     });
            //     return filteredCheckIns;
            // };

            // const handleImageChange = (e) => {
            //     setImage(e.target.files[0]);
            // }

            const handleImageChange = async (sourceType) => {
                try {
                  const image = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                    source: sourceType,
                    quality: 90,
                    saveToGallery: true,
                  });
                  console.log('Captured Image:', image);
                  setImage(image);
                  setUserImage(image.webPath);
                  setIsImageSelected(true);
                } catch (error) {
                  console.error("Image selection canceled or failed:", error);
                }
              };

            const fileInputRef = useRef(null);

            const handleFileUpload = (e) => {
            const file = e.target.files[0];
            if (file) {
                const imageUrl = URL.createObjectURL(file);
                setImage(file);
                setUserImage(imageUrl);
                setIsImageSelected(true);
            }
            };

            // const handleSubmit = async () => {
            //     setIsLoading(true);

            //     const formData = new FormData();

            //     formData.append('files', image);

            // try{
            //     const uploadResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload/`, formData, 'POST');
            //     console.log(uploadResponse);

            //     const uploadedImage = uploadResponse[0].id;
            //     console.log(uploadedImage);

            //     const updateData = {
            //         profile_picture: uploadedImage,
            //     }

            //     const response = await axiosAuthFetch(`${API_BASE_URL}/users/${user.id}`, updateData, 'PUT');

            //     console.log(response);
            //     alert('Profilbilde byttet!')
            //     setIsLoading(false);
            //     window.location.reload();
            // } catch(error){
            //     console.error(error);
            //     setIsLoading(false);
            // }
            // };

            const handleSubmit = async () => {
                setIsLoading(true);
              
                const formData = new FormData();
              
                console.log('Image object:', image);
              
                if (image && (image.path || image.webPath)) {
                  console.log('Image from Camera or Gallery');
              
                  let blob;
                  if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
                    // Les filen ved hjelp av Filesystem-pluginen
                    const fileData = await Filesystem.readFile({
                      path: image.path,
                    });
                    // Konverter base64 til Blob
                    const byteCharacters = atob(fileData.data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                      byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    blob = new Blob([byteArray], { type: `image/${image.format}` });
                  } else {
                    // For web
                    const response = await fetch(image.webPath);
                    blob = await response.blob();
                  }
              
                  console.log('Blob:', blob);
                  formData.append('files', blob, `image.${image.format}`);
                } else if (image) {
                  console.log('Image from File Upload');
                  formData.append('files', image);
                } else {
                  console.error('No image selected');
                  setIsLoading(false);
                  return;
                }
              
                try {
                  const uploadResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload/`, formData, 'POST');
                  console.log('Upload Response:', uploadResponse);
              
                  const uploadedImage = uploadResponse[0].id;
                  console.log('Uploaded Image ID:', uploadedImage);
              
                  const updateData = {
                    profile_picture: uploadedImage,
                  };
              
                  const response = await axiosAuthFetch(`${API_BASE_URL}/users/${user.id}`, updateData, 'PUT');
                  console.log('User Update Response:', response);
              
                  alert('Profilbilde byttet!');
                  setIsLoading(false);
                  setIsImageSelected(false);
                  window.location.reload();
                } catch (error) {
                  console.error('Error uploading image:', error);
                  setIsLoading(false);
                }
              };


              function formatWeight(weight, includeSign = false) {
                const roundedWeight = Math.round(weight * 10) / 10; // Runder av til én desimal
                const sign = includeSign && roundedWeight > 0 ? "+ " : ""; // Legger til "+" for positive tall hvis includeSign er true
            
                if (Number.isInteger(roundedWeight)) {
                    return `${sign}${roundedWeight.toFixed(0)}`; // Viser kun heltall hvis desimalen er 0
                } else {
                    return `${sign}${roundedWeight.toFixed(1)}`; // Viser én desimal hvis desimalen er større enn 0
                }
            }

            const getWeightChartData = () => {
                const sortedCheckIns = [...checkIns].sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date));
                const dates = sortedCheckIns.map(checkIn => formatDate(checkIn.attributes.date));
                const weights = sortedCheckIns.map(checkIn => checkIn.attributes.weight);

                return {
                    labels: dates,
                    datasets: [
                        {
                            label: 'Vekt (kg)',
                            data: weights,
                            borderColor: 'rgba(0, 0, 0, 0.25)',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            fill: true,
                            pointRadius: 0, // Remove point markers
                            borderWidth: 2 // Adjust line thickness
                        }
                    ]
                };
            };

            const getWeightChartOptions = () => {
                return {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false // Hide legend
                        }
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Dato',
                            },
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            title: {
                                display: false,
                                text: 'Vekt (kg)'
                            },
                            grid: {
                                display: false
                            }
                        }
                    }
                };
            };

            const imageUrl = userImage || defaultImage;

            if(isLoading || !response) {
                return(<Loader />)
            }
            if(isError){
                return(<div>Oida, noe gikk galt! Vennligst prøv å last inn siden på nytt!</div>)
            }

            //const sortedWorkouts = [...workouts].sort((a, b) => new Date(b.attributes.date_performed) - new Date(a.attributes.date_performed));
            // console.log(sortedWorkouts);

    return(
        <div className={`${css.container} pt-4 appearing-container`}>
            <div className={css.userInfo}>
                <div className={css.userInfoImageContainer}>
                    <Image className={`${css.userInfoImage}`} fluid alt="profile-picture" src={imageUrl} />
                </div>
                <div className={`${css.userName} my-4`}>
                    Hei {user.username}!
                </div>
                <div className={css.uploadProfileImageContainer}>
                    <div className={css.subDiv}>Last opp profilbilde:</div>
                    <div className={css.buttonContainer}>
                        {/* <button
                        type="button"
                        onClick={() => handleImageChange(CameraSource.Photos)}
                        className={css.imageButtons}
                        >
                        Album
                        </button> */}
                        <div className={css.iconContainer}>
                            <GalleryIcon className={css.diskIcon} onClick={() => handleImageChange(CameraSource.Photos)}/>
                        </div>
                        {/* <button
                        type="button"
                        onClick={() => handleImageChange(CameraSource.Camera)}
                        className={css.imageButtons}
                        >
                        Ta et bilde
                        </button> */}
                        <div className={css.iconContainer}>
                            <CameraIcon  className={css.diskIcon} onClick={() => handleImageChange(CameraSource.Camera)}/>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                        </div>
                        <div className={css.iconContainer}>
                            <DiskIcon  className={css.diskIcon} onClick={() => fileInputRef.current.click()}/>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                        </div>
                        {/* <button type="button" className={css.imageButtons} onClick={() => fileInputRef.current.click()}>
                        Last opp fil
                        </button> */}
                    </div>
                    {isImageSelected && (
                        <div className={css.previewMessage}>
                            Dette er en forhåndsvisning. Trykk <strong>"Last opp"</strong> for å lagre endringen.
                        </div>
                    )}
                    {/* <div className={css.formButton} onClick={handleSubmit}>
                        Last opp
                    </div> */}
                    <div className={`${css.formButton} ${!image ? css.disabled : ''}`}
                    onClick={image ? handleSubmit : null}>
                        Last opp
                    </div>
                </div>
                {/* <div className={css.uploadProfileImageConntainer}>
                    <form className={css.form}>
                        <label>Last opp profilbilde:</label>
                        <input type="file" onChange={handleImageChange} />
                        <div className={css.formButton} onClick={handleSubmit}>Last opp</div>
                    </form>
                </div> */}
                <div className={css.chartContainer}>
                    <div className={css.chartHeader}>Vektprogresjon</div>
                    {/* <div className={css.periodToggle}>
                        <button onClick={() => setPeriod('month')} className={period === 'month' ? css.active : ''}>Måned</button>
                        <button onClick={() => setPeriod('year')} className={period === 'year' ? css.active : ''}>År</button>
                    </div> */}
                    <Line data={getWeightChartData()} options={getWeightChartOptions()}/>
                    <div className={css.weights}>
                        <p>Startvekt: {formatWeight(startWeight)} kg</p>
                        <p>Nåværende vekt: {formatWeight(currentWeight)} kg</p>
                        <p className={css.total}>Din totale fremgang: {formatWeight(weightDifference, true)} kg</p>
                    </div>
                </div>
            </div>
            <div className={`${css.coachDiv} mt-4`}>
                <div className={css.coachOverskrift}>Din coach</div>
                <div className={css.coachCard}>
                    <div className={css.coachImageDiv} style={{backgroundImage:`url(${coach?.profile_picture?.data?.attributes?.url})`}}></div>
                    <div className={css.coachInfo}>
                        <h4>{coach.username}</h4>
                        <p>{aboutCoach}</p>
                    </div>
                </div>
            </div>
            <div className={css.checkInDiv}>
                <div className={css.checkInHeader}>
                    <div className={css.checkInOverskrift}>Dine check-ins</div>
                    <div className={css.checkInKnapp} onClick={openModal}>Ny check-in</div>
                </div>
                <div className={css.checkInCardsContainer}>
                    <CheckInCards checkIns={checkIns}/>
                </div>
            </div>
            <BottomSheetModal isOpen={bottomSheetOpen} onClose={closeModal} breakpoints={[0.9]} initialBreakpointIndex={0}>
                <CheckInForm coachId={coachId} user={user} checkIns={checkIns}/>
            </BottomSheetModal>
        </div>
    )
}

export default ClientSideProfilePage;