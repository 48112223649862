import React, { useState, useEffect, useRef } from 'react';
import { axiosAuthFetch } from '../../../../helpers/axios';
import Loader from '../../../global/loader';
import templateCss from '../template/template.module.css';
import styles from './styles.module.scss';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../global/storage';
import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
//import CustomAlertModal from '../../../global/customModal';
import BottomSheetModal from '../../../global/bottomSheetModal';
import '../../../transitions.css';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ClientTemplatePage() {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const userInfo = getLocalStorageItem('limitless-user');
  const clientID = userInfo.user.id;
  //console.log(clientID);
  const [searchText, setSearchText] = useState('');
  const [currentProgram, setCurrentProgram] = useState(null);
  const [workoutData, setWorkoutData] = useState([]);
  //const [showModal, setShowModal] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef(null);
  const [workoutName, setWorkoutName] = useState('');
  const [startTime, setStartTime] = useState(null); // Added startTime state
  //eslint-disable-next-line
  const [endTime, setEndTime] = useState(null);     // Added endTime startTime
  // const [isNavigatingForward, setIsNavigatingForward] = useState(true);
  const [clientComment, setClientComment] = useState('');
  // const [showPreviousWorkout, setShowPreviousWorkout] = useState(false);
  // const [previousWorkoutData, setPreviousWorkoutData] = useState(null);

  const [hasIncompleteWorkout, setHasIncompleteWorkout] = useState(false);

  // New state to manage views
  const [currentView, setCurrentView] = useState('main');
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [forceOpen, setForceOpen] = useState(false);

  const [exerciseBank, setExerciseBank] = useState([]);
  const [showExerciseSearch, setShowExerciseSearch] = useState(false);
  const [exerciseSearch, setExerciseSearch] = useState("");

  // Holds fetched history logs keyed by exerciseId
const [exerciseHistories, setExerciseHistories] = useState({});

// Controls whether we are currently showing the history for a given exercise
// If you want a more advanced approach, you might store e.g. { [exerciseId]: boolean } instead.
const [openHistoryExerciseId, setOpenHistoryExerciseId] = useState(null);


const shortMonthNames = [
  'jan', 'feb', 'mars', 'apr', 'mai', 'jun',
  'jul', 'aug', 'sep', 'okt', 'nov', 'des'
];

function formatDateDdShortMonth(isoString) {
  if (!isoString) return '';

  const date = new Date(isoString);
  const day = date.getDate();
  const month = date.getMonth(); // 0-11
  // Finn kort månedsnavn fra arrayet over:
  const monthShort = shortMonthNames[month];

  return `${day}.${monthShort}`;
}

  // New state for rating
  const [rating, setRating] = useState(0);

  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

  const query = `query{
    programs(filters:{users:{id:{eq:${clientID}}}} pagination: {limit: 25}) {
      data{
        id
        attributes{
          programName
          pairs{
            data{
              id
              attributes{
                description
                comment
                coach_comment
                exercise{
                  data{
                    id
                    attributes{
                      name
                      description
                      image{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          cover_image{
            data{
              id
              attributes{
                images{
                  data{
                    id
                    attributes{
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `;

  useEffect(() => {
    async function getPrograms() {
      setIsLoading(true);
      setIsError(false);
      try {
        const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
        console.log(response.data);
        setPrograms(response.data.programs.data);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
    getPrograms();
  }, [query]);

  useEffect(() => {
    const savedWorkout = getLocalStorageItem('incompleteWorkout');
    if (savedWorkout) {
      setHasIncompleteWorkout(true);
    }
  }, []);

  const fetchExerciseBank = async () => {
    try {
      const response = await axiosAuthFetch(`${API_BASE_URL}/exercises?populate=*`, {}, 'GET');
      console.log(response);
      setExerciseBank(response.data);
    } catch (error) {
      console.error("Feil ved henting av øvelser", error);
    }
  };

  const fetchExerciseHistory = async (exerciseId) => {
    try {

      const query = `
        query{
          exerciseLogs(filters: { 
            exercise: { id: { eq: ${exerciseId} } } 
            workout_session: {
              client: { id: { eq: ${clientID} } }
            }
          }
          pagination: { limit: 5 }
          sort: "createdAt:desc"
          ) {
            data {
              id
              attributes{
                sett
                reps
                vekt
                createdAt
              }
            }
          }
        }
      `
      // This call filters logs by exerciseId, sorts descending by createdAt, limiting to 5
      // Adjust the query parameters to match your Strapi setup
      // const url = `${API_BASE_URL}/exercise-logs?filters[exercise][id][$eq]=${exerciseId}&sort=createdAt:desc&pagination[limit]=5&populate=workout_session`;

      //const response = await axiosAuthFetch(url, {}, 'GET');
      const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');
      //console.log(response.data);
  
      // Suppose the response data is an array of logs
      const logs = response?.data?.exerciseLogs?.data || [];

      setExerciseHistories(prev => ({
        ...prev,
        [exerciseId]: logs,
      }));
  
      // After fetching, we "open" the panel for that exercise
      setOpenHistoryExerciseId(exerciseId);
  
    } catch (error) {
      console.error('Failed to fetch exercise history:', error);
    }
  };
  

  const handleAddExerciseClick = () => {
    if (exerciseBank.length === 0) {
      fetchExerciseBank();
    }
    setShowExerciseSearch(true);
  };

  const addExerciseFromBank = (exercise) => {
    const newExercise = {
      pairId: `custom-${Date.now()}`,
      exerciseName: exercise.attributes.name,
      exerciseId: exercise.id,
      imageUrl: exercise.attributes.image?.data?.[0]?.attributes?.url || 'default-image.jpg',
      order: workoutData.length + 1,
      sets: [{
        targetReps: '',
        reps: '',
        weight: '',
      }],
      isCustom: true,
    };
  
    const updatedData = [...workoutData, newExercise];
    setWorkoutData(updatedData);
    saveWorkoutProgress(updatedData);
    setShowExerciseSearch(false);
  };

  useEffect(() => {
    if (bottomSheetOpen && startTime) {
      timerRef.current = setInterval(() => {
        const now = new Date();
        const elapsed = Math.floor((now - startTime) / 1000);
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [bottomSheetOpen, startTime]);

  if (isLoading || !programs) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return <div>Oida, her har det skjedd noe galt!</div>;
  }

  const addSet = (pairIndex) => {
    const updatedData = [...workoutData];
    updatedData[pairIndex].sets.push({
      targetReps: '', // Kan eventuelt settes til en default verdi om ønskelig
      reps: '',
      weight: '',
    });
    setWorkoutData(updatedData);
    saveWorkoutProgress(updatedData);
  };
  
  const removeSet = (pairIndex, setIndex) => {
    const updatedData = [...workoutData];
    // Sørg for at det er minst ett sett igjen, eller bekreft med brukeren
    if (updatedData[pairIndex].sets.length > 1) {
      updatedData[pairIndex].sets.splice(setIndex, 1);
      setWorkoutData(updatedData);
      saveWorkoutProgress(updatedData);
    } else {
      window.alert("Du må ha minst ett sett per øvelse.");
    }
  };

  // const addExercise = () => {
  //   // Her kan du velge å åpne en modal der brukeren velger en øvelse,
  //   // eller du kan opprette en ny "custom" øvelse direkte.
  //   const newExercise = {
  //     pairId: `custom-${Date.now()}`, // Unik id for custom øvelse
  //     exerciseName: 'Ny øvelse', // Du kan la brukeren redigere dette
  //     exerciseId: null, // null eller en valgt øvelse hvis du har en liste
  //     order: workoutData.length + 1,
  //     sets: [{
  //       targetReps: '',
  //       reps: '',
  //       weight: '',
  //     }],
  //     isCustom: true, // Marker at denne øvelsen er lagt til av brukeren
  //   };
  //   const updatedData = [...workoutData, newExercise];
  //   setWorkoutData(updatedData);
  //   saveWorkoutProgress(updatedData);
  // };
  
  const removeExercise = (pairIndex) => {
    // Bekreft med brukeren før sletting
    if (window.confirm("Er du sikker på at du vil fjerne denne øvelsen?")) {
      const updatedData = [...workoutData];
      updatedData.splice(pairIndex, 1);
      setWorkoutData(updatedData);
      saveWorkoutProgress(updatedData);
    }
  };

  const saveWorkoutProgress = (updatedWorkoutData) => {
    if (!currentProgram) return;
  
    const workoutState = {
      workoutData: updatedWorkoutData,
      workoutName,
      startTime: startTime ? startTime.toISOString() : null,
      clientComment,
      rating,
      elapsedTime,
      currentProgram,
    };
  
    setLocalStorageItem('incompleteWorkout', workoutState);
  };

  const handleResumeWorkout = () => {
    const savedWorkout = getLocalStorageItem('incompleteWorkout');
    if (savedWorkout) {
      setWorkoutData(savedWorkout.workoutData);
      setWorkoutName(savedWorkout.workoutName);
      setStartTime(savedWorkout.startTime ? new Date(savedWorkout.startTime) : null);
      setClientComment(savedWorkout.clientComment);
      setRating(savedWorkout.rating);
      setElapsedTime(savedWorkout.elapsedTime);
      setCurrentProgram(savedWorkout.currentProgram);
      setBottomSheetOpen(true);
      setHasIncompleteWorkout(false); // Slett banneret når økta gjenopptas
    }
  };

  const handleDiscardWorkout = () => {
          if(window.confirm('Er du sikker på at du vil slette økta?')) {
              removeLocalStorageItem('incompleteWorkout');
              setHasIncompleteWorkout(false);
          }
      }

  const openWorkoutModal = (program) => {
    setCurrentProgram(program);
    //setShowModal(true);
    setBottomSheetOpen(true);
    setElapsedTime(0);
    setRating(0);
    setWorkoutName(program.attributes.programName);
    setStartTime(new Date());
    // setShowPreviousWorkout(false);
    // setPreviousWorkoutData(null);

    const workoutPairs = program.attributes.pairs.data.map((pair, index) => {
      const setsCount = parseInt(pair.attributes.description, 10);
      const targetReps = pair.attributes.comment.split('/').map((rep) => rep.trim());
      const exerciseData = pair.attributes.exercise.data;
      const imageUrl =
      exerciseData.attributes.image?.data?.[0]?.attributes?.url || 'default-image.jpg';

      return {
        pairId: pair.id,
        exerciseName: pair.attributes.exercise.data.attributes.name,
        exerciseId: pair.attributes.exercise.data.id,
        imageUrl,
        order: index + 1,
        sets: Array.from({ length: setsCount }, (_, index) => ({
          targetReps: targetReps[index] || '',
          reps: '',
          weight: '',
        })),
      };
    });

    setWorkoutData(workoutPairs);
    //fetchPreviousWorkout(program.id); // Fetch previous workout when opening the modal
  };

  // const togglePreviousWorkout = () => {
  //   setShowPreviousWorkout(!showPreviousWorkout); // Toggle between current and previous workout view
  // };

  const handleInputChange = (pairIndex, setIndex, field, value) => {
    const updatedData = [...workoutData];

    if (field === 'weight') {
      value = value.replace(',', '.');
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    }

    updatedData[pairIndex].sets[setIndex][field] = value;
    setWorkoutData(updatedData);

    saveWorkoutProgress(updatedData);
  };

  const handleCommentChange = (e) => {
    setClientComment(e.target.value);
    saveWorkoutProgress(workoutData);
  }

  const confirmCloseModal = () => {
    const confirmed = window.confirm('Er du sikker på at du vil avslutte økta?');
    if (confirmed) {
      //setShowModal(false);
      setBottomSheetOpen(false);
      clearInterval(timerRef.current);
      setElapsedTime(0);
      setStartTime(null);
      setEndTime(null);
      setForceOpen(false);
    } else {
      setForceOpen(true);
    }
  };

  const handleModalClose = () => {
    // Modalen lukkes umiddelbart uten bekreftelse
    setBottomSheetOpen(false);
    clearInterval(timerRef.current);
    setElapsedTime(0);
    setStartTime(null);
    setEndTime(null);
    setForceOpen(false);
  };

  const confirmEndWorkout = async () => {
    const confirmed = window.confirm(
      'Er du helt sikker på at du vil avslutte økta? Du kan ikke endre inputs etter at økta er lagret'
    );
    if (confirmed) {
      endWorkout();
    }
  };

  const endWorkout = async () => {
    setIsLoading(true);
    try {
      clearInterval(timerRef.current);
      const endTimeValue = new Date();
      setEndTime(endTimeValue); // Set the end time

      const durationInSeconds = Math.floor((endTimeValue - startTime) / 1000);

      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = durationInSeconds % 60;

      const duration = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      const workoutSessionResponse = await axiosAuthFetch(
        `${API_BASE_URL}/workout-sessions`,
        {
          data: {
            client: clientID,
            program: currentProgram.id,
            date_performed: new Date(),
            duration: duration,
            rating: rating,
            name: workoutName,
            clients_comments: clientComment,
            start_time: startTime.toISOString(),
            end_time: endTimeValue.toISOString(),
          },
        },
        'POST'
      );

      const workoutSession = workoutSessionResponse.data;

      // Create exercise logs, now including the 'order' field
      for (const data of workoutData) {
        for (let setIndex = 0; setIndex < data.sets.length; setIndex++) {
          const set = data.sets[setIndex];

          const weightValue = parseFloat(set.weight);

          await axiosAuthFetch(
            `${API_BASE_URL}/exercise-logs`,
            {
              data: {
                exercise: data.exerciseId,
                sett: setIndex + 1,
                reps: set.reps,
                vekt: weightValue,
                order: data.order, // Ensure the order is sent with each exercise log
                workout_session: workoutSession.id,
              },
            },
            'POST'
          );
        }
      }
      alert('Økt lagret!');
      //setShowModal(false);
      setBottomSheetOpen(false);
      removeLocalStorageItem('incompleteWorkout'); // Close the modal after logging the workout
    } catch (error) {
      console.error('Error ending workout session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to open detail view
  const openWorkoutDetail = (program) => {
    //setIsNavigatingForward(true);
    setSelectedProgram(program);
    setCurrentView('detail');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to go back to main view
  const goBackToMain = () => {
    //setIsNavigatingForward(false);
    setCurrentView('main');
    setSelectedProgram(null);
  };

  const sliderSettings = {
    dots: true,
    appendDots: dots => (
      <div style={{ position: 'absolute', top: '0', width: '100%', zIndex: -1 }}>
        <ul style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', }}>
        {dots.map((dot, index) =>
        React.cloneElement(dot, { style: { margin: '0 -1px' } })
      )}
        </ul>
      </div>
    ),
    arrows: true,        // viser navigasjonspiler
    infinite: false,     // dersom du ikke ønsker uendelig looping
    speed: 500,
    slidesToShow: 1,     // én øvelse per "screen"
    slidesToScroll: 1,
  };

  const filteredPrograms = programs.filter((program) =>
    program.attributes.programName.toLowerCase().includes(searchText.toLowerCase())
  );

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${seconds}s`;
  };

  const StarRating = ({ rating, setRating }) => {
    const handleRating = (index) => {
      setRating(index + 1);
    };

    return (
      <div className={styles.stars}>
        {[...Array(5)].map((star, index) => {
          return (
            <span
              key={index}
              className={`${styles.star} ${index < rating ? styles.filled : ''}`}
              onClick={() => handleRating(index)}
            >
              ★
            </span>
          );
        })}
      </div>
    );
  };


  return (
        <div className={`${styles.container1}`}>
          {currentView === 'main' && (
            <div className={ `${styles.sub1} appearing-container`}>
              {hasIncompleteWorkout && (
                <div className="shadow-lg rounded-3 col-11 p-5">
                  <p className="text-center">Du har en ufullstendig økt lagret.</p>
                  <div className={styles.resumeBanner}>
                    <div className={`${styles.resumeButton} p-2 col-6 me-3`} onClick={handleResumeWorkout}>Fortsett økt</div>
                    <div className={`${styles.discardButton} p-2 col-6 ms-3`} onClick={handleDiscardWorkout}>Slett økt</div>
                  </div>
                </div>
                
              )}
              <p className={templateCss.overskrift}>DINE PROGRAM</p>
              <input
                className={templateCss.search}
                type="text"
                placeholder="Søk etter program..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className={styles.programList}>
                {filteredPrograms.map((program) => {
                  //eslint-disable-next-line
                  const { programName, pairs, cover_image } = program.attributes;
                  const imageUrl = cover_image.data.attributes.images.data[0].attributes.url;
  
                  return (
                    <div
                      className={styles.programItem}
                      key={program.id}
                      onClick={() => openWorkoutDetail(program)}
                      style={{ backgroundImage: `url(${imageUrl})` }}
                    >
                      <div className={styles.text}>{programName}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
  
          {currentView === 'detail' && selectedProgram && (
            <div className={templateCss.detailView}>
              <div
                className={styles.header}
                style={{
                  backgroundImage: `url(${selectedProgram?.attributes?.cover_image?.data?.attributes?.images?.data[0]?.attributes?.url})`,
                }}
              >
                <div onClick={goBackToMain} className={styles.backButton}>
                  Tilbake
                </div>
                <div className={styles.programTitle}>{selectedProgram.attributes.programName}</div>
              </div>
              <div className={styles.programDetails}>
                {selectedProgram.attributes.pairs.data.map((pair, index) => {
                  const { description, comment, exercise } = pair.attributes;
                  return (
                    <div className={`${templateCss.openAccordionContainer1}`} key={pair.id}>
                      <div
                        className={templateCss.accordionCardImageContainer}
                        style={{
                          backgroundImage: `url(${exercise.data.attributes.image.data[0].attributes.url})`,
                        }}
                      ></div>
                      <div className={templateCss.accordionContainer2}>
                        <p className={templateCss.exName}>{exercise.data.attributes.name}</p>
                        <p className={templateCss.setsReps}>Sett: {description}</p>
                        <p className={templateCss.comment}>Reps: {comment}</p>
                        {/* <p className={templateCss.comment}>{coach_comment}</p> */}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={templateCss.buttonContainer}>
                <div
                  className={templateCss.startButton}
                  onClick={() => openWorkoutModal(selectedProgram)}
                >
                  START ØKT
                </div>
              </div>
            </div>
          )}
  
          {currentProgram && (
//             <BottomSheetModal title={currentProgram.programName} isOpen={bottomSheetOpen || forceOpen} onClose={handleModalClose} onBackdropClick={confirmCloseModal} breakpoints={[0.95]} initialBreakpointIndex={1}>
//               <div className={templateCss.modalContent}>
//                   <h2 className="text-center">{currentProgram.attributes.programName}</h2>
//                   <div className={templateCss.timerContainer}>
//                     <div className={templateCss.timer}>Tid: {formatTime(elapsedTime)}</div>
//                   </div>
//                   {workoutData.map((data, pairIndex) => {
//                      const imageUrl =
//                      data?.imageUrl ||
//                      currentProgram.attributes.pairs.data[pairIndex]?.attributes.exercise.data.attributes.image.data[0]?.attributes?.url ||
//                      'default-image.jpg';
//                     return(<div key={data.pairId} className={templateCss.exerciseCard}>
//                       <div className={templateCss.cardHeader}>
//                         <div
//                           className={templateCss.cardHeaderImageContainer}
//                           style={{
//                             backgroundImage: `url(${imageUrl})`,
//                           }}
//                         />
//                         <div className={templateCss.exerciseNameDiv}>
//                           <p className={templateCss.exerciseName}>{data.exerciseName}</p>
//                         </div>
//                         <div className={`${styles.removeButton} p-2`} onClick={() => removeExercise(pairIndex)}>X</div>
//                       </div>
//                       <div className={templateCss.subHeader}>
//                         <div className={templateCss.subSet}>Sett</div>
//                         <div className={templateCss.subReps}>Rep-mål</div>
//                         <div className={templateCss.subReps}>Reps gjort</div>
//                         <div className={templateCss.subReps}>Vekt</div>
//                       </div>
//                       {data.sets.map((set, setIndex) => (
//                         <div key={setIndex} className={templateCss.set}>
//                           {setIndex + 1}.
//                           <div className={templateCss.presetReps}>{set.targetReps}</div>
//                           <input
//                             type="tel"
//                             value={set.reps}
//                             onChange={(e) =>
//                               handleInputChange(pairIndex, setIndex, 'reps', e.target.value)
//                             }
//                             placeholder="Reps"
//                             className={`${templateCss.input} ${templateCss.setInput}`}
//                           />
//                           <input
//                             type="text"
//                             inputMode="decimal"
//                             value={set.weight}
//                             onChange={(e) =>
//                               handleInputChange(pairIndex, setIndex, 'weight', e.target.value)
//                             }
//                             placeholder="Vekt (kg)"
//                             className={templateCss.input}
//                           />
//                           <div className={`${styles.removeSet} px-2`} onClick={() => removeSet(pairIndex, setIndex)}>Fjern</div>
//                         </div>
//                       ))}
//                       <div className={`${styles.addSet} col-11 py-1`} onClick={() => addSet(pairIndex)}>Legg til sett</div>
//                       <div className="col-12 d-flex justify-content-center">
//                       <div
//                       className={`${styles.historyButton} shadow-lg col-11 py-1 mt-1 text-center`}
//                       onClick={() => {
//                         // 1) Hvis vi allerede har data i state, toggler vi
//                         if (exerciseHistories[data.exerciseId]) {
//                           setOpenHistoryExerciseId(
//                             openHistoryExerciseId === data.exerciseId ? null : data.exerciseId
//                           );
//                         } else {
//                           // 2) Hvis ikke, fetch
//                           fetchExerciseHistory(data.exerciseId);
//                         }
//                       }}
//                     >
//                       Vis historikk
//                     </div>
//                 </div>
// {openHistoryExerciseId === data.exerciseId && (
//   <div className={styles.historyContainer}>
//     <div className="text-center py-2">Dine siste fem sett</div>
//     <div className="d-flex align-items-center justify-content-between col-12">
//       <strong className="col-3 text-center">Når</strong>
//       <strong className="col-3 text-center">Reps</strong>
//       <strong className="col-3 text-center">Vekt</strong>
//     </div>
//     {exerciseHistories[data.exerciseId] ? (
//       exerciseHistories[data.exerciseId].length > 0 ? (
//         exerciseHistories[data.exerciseId].map((logItem) => {
//           // Hent ut sett, reps, vekt fra log-objektet
//           const logAttrs = logItem.attributes;
//           const date = formatDateDdShortMonth(logAttrs?.createdAt);
//           //eslint-disable-next-line
//           const sett = logAttrs?.sett;
//           const reps = logAttrs?.reps;
//           const vekt = logAttrs?.vekt;

//           return (
//             <div key={logItem.id} className={styles.historyItem}>
//               <div className="col-3 text-center">
//                 {date}
//               </div>
//               <div className="col-3 text-center">
//                 {reps}
//               </div>
//               <div className="col-3 text-center">
//                 {vekt}
//               </div>
//             </div>
//           );
//         })
//       ) : (
//         <p className={styles.noHistory}>Ingen historikk funnet</p>
//       )
//     ) : (
//       <p className={styles.noHistory}>Laster historikk ...</p>
//     )}
//   </div>
// )}

//                     </div>
//                   //))})
//                 )})}
//               <div className={styles.addExerciseContainer}>
//                 <div className={`${styles.addSet} ${styles.addExercise} col-11 py-1`} onClick={handleAddExerciseClick}>Legg til øvelse</div>
//               </div>
//               {showExerciseSearch && (
//                     <div className={styles.exerciseSearchPanel}>
//                       <input
//                         type="text"
//                         placeholder="Søk etter øvelse..."
//                         value={exerciseSearch}
//                         onChange={(e) => setExerciseSearch(e.target.value)}
//                         className={styles.searchInput}
//                       />
//                       <div className={styles.exerciseList}>
//                         {exerciseBank
//                           .filter((ex) =>
//                             ex?.attributes?.name?.toLowerCase().includes(exerciseSearch.toLowerCase())
//                           )
//                           .map((ex) => (
//                             <div
//                               key={ex.id}
//                               className={`${styles.exerciseItem}`}
//                               onClick={() => addExerciseFromBank(ex)}
//                             >
//                               <img
//                                 src={ex?.attributes?.image?.data?.[0]?.attributes?.url || 'default-image.jpg'}
//                                 alt={ex?.attributes?.name}
//                                 className={styles.exerciseImage}
//                               />
//                               {ex.attributes.name}
//                             </div>
//                           ))}
//                       </div>
//                       <button onClick={() => setShowExerciseSearch(false)} className={styles.closeSearchBtn}>
//                         Lukk søk
//                       </button>
//                     </div>
//                   )}
//                   <div className={styles.clientComment}>
//                     <label>Din kommentar:</label>
//                     <textarea 
//                       className={styles.clientCommentBox}
//                       value={clientComment}
//                       onChange={handleCommentChange}
//                       rows="6"
//                     >
//                     </textarea>
//                   </div>
//                   <div className={styles.inputRating}>
//                     <label>Rating:</label>
//                     <StarRating rating={rating} setRating={setRating} />
//                   </div>
//                 </div>
//                 <div className={styles.submitButton} onClick={confirmEndWorkout}>LAGRE ØKT</div>
//             </BottomSheetModal>
<BottomSheetModal
        title={currentProgram.programName}
        isOpen={bottomSheetOpen || forceOpen}
        onClose={handleModalClose}
        onBackdropClick={confirmCloseModal}
        breakpoints={[0.95]}
        initialBreakpointIndex={1}
      >
        <div className={templateCss.modalContent}>
          <h2 className="text-center">{currentProgram.attributes.programName}</h2>
          <div className={templateCss.timerContainer}>
            <div className={templateCss.timer}>Tid: {formatTime(elapsedTime)}</div>
          </div>

          <Slider {...sliderSettings}>
            {workoutData.map((data, pairIndex) => {
              const imageUrl =
                data?.imageUrl ||
                currentProgram.attributes.pairs.data[pairIndex]?.attributes.exercise.data.attributes.image.data[0]?.attributes?.url ||
                'default-image.jpg';

              return (
                <div key={data.pairId} 
                //className={templateCss.exerciseCard}
                //className={styles.exerciseCard}
                >
                  <div
                      //className={templateCss.cardHeaderImageContainer}
                      className={`${styles.imageContainer} mt-4`}
                      style={{ backgroundImage: `url(${imageUrl})` }}
                    />
                  <div className={styles.exerciseName}>
                    {data.exerciseName}
                  </div>
                  {/* <div className={templateCss.cardHeader}>
                    <div className={templateCss.exerciseNameDiv}>
                      <p className={templateCss.exerciseName}>{data.exerciseName}</p>
                    </div>
                    <div
                      className={`${styles.removeButton} p-2`}
                      onClick={() => removeExercise(pairIndex)}
                    >
                      X
                    </div>
                  </div> */}
                  <div className={styles.card2}>
                  <div className={`${styles.subHeader} col-12 d-flex align-items-center justify-content-between`}>
                    <div className={styles.subSet}>Sett</div>
                    <div className={styles.subReps}>Rep-mål</div>
                    <div className={styles.subReps}>Reps gjort</div>
                    <div className={styles.subReps}>Vekt</div>
                    <div className="col-1"></div>
                  </div>
                  {data.sets.map((set, setIndex) => (
                    <div key={setIndex} className={templateCss.set}>
                      {setIndex + 1}.
                      <div className={templateCss.presetReps}>{set.targetReps}</div>
                      <input
                        type="tel"
                        value={set.reps}
                        onChange={(e) =>
                          handleInputChange(pairIndex, setIndex, 'reps', e.target.value)
                        }
                        placeholder="Reps"
                        className={`${styles.input} text-center col-2 ${styles.setInput}`}
                      />
                      <input
                        type="text"
                        inputMode="decimal"
                        value={set.weight}
                        onChange={(e) =>
                          handleInputChange(pairIndex, setIndex, 'weight', e.target.value)
                        }
                        placeholder="Vekt"
                        className={`${styles.input} col-2`}
                        style={{ textAlign: 'right' }}
                      />
                      {set.reps && set.weight ? (
                        <div className={`${styles.checkSet} px-1`}>✅</div>
                      ) : (
                        <div
                          className={`${styles.removeSet} px-1`}
                          onClick={() => removeSet(pairIndex, setIndex)}
                        >
                          ❌
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                  <div
                    className={`${styles.addSet} col-11 py-1`}
                    onClick={() => addSet(pairIndex)}
                  >
                    Legg til sett
                  </div>
                  {currentProgram.attributes.pairs.data[pairIndex]?.attributes.coach_comment && (
                    <div className={styles.coachComment}>
                      <div className={styles.bold}>Coach Kommentar:</div>
                      <div>{currentProgram.attributes.pairs.data[pairIndex].attributes.coach_comment}</div>
                    </div>
                  )}
                  <div className="col-12 d-flex justify-content-center">
                    <div
                      className={`${styles.historyButton} col-11 py-1 mt-1 text-center`}
                      onClick={() => {
                        if (exerciseHistories[data.exerciseId]) {
                          setOpenHistoryExerciseId(
                            openHistoryExerciseId === data.exerciseId
                              ? null
                              : data.exerciseId
                          );
                        } else {
                          fetchExerciseHistory(data.exerciseId);
                        }
                      }}
                    >
                      Vis historikk
                    </div>
                  </div>
                  {openHistoryExerciseId === data.exerciseId && (
                    <div className={styles.historyContainer}>
                      <div className="text-center py-2">Dine siste fem sett</div>
                      <div className="d-flex align-items-center justify-content-between col-12">
                        <strong className="col-3 text-center">Når</strong>
                        <strong className="col-3 text-center">Reps</strong>
                        <strong className="col-3 text-center">Vekt</strong>
                      </div>
                      {exerciseHistories[data.exerciseId] ? (
                        exerciseHistories[data.exerciseId].length > 0 ? (
                          exerciseHistories[data.exerciseId].map((logItem) => {
                            const logAttrs = logItem.attributes;
                            const date = formatDateDdShortMonth(logAttrs?.createdAt);
                            const reps = logAttrs?.reps;
                            const vekt = logAttrs?.vekt;

                            return (
                              <div key={logItem.id} className={styles.historyItem}>
                                <div className="col-3 text-center">{date}</div>
                                <div className="col-3 text-center">{reps}</div>
                                <div className="col-3 text-center">{vekt}</div>
                              </div>
                            );
                          })
                        ) : (
                          <p className={styles.noHistory}>
                            Ingen historikk funnet
                          </p>
                        )
                      ) : (
                        <p className={styles.noHistory}>Laster historikk ...</p>
                      )}
                    </div>
                  )}
                  <div
                      className={`${styles.removeButton} text-center py-1 mt-2 col-11`}
                      onClick={() => removeExercise(pairIndex)}
                    >
                      Fjern øvelse
                    </div>
                  <div className={styles.addExerciseContainer}>
              <div
                className={`${styles.addSet} ${styles.addExercise} col-11 py-1`}
                onClick={handleAddExerciseClick}
              >
                Legg til øvelse
              </div>
            </div>

            {showExerciseSearch && (
              <div className={styles.exerciseSearchPanel}>
                <input
                  type="text"
                  placeholder="Søk etter øvelse..."
                  value={exerciseSearch}
                  onChange={(e) => setExerciseSearch(e.target.value)}
                  className={styles.searchInput}
                />
                <div className={styles.exerciseList}>
                  {exerciseBank
                    .filter((ex) =>
                      ex?.attributes?.name
                        ?.toLowerCase()
                        .includes(exerciseSearch.toLowerCase())
                    )
                    .map((ex) => (
                      <div
                        key={ex.id}
                        className={`${styles.exerciseItem}`}
                        onClick={() => addExerciseFromBank(ex)}
                      >
                        <img
                          src={
                            ex?.attributes?.image?.data?.[0]?.attributes?.url ||
                            'default-image.jpg'
                          }
                          alt={ex?.attributes?.name}
                          className={styles.exerciseImage}
                        />
                        {ex.attributes.name}
                      </div>
                    ))}
                </div>
                <button
                  onClick={() => setShowExerciseSearch(false)}
                  className={styles.closeSearchBtn}
                >
                  Lukk søk
                </button>
              </div>
            )}
                </div>
              );
            })}

            
            <div key="final-slide">
              <div className={styles.clientComment}>
                <label>Din kommentar:</label>
                <textarea
                  className={styles.clientCommentBox}
                  value={clientComment}
                  onChange={handleCommentChange}
                  rows="6"
                />
              </div>
              <div className={styles.inputRating}>
                <label>Rating:</label>
                <StarRating rating={rating} setRating={setRating} />
              </div>
              <div
                className={`${styles.submitButton} py-2 col-11`}
                onClick={confirmEndWorkout}
              >
                LAGRE ØKT
              </div>
            </div>
          </Slider>
        </div>
      </BottomSheetModal>
          )}
        </div>
  );
}
