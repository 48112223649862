import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useParams } from 'react-router';
import { GetSingleClientInfo } from '../../../hooks/newHooks/coachSide/clientInfo';
import Loader from '../../global/loader';
import SpecificClientWorkoutList from '../../coaches/clientsPageComponents/workoutsList';
// import { useIsMobile } from '../../layouts/meals/coachSide';
import { GetCheckIns, GetWorkouts, GetPrograms } from '../../../hooks/newHooks/coachSide/additionalClientHooks';
import SpecificClientCheckInList from '../../coaches/clientsPageComponents/checkInList';
//import SpecificClientMealsList from '../../coaches/clientsPageComponents/mealList';
import randomUser from '../../../images/blue_man.png';
import Image from 'react-bootstrap/Image';
import MealPlansList from '../../coaches/mealPlansList';
import SpecificClientProgramList from '../../coaches/clientsPageComponents/programsList';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import BackButton from '../../global/backButton';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export function SpecificClientPage() {

    const { clientId } = useParams();
    const [client, setClient] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [workouts, setWorkouts] = useState([]);
    const [checkIns, setCheckIns] = useState([]);
    //const [meals, setMeals] = useState([]);
    const [mealPlans, setMealPlans] = useState('');
    const [programs, setPrograms] = useState([]);
    // const [visibleWorkouts, setVisibleWorkouts] = useState(5); 
    // const [visibleCheckIns, setVisibleCheckIns] = useState(5); 
    // const [visibleMeals, setVisibleMeals] = useState(5);
    const [pagination, setPagination] = useState({
        workouts: { limit: 5, start: 0, isMoreAvailable: true },
        checkIns: { limit: 5, start: 0, isMoreAvailable: true },
        meals: { limit: 5, start: 0, isMoreAvailable: true },
        programs: { limit: 5, start: 0, isMoreAvailable: true },
    });

    const [weightDifference, setWeightDifference] = useState('');
    const [startWeight, setStartWeight] = useState('');
    const [currentWeight, setCurrentWeight] = useState('');


    // const isMobile = useIsMobile();
    

    useEffect(() => {
        setIsLoading(true);
            async function getClientInfo() {
                const result = await GetSingleClientInfo(clientId);
                console.log(result.usersPermissionsUser.data);

                //const clientData = result?.usersPermissionsUser?.data?.attributes;

            // const meals = clientData?.meals?.data?.map((meal) => ({
            //     ...meal.attributes,
            //     id: meal.id,
            //     type: 'main-meal',
            // })) || [];

            // const mealInstances = clientData?.meal_instances?.data?.map((instance) => ({
            //     ...instance.attributes,
            //     id: instance.id,
            //     type: 'meal-instance',
            //     mealName: instance.attributes?.meal?.data?.attributes?.name,
            // })) || [];

            //const combinedMealsData = [...meals, ...mealInstances];
            setClient(result?.usersPermissionsUser?.data?.attributes);
            setCheckIns(result?.usersPermissionsUser?.data?.attributes?.check_ins?.data);
            setWorkouts(result?.usersPermissionsUser?.data?.attributes?.workout_sessions?.data);
            //setMeals(combinedMealsData);
            setPrograms(result?.usersPermissionsUser?.data?.attributes?.programs?.data);
            setMealPlans(result?.usersPermissionsUser?.data?.attributes?.meal_plans?.data);
            setIsLoading(false);
        }

        getClientInfo();

        //eslint-disable-next-line
    },[clientId]);

    useEffect(() => {
        if (checkIns && checkIns.length > 0) {
          const sortedCheckIns = [...checkIns].sort(
            (a, b) => new Date(b.attributes.date) - new Date(a.attributes.date)
          );
          if (sortedCheckIns.length > 1) {
            const oldestWeight = sortedCheckIns[sortedCheckIns.length - 1].attributes.weight;
            const newestWeight = sortedCheckIns[0].attributes.weight;
            const difference = newestWeight - oldestWeight;
            setCurrentWeight(newestWeight);
            setStartWeight(oldestWeight);
            setWeightDifference(difference);
          } else if (sortedCheckIns.length === 1) {
            const onlyWeight = sortedCheckIns[0].attributes.weight;
            setCurrentWeight(onlyWeight);
            setStartWeight(onlyWeight);
            setWeightDifference(0);
          }
        }
      }, [checkIns]);
    
    const handleShowMore = async (type) => {
        const newStart = pagination[type].start + pagination[type].limit;
    
        setPagination((prev) => ({
            ...prev,
            [type]: { ...prev[type], start: newStart },
        }));
    
        if (type === 'workouts') {
            const newWorkouts = await GetWorkouts(clientId, pagination.workouts.limit, newStart);
            const fetchedWorkouts = newWorkouts.usersPermissionsUser.data.attributes.workout_sessions.data;
    
            setWorkouts((prev) => [...prev, ...fetchedWorkouts]);
    
            // Fjern knappen dersom vi mottar færre enn limit
            if (fetchedWorkouts.length < pagination.workouts.limit) {
                setPagination((prev) => ({
                    ...prev,
                    workouts: { ...prev.workouts, isMoreAvailable: false },
                }));
            }
        } else if (type === 'checkIns') {
            const newCheckIns = await GetCheckIns(clientId, pagination.checkIns.limit, newStart);
            const fetchedCheckIns = newCheckIns.usersPermissionsUser.data.attributes.check_ins.data;
    
            setCheckIns((prev) => [...prev, ...fetchedCheckIns]);
    
            if (fetchedCheckIns.length < pagination.checkIns.limit) {
                setPagination((prev) => ({
                    ...prev,
                    checkIns: { ...prev.checkIns, isMoreAvailable: false },
                }));
            }
        } else if (type === 'programs') {
            // const newMealsData = await GetMeals(clientId, pagination.meals.limit, newStart);
    
            // const newMeals = newMealsData.usersPermissionsUser.data.attributes.meals.data.map((meal) => ({
            //     ...meal.attributes,
            //     id: meal.id,
            //     type: 'main-meal',
            // }));
    
            // const newMealInstances = newMealsData.usersPermissionsUser.data.attributes.meal_instances.data.map((instance) => ({
            //     ...instance.attributes,
            //     id: instance.id,
            //     type: 'meal-instance',
            //     mealName: instance.attributes.meal?.data?.attributes.name,
            // }));
    
            // const combinedMeals = [...newMeals, ...newMealInstances];
            // setMeals((prev) => [...prev, ...combinedMeals]);
    
            // if (combinedMeals.length < pagination.meals.limit) {
            //     setPagination((prev) => ({
            //         ...prev,
            //         meals: { ...prev.meals, isMoreAvailable: false },
            //     }));
            // }

            const newProgramData = await GetPrograms(clientId, pagination.programs.limit, newStart);
            const fetchedPrograms = newProgramData.usersPermissionsUser.data.attributes.programs.data;
            setPrograms((prev) => [...prev, ...fetchedPrograms]);

            if (fetchedPrograms.length < pagination.programs.limit) {
                setPagination((prev) => ({
                    ...prev,
                    programs: { ...prev.programs, isMoreAvailable: false },
                }));
            }
        }
    };

    const imageUrl = client?.profile_picture?.data?.attributes?.url || randomUser;

    const sortedWorkouts = [...workouts].sort((a, b) => new Date(b.attributes.date_performed) - new Date(a.attributes.date_performed));

    const sortedCheckIns = [...checkIns].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));

    function formatWeight(weight, includeSign = false) {
        const roundedWeight = Math.round(weight * 10) / 10; // Runder av til én desimal
        const sign = includeSign && roundedWeight > 0 ? "+ " : ""; // Legger til "+" for positive tall hvis includeSign er true
    
        if (Number.isInteger(roundedWeight)) {
            return `${sign}${roundedWeight.toFixed(0)}`; // Viser kun heltall hvis desimalen er 0
        } else {
            return `${sign}${roundedWeight.toFixed(1)}`; // Viser én desimal hvis desimalen er større enn 0
        }
    }

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit' };
        return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
    };

    const getWeightChartData = () => {
        const sortedCheckIns = [...checkIns].sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date));
        const dates = sortedCheckIns.map(checkIn => formatDate(checkIn.attributes.date));
        const weights = sortedCheckIns.map(checkIn => checkIn.attributes.weight);

        return {
            labels: dates,
            datasets: [
                {
                    label: 'Vekt (kg)',
                    data: weights,
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    fill: true,
                    pointRadius: 0, // Remove point markers
                    borderWidth: 2 // Adjust line thickness
                }
            ]
        };
    };

    const getWeightChartOptions = () => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    display: false // Hide legend
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Dato',
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    title: {
                        display: false,
                        text: 'Vekt (kg)'
                    },
                    grid: {
                        display: false
                    }
                }
            }
        };
    };

    if(isLoading) {
        return <Loader />
    }
        return(
        <div className={styles.mainContainer}>
            <div className="col-2 py-3 ms-2">
                <BackButton />
            </div>
            <div className={styles.desktopContainer}>
            <div className={`d-flex flex-column align-items-center`}>
                <div className={`${styles.image} col-12 col-md-5 d-flex flex-column align-items-center`}>
                    <div className={`${styles.profilePictureContainer} col-8`}>
                        <Image className={`${styles.clientProfilePicture}`} fluid alt="client-image" src={imageUrl} />
                    </div>
                </div>
                <div className={`${styles.navnOverskrift} mt-3 mb-5`}>
                    {client.username}
                </div>
            </div>
            <div className={`${styles.chartContainer} col-md-5 mb-3`}>
                    <h2 className="text-center">Vektprogresjon</h2>   
                    {/* <div className={css.periodToggle}>
                        <button onClick={() => setPeriod('month')} className={period === 'month' ? css.active : ''}>Måned</button>
                        <button onClick={() => setPeriod('year')} className={period === 'year' ? css.active : ''}>År</button>
                    </div> */}
                    <Line data={getWeightChartData()} options={getWeightChartOptions()}/>
                    <div className={`${styles.weights}`}>
                    <p>Startvekt: {formatWeight(startWeight)} kg</p>
                    <p>Nåværende vekt: {formatWeight(currentWeight)} kg</p>
                    <p className={styles.total}>Klients fremgang: {formatWeight(weightDifference, true)} kg</p>
                </div>
                <div className="text-center col-10 m-auto mb-5">Vektene over er basert på de check-in's som er lastet inn - for mer data
                    last inn flere check-in's.
                </div>
            </div>
            </div>
            <div className="d-flex flex-column flex-md-row flex-wrap">
                <div className="d-flex col-md-3 flex-column align-items-center mb-5">
                    <div className={`${styles.overskrift}`}>Treningsøkter</div>
                    {sortedWorkouts.map((workout, index) => (
                        <SpecificClientWorkoutList key={index} workout={workout} />
                    ))}
                    {pagination.workouts.isMoreAvailable ? (
                        <button
                            onClick={() => handleShowMore('workouts')}
                            className={styles.showMoreButton}
                        >
                            Se flere treningsøkter
                        </button>
                    ) : (
                        <p className={styles.noMoreDataMessage}>Ingen flere treningsøkter å vise</p>
                    )}
                </div>
                <div className="d-flex col-md-3 flex-column align-items-center mb-5">
                    <div className={`${styles.overskrift}`}>Check-in's</div>
                    {sortedCheckIns.map((checkIn, index) => (
                        <SpecificClientCheckInList key={index} checkIn={checkIn} />
                    ))}
                    {pagination.checkIns.isMoreAvailable ? (
                        <button
                            onClick={() => handleShowMore('checkIns')}
                            className={styles.showMoreButton}
                        >
                            Se flere check-in's
                        </button>
                    ) : (
                        <p className={styles.noMoreDataMessage}>Ingen flere check-in's å vise</p>
                    )}
                </div>
                <div className="d-flex col-md-3 flex-column align-items-center mb-5">
                    <div className={`${styles.overskrift}`}>Matplan</div>
                    {mealPlans && mealPlans.length > 0 ? (
                        mealPlans.map((mealPlan) => (
                            <MealPlansList key={mealPlan.id} mealplan={mealPlan} />
                        ))
                    ) : (
                        <p className={styles.noDataMessage}>Ingen matplan</p>
                    )}
                </div>
                <div className="d-flex col-md-3 flex-column align-items-center mb-5">
                    <div className={`${styles.overskrift}`}>Treningsprogram</div>
                        {programs.length > 0 ? (
                            programs.map((program, index) => (
                                <SpecificClientProgramList key={index} program={program} />
                            ))
                        ) : (
                            <p className={styles.noDataMessage}>Ingen program</p>
                        )}
                        {pagination.programs.isMoreAvailable ? (
                            <button
                                onClick={() => handleShowMore('programs')}
                                className={styles.showMoreButton}
                            >
                                Se flere programmer
                            </button>
                        ) : (
                            <p className={styles.noMoreDataMessage}>Ingen flere programmer å vise</p>
                        )}
                </div>
            </div>
        </div>
    
        )
    }

// function handleOpenModal(meal) {
//     if (meal.type === 'main-meal') {
//         // Bruk query/endepunkt for hovedmåltider
//         console.log(`Hent data for hovedmåltid ID: ${meal.id}`);
//     } else if (meal.type === 'meal-instance') {
//         // Bruk query/endepunkt for måltidsinstanser
//         console.log(`Hent data for måltidsinstans ID: ${meal.id}`);
//     }
// }

// <div className="d-flex col-md-3 flex-column align-items-center mb-5">
  //                  <div className={`${styles.overskrift}`}>Matplan</div>
    //                {meals.map((meal, index) => (
      //                  <SpecificClientMealsList key={index} meal={meal} />
        //            ))}
          //          {pagination.meals.isMoreAvailable ? (
            //            <button
              //              onClick={() => handleShowMore('meals')}
                //            className={styles.showMoreButton}
                  //        Se flere måltider
                    //    </button>
//                    ) : (
 //                       <p className={styles.noMoreDataMessage}>Ingen flere måltider å vise</p>
   //                 )}
     //               {mealPlan && (
       //                 <MealPlansList mealplan={mealPlan}/>
         //           )}
           //     </div> 