import React from 'react';
import styles from './styles.module.scss';
// import { useIsMobile } from '../../../layouts/meals/coachSide';
// import BottomSheetModal from '../../../global/bottomSheetModal';
//import {ReactComponent as RightArrow} from '../../../../images/icons/right-arrow.svg';
//import { useNavigate } from 'react-router';

export default function SpecificClientProgramList({ program }) {

    //const navigate = useNavigate();

    // const redirectToProgram = () => {
    //     navigate(`/program/${program.id}`);
    // }
        return(
            <>
                <div className={`${styles.card} col-11 p-3 d-flex align-items-center justify-content-between`} 
                //</>onClick={redirectToProgram}
                >
                    <div className="col-10 d-flex justify-content-between">
                        <div>{program?.attributes?.programName}</div>
                    </div>
                    <div className="chevron">
                        
                    </div>
                </div>

                {/* <ResponsiveModal isOpen={isModalOpen} onClose={handleCloseModal}>
                    <WorkoutDetails workoutData={selectedWorkout} workoutId={workout.id}/>
                </ResponsiveModal> */}
            </>
        )
}