import React, {useState } from 'react';
import styles from './styles.module.scss';
import { useClientInformation } from '../../../hooks/clientInfo';
import Loader from '../../global/loader';
import Image from 'react-bootstrap/Image';
import defaultImage from '../../../images/blue_man.png';
//import Modal from 'react-bootstrap/Modal';
import { filterObjects } from '../../global/searchFunctionality';
// import CheckInCards from '../../global/forms/check-in/check-In-cards';
// import ClientCards from '../../global/ClientCard';
//import CoachSideCheckInCards from '../../global/forms/check-in/coachSide';
//import CoachSideWorkoutCards from '../../coaches/clientsWorkouts';
//import useGetCoachSideCheckInCards from '../../../hooks/coachSideCheckIns';
// import { axiosAuthFetch } from '../../../helpers/axios';
// import { API_GRAPHQL } from '../../../constants';
import { getLocalStorageItem } from '../../global/storage';
import { useNavigate } from 'react-router';
import SpecificClientWorkoutList from '../../coaches/clientsPageComponents/workoutsList';
import SpecificClientCheckInList from '../../coaches/clientsPageComponents/checkInList';

export default function CoachesClientPage() {
    
  //const { clients, checkIns, sessions, isLoading, isError } = useClientInformation();
  const { clients, checkIns, sessions, isLoading, isError, loadMoreSessions, loadMoreCheckIns, hasMoreCheckIns, hasMoreSessions } = useClientInformation();
  const [searchText, setSearchText] = useState('');
  //const [checkInSearchText, setCheckInSearchText] = useState('');
  const userInfo = getLocalStorageItem('limitless-user');
  //eslint-disable-next-line
  const coachID = userInfo.user.coachID;
  const user = userInfo?.user;
  //eslint-disable-next-line
//   const [checkIns, setCheckIns] = useState([]);

  const filteredClients = clients ? clients.filter(client => client.id.toString() !== user.id.toString()) : [];

//   const [checkInSearchTerm, setSearchTerm] = useState('');

//     const handleCheckInSearch = (e) => {
//         setSearchTerm(e.target.value);
//     };

  if (isLoading || !clients) {
    return <Loader />;
  }

  if (isError) {
    return <div>Oida, her gikk noe galt! Vennligst prøv igjen!</div>;
  }

  if (clients && clients.length === 0) {
    return <div>Du har ingen klienter for øyeblikket.</div>;
  }
  const searchedClients = filterObjects(searchText, filteredClients);
  

//   const filteredCheckIns = checkIns.filter((checkIn) => {
//     const checkInString = JSON.stringify(checkIn).toLowerCase();
//     return checkInString.includes(checkInSearchTerm.toLowerCase());
// });

  return (
    <div className={`${styles.container} pt-2 pb-5 appearing-container`}>
        <div className={styles.sub1}>
            <h1 className="text-center">Dine klienter</h1>
            <input
            className={styles.search}
            type="text"
            placeholder="Søk etter klient"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            />
            <CoachSideClientCards clients={searchedClients} />
        </div>
        <div className={styles.sub2}>
            <div className={styles.checkInHeader}>
                <div className={styles.checkInOverskrift}>Klienters check-ins</div>
            </div>
            <div className={styles.checkInCardsContainer}>
                {checkIns.map((checkIn, index) => (
                    <SpecificClientCheckInList key={index} checkIn={checkIn}/>
                ))}
            </div>
            {hasMoreCheckIns ? (
                    <button onClick={loadMoreCheckIns} className={styles.loadMoreButton}>
                        Hent flere check-ins
                    </button>
                ) : (
                    <button disabled className={styles.loadMoreButton}>
                        Ingen flere check-ins tilgjengelig
                    </button>
                )}
        </div>
        <div className={styles.sub2}>
            <div className={styles.checkInHeader}>
                <div className={styles.checkInOverskrift}>Klienters økter</div>
            </div>
            <div className={`${styles.checkInCardsContainer}`}>
                {sessions.map((workout,index) => (
                <SpecificClientWorkoutList key={index} workout={workout}/>
                )
                )}
            </div>
            {hasMoreSessions ? (
                    <button onClick={loadMoreSessions} className={styles.loadMoreButton}>
                        Hent flere økter
                    </button>
                ) : (
                    <button disabled className={styles.loadMoreButton}>
                        Ingen flere økter tilgjengelig
                    </button>
                )}
        </div>
    </div>
  );
}

const CoachSideClientCards = ({ clients }) => {
    // const [selectedClient, setSelectedClient] = useState(null);
    // const [showModal, setShowModal] = useState(false);

    // const handleClientClick = (client) => {
    //     setSelectedClient(client);
    //     setShowModal(true);
    // };

    // const handleCloseModal = () => {
    //     setShowModal(false);
    //     setSelectedClient(null);
    // };

    // console.log('clients', clients);

    // const getFormattedDate = (client) => {
    //     let formattedDate = "";
    //     const workout_sessions = client?.attributes?.workout_sessions?.data || [];
    //     if (workout_sessions.length > 0) {
    //         const datePerformed = new Date(workout_sessions[workout_sessions.length - 1].attributes.date_performed);
    //         const months = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
    //         const day = datePerformed.getDate().toString().padStart(2, '0');
    //         const month = months[datePerformed.getMonth()];
    //         formattedDate = `${day}. ${month}`;
    //     }
    //     return formattedDate;
    // };

    const navigate = useNavigate();

    const redirectToSpecificUser = (id) => {
        navigate(`/clients/${id}`);
      };

    return(
            <div className={styles.cardContainer}>
                {clients.length > 0 ? (
                    clients.map((client) => {
                        const { username } = client.attributes;
                        const imageUrl = client.attributes.profile_picture?.data?.attributes?.url || defaultImage;
                        return (
                            <div key={client.id} className={styles.card} onClick={() => redirectToSpecificUser(client.id)}>
                                <div className={styles.image}>
                                    <div className={styles.profilePictureContainer}>
                                        <Image className={styles.clientProfilePicture} fluid alt="client-image" src={imageUrl} />
                                    </div>
                                </div>
                                <div className={styles.infoDiv}>
                                    <div className={styles.name}>{username}</div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className={styles.noClients}>
                        Du har ingen klienter.
                    </div>
                )}
            </div>
    )
};