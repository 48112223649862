import { axiosAuthFetch } from "../../../helpers/axios";
import { API_GRAPHQL } from "../../../constants";


export async function GetMealPlanList(id) {
    const query = `
      query {
        mealPlans(pagination: {limit: 50} ,filters: { coach: { id: { eq: "${id}" } } }) {
          data {
            id
            attributes {
              name
              total_kcals
              protein
              fat
              carbs
            }
          }
        }
      }
    `;
  
    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
    return response.data.mealPlans.data;
  }

export async function GetSpecificMealPlan(id) {
    const query = `
        query {
            mealPlan(id:"${id}") {
                data{
                    id
                    attributes{
                        name
                        description
                        total_kcals
                        fat
                        carbs
                        protein
                        goal
                        duration
                        notes
                        meal_slot{
                            meal_name
                            meal_instances{
                                data{
                                    id
                                    attributes{
                                        variant_name
                                        proteins
                                        carbs
                                        fats
                                        kcals
                                        meal{
                                            data{
                                                id
                                                attributes{
                                                    name
                                                    meal_images{
                                                        data{
                                                            id
                                                            attributes{
                                                                image{
                                                                    data{
                                                                        id
                                                                        attributes{
                                                                            url
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            meals{
                                data{
                                    id
                                    attributes{
                                        name
                                        proteins
                                        fats
                                        carbs
                                        kcals
                                        meal_images{
                                                        data{
                                                            id
                                                            attributes{
                                                                image{
                                                                    data{
                                                                        id
                                                                        attributes{
                                                                            url
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                    }
                                }
                            }
                        }
                        clients{
                            data{
                                id
                                attributes{
                                    username
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
    console.log(response);
    return response;
}
  