import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { GetSpecificMealPlan } from '../../../hooks/newHooks/coachSide/mealPlans';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_BASE_URL } from '../../../constants';
//import { getLocalStorageItem } from '../../global/storage';
//import ResponsiveModal from '../../global/responsiveModal';
//import AddMealModal from '../../global/forms/addMealModal';
import styles from './styles.module.scss';
//import { getClients } from '../../../hooks/newHooks/coachSide/clientInfo';
import MacroDistributionChart from '../../makroChart';
import BackButton from '../../global/backButton';
import { getClients } from '../../../hooks/newHooks/coachSide/clientInfo';
import { getLocalStorageItem } from '../../global/storage';
import fallBackImage from '../../../images/mat.png';
import EditMealPlanForm from '../../global/forms/editMealPlanForm';
import ResponsiveModal from '../../global/responsiveModal';

export function ClientCard({ client, onClick }) {

    const username = client.attributes.username || '';
    const initials = username.substring(0, 2).toUpperCase();
  
    return (
      <div key={client.id} className={`${styles.clientCard} shadow rounded-3 p-3`}>
        <div className={styles.avatar}>
            {initials}
        </div>
        <div className={styles.clientName}>
            {username}
        </div>
        <div onClick={() => onClick(client.id)} className={`${styles.x} px-4`}>
            Fjern
        </div>
      </div>
    );
  }

  function transformMealPlan(plan) {
    return {
      ...plan,
      meal_slot: (plan.meal_slot || []).map(slot => ({
        meal_name: slot.meal_name,
        mealSearchTerm: "",
        selectedMealsAndInstances: [
          ...(slot.meals && slot.meals.data
            ? slot.meals.data.map(meal => ({ id: meal.id, type: 'meal' }))
            : []),
          ...(slot.meal_instances && slot.meal_instances.data
            ? slot.meal_instances.data.map(instance => ({
                id: instance.id,
                type: 'instance',
                mealId: instance.attributes.meal?.data?.id || ''
              }))
            : [])
        ]
      }))
    };
  }

export default function CoachSpecificMealPlanPage() {
  const { mealplanId } = useParams();
  //const userInfo = getLocalStorageItem('limitless-user');
  //const coachId = userInfo.user.coachID;
  const [isLoading, setIsLoading] = useState(false);
  const [mealPlan, setMealPlan] = useState(null);
  //const [editedPlan, setEditedPlan] = useState({});
  const [mealSlots, setMealSlots] = useState([]);
  const [clientsWithPlan, setClientsWithPlan] = useState([]);
  const [editing, setEditing] = useState(false);
  //const [modalOpen, setModalOpen] = useState(false);
  //const [allMeals, setAllMeals] = useState([]);
  //const [clients, setClients] = useState([]);
  //const [coachClients, setCoachClients] = useState([]);
  //const [selectedMealData, setSelectedMealData] = useState(null);
  //const [mealLoading, setMealLoading] = useState(false);
  const coachInfo = getLocalStorageItem('limitless-user');
  const coachId = coachInfo.user.coachID;
  const [allClients, setAllClients] = useState([]);
  const [unassignedClients, setUnassignedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function getMealPlanData() {
      setIsLoading(true);
      try {
        const response = await GetSpecificMealPlan(mealplanId);
        console.log('MEALPLAN 2', response);
        const planData = response.data.mealPlan.data;
        const attributes = planData.attributes;
        setMealPlan(attributes);
        setMealSlots(attributes.meal_slot || []);
        setClientsWithPlan(attributes.clients?.data || []);
        const allClientsResponse = await getClients(coachId);
        setAllClients(allClientsResponse.coach.data.attributes.clients.data);
      } catch (error) {
        console.error('Error fetching meal plan:', error);
      } finally {
        setIsLoading(false);
      }
    }
    getMealPlanData();
  }, [mealplanId, coachId]);

  useEffect(() => {
    if (allClients.length > 0 && clientsWithPlan.length > 0) {
      const filtered = allClients.filter(client => 
        !clientsWithPlan.some(assigned => assigned.id === client.id)
      );
      setUnassignedClients(filtered);
    } else {
      // Dersom clientsWithPlan er tom, kan du f.eks. sette alle klientene som filtrert resultat.
      setUnassignedClients(allClients);
    }
  }, [allClients, clientsWithPlan]);

  const handleMealClick = async (mealId) => {
    navigate(`/meal/${mealId}`);
  };

  const addClientToMealPlan = async (clientId) => {
    try {
      await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${mealplanId}`,
        {
          data: {
            clients: {
              connect: [clientId],
            },
          },
        },
        'PUT'
      );
      alert('Klient lagt til i matplanen!');
      const newClient = unassignedClients.find(client => client.id === clientId);
        if (newClient) {
        setClientsWithPlan(prev => [...prev, newClient]);
        setUnassignedClients(prev => prev.filter(client => client.id !== clientId));
        setSelectedClient(null);
        }
      // Her kan du eventuelt oppdatere state eller gi feedback til brukeren.
    } catch (error) {
      console.error('Feil ved å legge til klient i matplanen:', error);
      alert('Det oppstod en feil ved å legge til klient.');
    }
  };

  const removeClientFromMealPlan = async (clientId, mealPlanId) => {
    try {
      // Sender en PUT-request med "disconnect" for å fjerne klienten
      await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${mealPlanId}`,
        {
          data: {
            clients: {
              disconnect: [clientId]
            }
          }
        },
        'PUT'
      );
      alert('Klient fjernet fra matplanen!');
      const removedClient = clientsWithPlan.find(client => client.id === clientId);
        if (removedClient) {
        setClientsWithPlan(prev => prev.filter(client => client.id !== clientId));
        setUnassignedClients(prev => [...prev, removedClient]);
        }
    } catch (error) {
      console.error('Error disconnecting client:', error);
      alert('Det oppstod en feil ved fjerning av klient.');
    }
  };

  const deleteMealPlan = async (mealplanId) => {
    const confirmed = window.confirm('Er du sikker på at du vil slette matplanen?');
    if (!confirmed) {
      return; // Avbryt slettingen dersom brukeren trykker "Cancel"
    }
  
    try {
      // Utfør slettingen via API-kall (her brukes en DELETE-request)
      await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans/${mealplanId}`,
        {},
        'DELETE'
      );
      alert('Matplanen er slettet!');
      navigate(-1);
    } catch (error) {
      console.error('Feil ved sletting av matplan:', error);
      alert('Det oppstod en feil under slettingen.');
    }
  };
  
  const reFetchMealPlanData = async () => {
    setIsLoading(true);
    try {
      const response = await GetSpecificMealPlan(mealplanId);
      console.log('MEALPLAN', response);
      const planData = response.data.mealPlan.data;
      const attributes = planData.attributes;
      setMealPlan(attributes);
      setMealSlots(attributes.meal_slot || []);
      // eventuelt andre state-oppdateringer...
    } catch (error) {
      console.error('Error fetching meal plan:', error);
    } finally {
      setIsLoading(false);
    }
  }

  // const handleCopyPlan = async () => {
  //   if (!mealPlan) return;
  //   const newPlan = { ...mealPlan, name: `${mealPlan.name} KOPI`, coach: coachId  };
  //   if (newPlan.clients) {
  //     delete newPlan.clients;
  //   }
  //   try {
  //     setIsLoading(true);
  //     const response = await axiosAuthFetch(
  //       `${API_BASE_URL}/meal-plans`,
  //       { data: newPlan },
  //       'POST'
  //     );
  //     console.log(response);
  //     setIsLoading(false);
  //     alert('Matplanen er kopiert!');
  //     (navigate('/mealplan'));
  //     // Eventuelt kan du navigere til den nye matplanens side:
  //     // navigate(`/coach/meal-plan/${response.data.mealPlan.id}`);
  //   } catch (error) {
  //     console.error('Feil ved kopiering av matplan:', error);
  //     alert('Det oppstod en feil ved kopiering av matplan.');
  //   }
  // };

  const handleCopyPlan = async () => {
    if (!mealPlan) return;
  
    const transformedMealSlots = (mealPlan.meal_slot || []).map(slot => {
      const meals = slot.meals?.data?.map(meal => meal.id) || [];
      const mealInstances = slot.meal_instances?.data?.map(instance => instance.id) || [];
  
      return {
        meal_name: slot.meal_name,
        meals,
        meal_instances: mealInstances
      };
    });
  
    const { 
      id, 
      createdAt, 
      updatedAt, 
      clients,
      ...rest 
    } = mealPlan;
  
    const newPlan = {
      ...rest,
      name: `${mealPlan.name} KOPI`,
      coach: coachId,                     
      meal_slot: transformedMealSlots,  
    };

    console.log('newPLAN', newPlan);
  
    // Fjern clients hvis den finnes (vi vil ikke kopiere med relasjonene)
    if (newPlan.clients) {
      delete newPlan.clients;
    }
  
    try {
      setIsLoading(true);
      const response = await axiosAuthFetch(
        `${API_BASE_URL}/meal-plans`,
        { data: newPlan },
        'POST'
      );
      console.log(response);
      setIsLoading(false);
      alert('Matplanen er kopiert!');
      navigate('/mealplan');
      // Alternativt: navigate(`/coach/meal-plan/${response.data.mealPlan.id}`);
    } catch (error) {
      console.error('Feil ved kopiering av matplan:', error);
      alert('Det oppstod en feil ved kopiering av matplan.');
    }
  };
  
  

if(isLoading) {
    return(
        <>Laster inn matplan...</>
    )
}

if(mealPlan) {
    return(
        <div className={`col-12 col-md-5 m-auto ${styles.mainCont}`}>
            <div className="col-12">
                <div className="col-2 ms-2 d-md-none">
                    <BackButton/>
                </div>
            </div>
            <div className={`${styles.overskrift} text-center`}>{mealPlan.name}</div>
            <div className={`${styles.beskrivelse} col-12 p-2 text-center`}>{mealPlan.description}</div>
            <div className={`${styles.duration} col-12 p-2 text-center`}>Varighet: {mealPlan.duration}</div>
            <div className={`${styles.duration} col-12 p-2 text-center`}>Mål: {mealPlan.goal}</div>
            <div className={`${styles.duration} col-12 p-2 text-center`}>Notater (internt): {mealPlan.notes}</div>
            <div>
                <MacroDistributionChart 
                    protein={mealPlan.protein}
                    carbs={mealPlan.carbs}
                    fat={mealPlan.fat}
                    kcals={mealPlan.total_kcals}
                />
            </div>
            <div className={`text-center ${styles.kalorier} mt-3`}>Kalorier: {mealPlan.total_kcals} kcal</div>
            <div className={`d-flex align-items-center justify-content-between col-12 px-5 mb-3`}>
                <div className={`${styles.beskrivelse}`}>Protein: {mealPlan.protein} g</div>
                <div className={`${styles.beskrivelse}`}>Carbs: {mealPlan.carbs} g</div>
                <div className={`${styles.beskrivelse}`}>Fett: {mealPlan.fat} g</div>
            </div>
            <div className={styles.mealSlotsDisplay}>
                {mealSlots.map((slot, index) => (
                    <div key={index} className={styles.categorySection}>
                        <h3>{slot.meal_name}</h3>
                        <div className={styles.horizontalScrollContainer}>
                        {/* Kort for meals */}
                        {slot.meals && slot.meals.data && slot.meals.data.length > 0 &&
                            slot.meals.data.map((meal, i) => {
                            const imageUrl =
                                meal.attributes.meal_images &&
                                meal.attributes.meal_images.data &&
                                meal.attributes.meal_images.data.length > 0
                                ? meal.attributes.meal_images.data[0].attributes.image.data.attributes.url
                                : fallBackImage;
                            return (
                                <div key={`meal-${i}`} className={styles.container99}>
                                    <div
                        
                                    className={styles.mealCard}
                                    style={{ backgroundImage: `url(${imageUrl})` }}
                                    onClick={() => handleMealClick(meal.id, "meal")}
                                    >
                                    {/* <div className={styles.cardOverlay}>
                                        {meal.attributes.name}
                                    </div> */}
                                    </div>
                                    {meal.attributes.name}
                                </div>
                                
                            );
                            })
                        }
                        {/* {slot.meal_instances && slot.meal_instances.data && slot.meal_instances.data.length > 0 &&
                            slot.meal_instances.data.map((inst, i) => {
                            const linkedMeal = inst.attributes.meal.data;
                            const imageUrl =
                                linkedMeal &&
                                linkedMeal.attributes.meal_images &&
                                linkedMeal.attributes.meal_images.data &&
                                linkedMeal.attributes.meal_images.data.length > 0
                                ? linkedMeal.attributes.meal_images.data[0].attributes.image.data.attributes.url
                                : fallBackImage;
                            return (
                                <div className={styles.container99}>
                                    <div
                                        key={`inst-${i}`}
                                        className={styles.mealCard}
                                        style={{ backgroundImage: `url(${imageUrl})` }}
                                        onClick={() => handleMealClick(linkedMeal.id, "meal")}
                                        >
                                        </div>
                                        <div className="">
                                            {inst.attributes.variant_name}
                                        </div>
                                        <div className="">
                                            fra
                                        </div>
                                        <div className="">
                                            {linkedMeal.attributes.name}
                                        </div>
                                </div>
                                
                            );
                            })
                        } */}
                        {slot.meal_instances?.data?.length > 0 &&
  slot.meal_instances.data.map((inst, i) => {
    const linkedMeal = inst.attributes.meal.data;
    
    const imageUrl =
      linkedMeal?.attributes?.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url ??
      fallBackImage;
      
    return (
      <div className={styles.container99} key={`inst-${i}`}>
        <div
          className={styles.mealCard}
          style={{ backgroundImage: `url(${imageUrl})` }}
          onClick={() => handleMealClick(linkedMeal.id, "meal")}
        >
        </div>
        <div>{inst.attributes.variant_name}</div>
        <div>fra</div>
        <div>{linkedMeal?.attributes?.name}</div>
      </div>
    );
  })
}

                        </div>
                    </div>
                ))}
            </div>
            <div className="col-12 d-flex flex-column align-items-center">
              <div 
              className={styles.editButton}
              onClick={() => setEditing(true)}
              >
                Rediger matplan
              </div>
            </div>
            <div className="col-12 d-flex flex-column align-items-center">
              <div 
              className={styles.editButton}
              onClick={handleCopyPlan}
              >
                Kopier matplan
              </div>
            </div>
            <div className={``}>
                <div className="col-12 px-3 d-flex align-items-center justify-content-between">
                    <div>
                        Klienter med denne matplanen
                    </div>
                </div>
                <div className={`col-12 px-3 d-flex align-items-center justify-content-between`}>
                    <div>
                        <select value={selectedClient ? selectedClient.id : ''}
                            onChange={(e) => {
                                const client = unassignedClients.find(client => client.id === e.target.value);
                                setSelectedClient(client);
                            }}
                            className={`${styles.select} py-1 px-2`}
                        >
                            <option value="">Velg en klient</option>
                            {unassignedClients.map(client => (
                                <option key={client.id} value={client.id}>
                                    {client.attributes.username}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={`${styles.submitButton} col-6 py-1 text-center px-3`} onClick={(e) => addClientToMealPlan(selectedClient.id)}>
                        Legg til ny klient
                    </div>
                </div>
                <div className={`d-flex flex-wrap ${styles.cardContainer}`}>
                    {clientsWithPlan.map((client) => (
                        <div className={`col-5`} key={client.id}>
                            <ClientCard client={client}
                                onClick={(clientId) => removeClientFromMealPlan(clientId, mealplanId)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-12 d-flex justify-content-center mt-4">
                <div className={`${styles.x} px-4 py-1 mb-5`} onClick={(e) => deleteMealPlan(mealplanId)}>Slett matplan</div>
            </div>
            <ResponsiveModal 
        isOpen={editing} 
        onClose={() => setEditing(false)}
      >
        {/* Vi sender med initialData og en onSubmitSuccess callback for å oppdatere UI */}
        <EditMealPlanForm 
          initialData={transformMealPlan(mealPlan)}
          planId={mealplanId}
          coachId={coachId}
          onSubmitSuccess={() => {
            reFetchMealPlanData();
            setEditing(false);
          }}
        />
      </ResponsiveModal>
        </div>
    )
}
}