import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import NavBar from '../global/nav';
import layoutCss from './layout.module.scss';

export default function Layout() {
    return(
        <div className={StyleSheet.main}>
            <NavBar />
            <div className={layoutCss.container}>
                <Header />
                <div className={layoutCss.outletDiv}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}