import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { GetNotifications } from '../../../hooks/notifications';
import { getLocalStorageItem } from '../storage';
import ResponsiveModal from '../responsiveModal';
import { GetSingleCheckInInfo } from '../../../hooks/newHooks/coachSide/fetchClientCheckIn';
import { GetSingleSessionInfo } from '../../../hooks/newHooks/coachSide/fetchClientSession';
import CheckInDetails from '../../coaches/clientsPageComponents/checkInDetails';
import WorkoutDetails from '../../coaches/clientsPageComponents/workoutDetails';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_BASE_URL } from '../../../constants';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export default function Notifications() {

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;
    const [notifications, setNotifications] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalData, setModalData] = useState('');
    const [itemId, setItemId] = useState(null);

    useEffect(() => {

        async function getNotifications() {
            const response = await GetNotifications(user.id);
            console.log('notif', response.notifications.data);

            setNotifications(response.notifications.data);
        }
        getNotifications()

        //eslint-disable-next-line
    },[]);

    const handleNotificationClick = async (notification) => {
        const { attributes } = notification;
        // Merk at attributes kan ha:
        // check_in?.data?.id
        // workout_session?.data?.id
        const checkInData = attributes.check_in?.data;
        const workoutData = attributes.workout_session?.data;
    
        let fetchedData = null;
        let chosenType = null;
        let itemId = null;
    
        if (checkInData && checkInData.id) {
          // Notifikasjon peker til en Check-in
          chosenType = 'checkin';
          fetchedData = await GetSingleCheckInInfo(checkInData.id);
          itemId = checkInData.id;
        } else if (workoutData && workoutData.id) {
          // Notifikasjon peker til en Workout-session
          chosenType = 'workout';
          fetchedData = await GetSingleSessionInfo(workoutData.id);
          itemId = workoutData.id;
        }
    
        setModalData(fetchedData);
        setModalType(chosenType);
        setItemId(itemId);
        setModalOpen(true);
      };

      const handleMarkAsRead = async (notification) => {
        try {
          const notifId = notification.id;
          // Oppdaterer isRead = true i Strapi
          await axiosAuthFetch(`${API_BASE_URL}/notifications/${notifId}`, {
            data: {
              isRead: true,
            },
          }, 'PUT');
    
          // Fjern notifikasjonen fra state-liste (så den forsvinner fra "uleste"-lista)
          setNotifications((prev) => prev.filter((n) => n.id !== notifId));
    
        } catch (error) {
          console.error('Feil ved å merke notifikasjon som lest:', error);
          alert('Noe gikk galt. Prøv igjen.');
        }
      };
    

      return (
        <div className={styles.notificationsWrapper}>
        <h2>Uleste notifikasjoner</h2>
        {notifications.length === 0 ? (
          <p>Ingen uleste notifikasjoner</p>
        ) : (
          <TransitionGroup className={styles.notificationList}>
            {notifications.map((notification) => {
              const { id, attributes } = notification;
              const { message, sender, receiver } = attributes || {};
    
              return (
                <CSSTransition key={id} timeout={300} classNames="fade">
                  <div className={styles.notificationItem}>
                    <p className={styles.message}>{message}</p>
                    {sender?.data && (
                      <p>Sender: {sender.data.attributes?.username || sender.data.id}</p>
                    )}
                    {receiver?.data && (
                      <p>Mottaker: {receiver.data.attributes?.username || receiver.data.id}</p>
                    )}
                    <div className={styles.buttonRow}>
                      <button
                        className={`${styles.viewButton} col-5 `}
                        onClick={() => handleNotificationClick(notification)}
                      >
                        Se mer  🔍
                      </button>
                      <button
                        className={`${styles.markReadButton} col-5`}
                        onClick={() => handleMarkAsRead(notification)}
                      >
                        Fjern  🗑️
                      </button>
                    </div>
                  </div>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        )}
    
          <ResponsiveModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
            {modalType === 'checkin' && modalData && (
              <CheckInDetails selectedCheckIn={modalData} checkInId={itemId} />
            )}
            {modalType === 'workout' && modalData && (
              <WorkoutDetails workoutData={modalData} workoutId={itemId} />
            )}
          </ResponsiveModal>
        </div>
      );
    }