import { API_GRAPHQL } from "../../../constants";
import { axiosAuthFetch } from "../../../helpers/axios";

export default async function GetClientMealPlans(id){

    const query = `
        query{
            usersPermissionsUser(id:${id}){
                data{
                    attributes{
                        meal_plans{
                            data{
                                id
                                attributes{
                                    name
                                    total_kcals
                                    protein
                                    fat
                                    carbs
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'post');
    return response.data.usersPermissionsUser.data.attributes.meal_plans;
}