import React from 'react';
import tCss from './training.module.scss';
import { Link } from 'react-router-dom';
import bgImage1 from '../../../images/db.jpg';
import bgImage2 from '../../../images/db_rack_2.jpg'
import bgImage3 from '../../../images/trening.jpeg';
import ClientTemplatePage from './client';
import { getLocalStorageItem } from '../../global/storage';

export default function TrainingPage() {

    const userInfo = getLocalStorageItem('limitless-user');
  const user = userInfo?.user;

    if(user.isCoach === true) {
        return (
            <div className={`${tCss.container1} appearing-container`}>
                <h2 className={tCss.h2}>DINE PROGRAM</h2>
                <div className={tCss.container2}>
                    <Link to="/training/create" className={tCss.cardContainer}>
                        <div className={tCss.imageContainer} style={{backgroundImage: `url(${bgImage1})`}}></div>
                        <h3>Lag nytt program</h3>
                        <p className={tCss.text}>Lag et nytt program fra bunnen og velg mellom å tildele det til en
                            klient eller lagre som en mal for senere bruk.
                        </p>
                    </Link>
                    <Link to="/training/template" className={`${tCss.cardContainer} ${tCss.cardContainer2}`}>
                        <div className={tCss.imageContainer} style={{backgroundImage: `url(${bgImage2})`}}></div>
                        <h3>Se dine program</h3>
                        <p className={tCss.text}>
                            Se, rediger og tildelt klienter dine lagrede program.
                        </p>
                    </Link>
                    <Link to="/training/exercises" className={`${tCss.cardContainer} ${tCss.cardContainer2}`}>
                        <div className={tCss.imageContainer} style={{backgroundImage: `url(${bgImage3})`}}></div>
                        <h3>Øvelsesbank</h3>
                        <p className={tCss.text}>
                            Se, rediger og legg til øvelser.
                        </p>
                    </Link>
                </div>
            </div>
        )
    }

    else {

        return(
            <div className={tCss.container1}>
                <ClientTemplatePage />
            </div>
        )
    }
    
}