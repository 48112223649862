// import React, {useState} from 'react';
// import css from './c-iCards.module.css';
// import Modal from 'react-bootstrap/Modal';
// import Image from 'react-bootstrap/Image';

// const renderStars = (rating) => {
//     const stars = [];
//     for (let i = 1; i <= 5; i++) {
//         stars.push(
//             <span key={i} className={i <= rating ? css.starFilled : css.starEmpty}>
//                 &#9733;
//             </span>
//         );
//     }
//     return stars;
// };

// const CheckInCards = ({ checkIns }) => {
//     const [showModal, setShowModal] = useState(false);
//     const [currentImage, setCurrentImage] = useState('');
//     console.log(checkIns);

//     const handleImageClick = (url) => {
//         setCurrentImage(url);
//         setShowModal(true);
//     };

//     const formatDate = (dateString) => {
//         const options = { day: '2-digit', month: 'long' };
//         return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
//     };

//     const handleClose = () => setShowModal(false);

//     const sortedCheckIns = [...checkIns].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));

//     // Get the newest and second newest check-in
//     // const newestCheckIn = sortedCheckIns[0].attributes;
//     // const secondNewestCheckIn = sortedCheckIns[1].attributes;

//     // Calculate weight difference
//     // let weightDifference = null;
//     // if (sortedCheckIns.length > 1) {
//     //     console.log(sortedCheckIns);
//     //     const newestCheckIn = sortedCheckIns[0].attributes;
//     //     const secondNewestCheckIn = sortedCheckIns[1].attributes;
//     //     weightDifference = newestCheckIn.weight - secondNewestCheckIn.weight;
//     // }

//     // function formatWeight(weight) {
//     //     const roundedWeight = Math.round(weight * 10) / 10; // Runder av til én desimal
//     //     if (Number.isInteger(roundedWeight)) {
//     //         return roundedWeight.toFixed(0); // Viser kun heltall hvis desimalen er 0
//     //     } else {
//     //         return roundedWeight.toFixed(1); // Viser én desimal hvis desimalen er større enn 0
//     //     }
//     // }

//     return (
//         <div className={css.container}>
//             {sortedCheckIns.map((checkIn, index) => {
//                 const { coach_comment, comment, date, weight, progress_pic, rating } = checkIn.attributes;

//                 return (
//                     <div className={css.card} key={checkIn.id}>
//                         <div className={css.checkInDate}>{formatDate(date)}</div>
//                         <div className={css.subChild1}><p className={`${css.bold} my-2`}>Vekt: {weight} kg</p></div>
//                         <div className={css.content}>
//                         {/* {index === 0 && weightDifference !== null && (
//                                 <p className={css.bold}>Utvikling fra forrige check-in: {formatWeight(weightDifference)} kg</p>
//                             )} */}
                            
//                             {/* <div className={css.coach}>
//                                 <div className={`${css.bold} ${css.bold2}`}>Din kommentar:</div>
//                                 {comment}
//                             </div>
//                             <div className={css.coach}>
//                                 <div className={`${css.bold} ${css.bold2}`}>Coach:</div>
//                             {coach_comment && <p>{coach_comment}</p>}
//                             </div> */}
//                             <div className={css.rating}>
//                                 {renderStars(rating)}
//                             </div>
//                             {/* {progress_pic?.data && progress_pic.data.length > 0 && (
//                                 <div className={css.images}>
//                                     {progress_pic.data.map((pic, index) => (
//                                         <img key={index} src={pic.attributes.url} alt={`Progress ${index}`} className={css.image} onClick={() => handleImageClick(pic.attributes.url)}/>
//                                     ))}
//                                 </div>
//                             )} */}
//                         </div>
//                     </div>
//                 );
//             })}
//             <Modal className={css.modal} show={showModal} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Check-In</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className={css.modalChild1}>
//                         {coach_comment}
//                         {comment}
//                     </div>
//                     <div className={css.modalImageParent}>
//                         <Image src={currentImage} alt="Large Progress" fluid className={css.largeImage} />
//                     </div>
//                 </Modal.Body>
//             </Modal>
//         </div>
//     );
// }

// export default CheckInCards;

import React, { useState } from 'react';
import css from './c-iCards.module.scss';
//import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { axiosAuthFetch } from '../../../../../helpers/axios';
import { API_BASE_URL } from '../../../../../constants';
import BottomSheetModal from '../../../bottomSheetModal';

const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <span key={i} className={i <= rating ? css.starFilled : css.starEmpty}>
                &#9733;
            </span>
        );
    }
    return stars;
};

const CheckInCards = ({ checkIns, setCheckIns }) => {
    //const [showModal, setShowModal] = useState(false);
    const [currentCheckIn, setCurrentCheckIn] = useState(null); // Store all attributes in this state
    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

    const openModal = () => setBottomSheetOpen(true);
    //const closeModal = () => setBottomSheetOpen(false);

    const closeModal = () => {
        setBottomSheetOpen(false);
        setCurrentCheckIn(null); // Rydd opp currentCheckIn
        // Forsikre deg om at overflow blir tilbakestilt
    };

    const handleCardClick = (checkIn) => {
        setCurrentCheckIn(checkIn); // Store the full check-in attributes
        //setShowModal(true);
        openModal();
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short' };
        return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
    };

    const handleDelete = async () => {
        if (!currentCheckIn) return;
    
        // Vis bekreftelsesdialog
        const isConfirmed = window.confirm("Er du sikker på at du vil slette denne check-in?");
        if (!isConfirmed) return; // Avbryt hvis brukeren ikke bekrefter
    
        try {
            // Sende DELETE-forespørsel til API-et
            await axiosAuthFetch(`${API_BASE_URL}/check-ins/${currentCheckIn.id}`, {}, 'DELETE');
    
            // Oppdaterer checkIns listen for å fjerne den slettede check-in
            // setCheckIns((prevCheckIns) => prevCheckIns.filter(checkIn => checkIn.id !== currentCheckIn.id));
            alert('Check-in slettet!')
    
            // Lukk modalen
            window.location.reload();
            //setShowModal(false);
            closeModal();
        } catch (error) {
            console.error("Error deleting check-in:", error);
            alert("Kunne ikke slette check-in. Prøv igjen senere.");
        }
    };
    
    // const handleClose = () => setShowModal(false);

    const sortedCheckIns = [...checkIns].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));

    
    const calculateWeightDifference = (currentIndex) => {
        if (currentIndex === sortedCheckIns.length - 1) return null; // Ingen forrige check-in
        const currentWeight = sortedCheckIns[currentIndex].attributes.weight;
        const previousWeight = sortedCheckIns[currentIndex + 1].attributes.weight;
        const difference = currentWeight - previousWeight;
        return difference.toFixed(1); // Returner differansen med én desimal
    };

    return (
        <div className={css.container}>
            {sortedCheckIns.map((checkIn, index) => {
                //eslint-disable-next-line
                const { coach_comment, comment, date, weight, progress_pic, rating } = checkIn.attributes;
                const weightDifference = calculateWeightDifference(index);

                return (
                    <div
                        className={css.card}
                        key={checkIn.id}
                        onClick={() => handleCardClick(checkIn)}
                    >
                        <div className={css.checkInDate}>{formatDate(date)}</div>
                        <div className={css.subChild1}>
                            <p className={`${css.bold} my-2`}>Vekt: {weight} kg</p>
                        </div>
                        {weightDifference !== null && (
                            <div
                                className={`${css.weightDifference} col-3 text-center ${
                                    weightDifference > 0 ? css.weightUp : css.weightDown
                                }`}
                            >
                                {weightDifference > 0 ? `+${weightDifference} kg` : `${weightDifference} kg`}
                            </div>
                        )}
                        <div className="chevron col-2 me-2" />
                    </div>
                );
            })}
            {currentCheckIn && (
                <BottomSheetModal isOpen={bottomSheetOpen} onClose={closeModal} breakpoints={[0.9]} initialBreakpointIndex={0}>
                    {console.log('Rendering modal with currentCheckIn:', currentCheckIn)}
                    <div className={css.modalContent}>
                        <strong>Check-In {formatDate(currentCheckIn.attributes.date)}</strong>
                        <div className={css.modalChild1}>
                            <p><strong>Dato:</strong> {formatDate(currentCheckIn.attributes.date)}</p>
                            <p><strong>Vekt:</strong> {currentCheckIn.attributes.weight} kg</p>
                            <p><strong>Trening:</strong> {renderStars(currentCheckIn.attributes.rating_training)}</p>
                            <p><strong>Motivasjon:</strong> {renderStars(currentCheckIn.attributes.rating_motivation)}</p>
                            <p><strong>Kosthold:</strong> {renderStars(currentCheckIn.attributes.rating_nutrition)}</p>
                            <p><strong>Søvn:</strong> {renderStars(currentCheckIn.attributes.rating_sleep)}</p>
                            <p><strong>Din kommentar:</strong> {currentCheckIn.attributes.comment}</p>
                            <p>
                                <strong>Coach kommentar:</strong> { 
                                    currentCheckIn.attributes.coach_comment && currentCheckIn.attributes.coach_comment.trim().length > 3 
                                    ? currentCheckIn.attributes.coach_comment 
                                    : "Din coach har ikke kommentert"
                                }
                            </p>
                        </div>
                        <div className={css.modalImageParent}>
                            {currentCheckIn.attributes.progress_pic?.data?.length > 0 && (
                                <div className={css.images}>
                                    <div>Check-in bilder:</div>
                                    {currentCheckIn.attributes.progress_pic.data.map((pic, index) => (
                                        <Image
                                            key={index}
                                            src={pic.attributes.url}
                                            alt={`Progress ${index}`}
                                            fluid
                                            className={css.largeImage}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={`${css.deleteButton} p-2 col-11`} onClick={handleDelete}>Slett check-in</div>
                    </div>
                </BottomSheetModal>
            )}
        </div>
    );
};

export default CheckInCards;

