import React, { useState } from 'react';
import styles from './styles.module.scss'; // Legg til eventuelle klasser du ønsker for styling
import Image from 'react-bootstrap/Image';

export default function RotatableImage({ src, alt, className }) {
  const [rotation, setRotation] = useState(0);

  const handleRotate = () => {
    // Øk rotasjonen med 90 grader hver gang knappen trykkes
    setRotation(prevRotation => prevRotation + 90);
  };

  return (
    <div className={`${styles.imageWrapper} d-flex flex-column align-items-center col-12`} style={{ position: 'relative', display: 'inline-block' }}>
      <Image
        src={src}
        alt={alt}
        fluid
        className={className}
        style={{ transform: `rotate(${rotation}deg)`, transition: 'transform 0.3s ease' }}
      />
      <button
        onClick={handleRotate}
        className={`${styles.rotateButton}`}
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          padding: '5px 10px',
          background: 'rgba(0,0,0,0.6)',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        Roter
      </button>
    </div>
  );
}