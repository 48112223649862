import { API_GRAPHQL } from "../../../../constants";
import { axiosAuthFetch } from "../../../../helpers/axios";


export async function GetMealsAndInstances(id) {

const query = `
    query {
        coach(id:"${id}"){
            data{
                id
                attributes{
                    clients(pagination: {limit: 50}){
                        data{
                            id
                            attributes{
                                username
                            }
                        }
                    }
                    meals(pagination: {limit: 50}){
                        data{
                            id
                            attributes{
                                name
                                category
                                kcals
                                proteins
                                carbs
                                fats
                                meal_images{
                                    data{
                                    id
                                    attributes{
                                        image{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                meal_instances(pagination: {limit: 50}){
                                    data{
                                        id
                                        attributes{
                                            variant_name
                                            proteins
                                            fats
                                            carbs
                                            kcals
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const response = await axiosAuthFetch( API_GRAPHQL, { query }, 'post')

return response.data;

}