import React from 'react';
import CreateIngredient from '../../global/forms/createIngredient';
import BackButton from '../../global/backButton';

export default function IngredientsPage() {

    
    return(
        <div className="">
            <div className="col-12 d-flex d-md-none">
                <div className="col-2 ms-2">
                    <BackButton/>
                </div>
            </div>
            <CreateIngredient/>
        </div>
    )
}