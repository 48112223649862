// import React, { useMemo, useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router';
// import { GetSpecificMealPlan } from '../../../hooks/newHooks/coachSide/mealPlans';
// import { axiosAuthFetch } from '../../../helpers/axios';
// import { API_BASE_URL } from '../../../constants';
// import { getLocalStorageItem } from '../../global/storage';
// import ResponsiveModal from '../../global/responsiveModal';
// import AddMealModal from '../../global/forms/addMealModal';
// import styles from './styles.module.scss';  // Bruk din egen SCSS-fil
// import { getClients } from '../../../hooks/newHooks/coachSide/clientInfo';
// import CheckmarkIcon from '../../global/checkmarkIcon';
// import MacroDistributionChart from '../../makroChart';

// export default function SpecificMealPlanPage() {
//   const { mealplanId } = useParams();
//   const userInfo = getLocalStorageItem('limitless-user');
//   const user = userInfo.user;
//   const coachId = user.coachID;
//   const navigate = useNavigate();
//   const [mealSlots, setMealSlots] = useState([]);

//   // Hovedstate
//   const [isLoading, setIsLoading] = useState(false);
//   const [mealPlan, setMealPlan] = useState(null);
//   const [editedPlan, setEditedPlan] = useState({});
//   const [modalOpen, setModalOpen] = useState(false);
//   const [allMeals, setAllMeals] = useState([]);
//   const [clients, setClients] = useState([]);
//   const [coachClients, setCoachClients] = useState([]);
//   //const [showClientModal, setShowClientModal] = useState(false);
//   const [editing, setEditing] = useState(false);

//   // Hent matplanen ved mount
//   useEffect(() => {
//     async function getMealPlan() {
//       setIsLoading(true);
//       try {
//         const response = await GetSpecificMealPlan(mealplanId);
//         console.log('MEALPLAN', response);
//         const planData = response.data.mealPlan.data;
//         const attributes = planData.attributes;
//         // Transformer slik at meals og meal_instances blir arrays med string-ID-er
//         const transformedPlan = {
//           ...attributes,
//           meals: attributes.meals?.data?.map(meal => String(meal.id)) || [],
//           meal_instances: attributes.meal_instances?.data?.map(inst => String(inst.id)) || [],
//           clients: attributes.clients?.data?.map(client => String(client.id)) || []
//         };
//         setMealPlan(planData);
//         setEditedPlan(transformedPlan);
//         setClients(attributes.clients?.data || []);
//         setAllMeals(combineMeals(attributes));
//       } catch (error) {
//         console.error('Error fetching meal plan:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//     getMealPlan();
//   }, [mealplanId]);


//   useEffect(() => {
//     const fetchCoachClients = async () => {
//         try {
//           const response = await getClients(coachId);
//           console.log('CLIENTS', response);
//           setCoachClients(response.coach.data.attributes.clients.data);
//         } catch (error) {
//           console.error("Error fetching coach clients:", error);
//         } finally {
//           setIsLoading(false);
//         }
//       };
//     fetchCoachClients();
//   },[coachId]);

  


//   // Kombiner meals og instanser til en flat struktur, med et ekstra felt 'imageUrl'
//   function combineMeals(attributes) {
//     const mealArray = attributes.meals?.data || [];
//     const instanceArray = attributes.meal_instances?.data || [];
//     const combined = [];
  
//     // Hovedmåltider
//     mealArray.forEach(item => {
//       const imageUrl = item.attributes.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url || 'fallback.jpg';
//       combined.push({
//         id: String(item.id),
//         name: item.attributes.name,
//         kcals: item.attributes.kcals,
//         protein: item.attributes.proteins,
//         fat: item.attributes.fats,
//         carbs: item.attributes.carbs,
//         type: 'meal',
//         category: item.attributes.category || "Uten kategori",
//         baseMealId: String(item.id),
//         imageUrl,
//         attributes: item.attributes
//       });
//     });
  
//     // Instanser (varianter)
//     instanceArray.forEach(item => {
//       const linkedMeal = item.attributes.meal?.data;
//       const linkedMealName = linkedMeal?.attributes?.name || '';
//       // Hent bildet fra linkedMeal
//       const imageUrl = linkedMeal?.attributes?.meal_images?.data?.[0]?.attributes?.image?.data?.attributes?.url || 'fallback.jpg';
//       combined.push({
//         id: String(item.id),
//         name: `${item.attributes.variant_name} av ${linkedMealName}`,
//         kcals: item.attributes.kcals,
//         protein: item.attributes.proteins,
//         fat: item.attributes.fats,
//         carbs: item.attributes.carbs,
//         type: 'instance',
//         category: linkedMeal?.attributes?.category || "Uten kategori",
//         baseMealId: linkedMeal ? String(linkedMeal.id) : null,
//         imageUrl,
//         attributes: item.attributes
//       });
//     });
  
//     return combined;
//   }

//   // computePlanMacros: For hver kategori, finn først elementet blant de valgte (basert på unionen)
//   // Hvis ingen er valgt for en kategori, bruk det første elementet fra allMeals for den kategorien.
//   function computePlanMacros(allMeals, selectedMealIds, categories) {
//     let totalProtein = 0;
//     let totalCarbs = 0;
//     let totalFat = 0;
//     let totalKcals = 0;
    
//     categories.forEach(category => {
//       let mealsInCategory = allMeals.filter(meal =>
//         selectedMealIds.includes(meal.id) && meal.category === category
//       );
//       if (mealsInCategory.length === 0) {
//         mealsInCategory = allMeals.filter(meal => meal.category === category);
//       }
//       if (mealsInCategory.length > 0) {
//         const meal = mealsInCategory[0];
//         totalProtein += Number(meal.protein) || 0;
//         totalCarbs += Number(meal.carbs) || 0;
//         totalFat += Number(meal.fat) || 0;
//         totalKcals += Number(meal.kcals) || 0;
//       }
//     });
    
//     return { totalProtein, totalCarbs, totalFat, totalKcals };
//   }

//   function handleFieldChange(e) {
//     const { name, value } = e.target;
//     setEditedPlan(prev => ({ ...prev, [name]: value }));
//   }

//   async function handleSaveChanges() {
//     try {
//       setIsLoading(true);
//       const response = await axiosAuthFetch(
//         `${API_BASE_URL}/meal-plans/${mealPlan.id}`,
//         { data: editedPlan },
//         'PUT'
//       );
//       console.log("Plan updated:", response);
//       setMealPlan(prev => ({
//         ...prev,
//         attributes: editedPlan
//       }));
//       setEditing(false);
//     } catch (error) {
//       console.error("Error updating plan:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }

//   async function disconnectMealOrInstance(id, type) {
//     try {
//       setIsLoading(true);
//       let payload = {};
//       if (type === 'meal') {
//         payload = { data: { meals: { disconnect: [id] } } };
//       } else if (type === 'instance') {
//         payload = { data: { meal_instances: { disconnect: [id] } } };
//       }
//       const response = await axiosAuthFetch(
//         `${API_BASE_URL}/meal-plans/${mealplanId}`,
//         payload,
//         'PUT'
//       );
//       console.log('Disconnected successfully:', response);
  
//       setEditedPlan(prev => ({
//         ...prev,
//         meals: type === 'meal'
//           ? prev.meals.filter(mealId => mealId !== id)
//           : prev.meals,
//         meal_instances: type === 'instance'
//           ? prev.meal_instances.filter(instId => instId !== id)
//           : prev.meal_instances,
//       }));
//       setAllMeals(prev => prev.filter(meal => meal.id !== id));
//     } catch (error) {
//       console.error("Error disconnecting meal/instance:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }

//   // Callback fra AddMealModal – hindrer duplikat for samme basismåltid:
//   const handleAddMealFromModal = (mealId, instanceId, mealDetails) => {
//     const baseMealId = String(mealDetails.id);
//     // Sjekk om et element for dette basismåltidet allerede er lagt til
//     const alreadyAdded = (editedPlan.meals && editedPlan.meals.includes(baseMealId)) ||
//                          (editedPlan.meal_instances && editedPlan.meal_instances.some(instId => {
//                            const variant = allMeals.find(m => m.id === instId && m.type === 'instance');
//                            return variant && variant.baseMealId === baseMealId;
//                          }));
//     if (alreadyAdded) {
//       alert("Du kan kun legge til én variant (eller hovedmåltid) for dette måltidet.");
//       return;
//     }
//     if (instanceId) {
//       // Variant valgt: legg kun til variant
//       setEditedPlan(prev => ({
//         ...prev,
//         meal_instances: prev.meal_instances ? [...prev.meal_instances, String(instanceId)] : [String(instanceId)]
//       }));
//       setAllMeals(prev => {
//         if (prev.find(m => m.id === String(instanceId) && m.type === 'instance')) {
//           return prev;
//         }
//         const instanceData = mealDetails.attributes.meal_instances?.data?.find(inst => String(inst.id) === String(instanceId));
//         let displayName = mealDetails.attributes.name;
//         if (instanceData) {
//           displayName = `${instanceData.attributes.variant_name} av ${mealDetails.attributes.name}`;
//         }
//         const newVariant = {
//           id: String(instanceId),
//           name: displayName,
//           kcals: instanceData ? instanceData.attributes.kcals : mealDetails.attributes.kcals,
//           protein: instanceData ? instanceData.attributes.proteins : mealDetails.attributes.proteins,
//           fat: instanceData ? instanceData.attributes.fats : mealDetails.attributes.fats,
//           carbs: instanceData ? instanceData.attributes.carbs : mealDetails.attributes.carbs,
//           type: 'instance',
//           baseMealId: baseMealId,
//           category: mealDetails.attributes.category || "Uten kategori",
//           attributes: mealDetails.attributes
//         };
//         return [...prev, newVariant];
//       });
//     } else {
//       // Hovedmåltid: legg til bare hovedmåltid
//       setEditedPlan(prev => ({
//         ...prev,
//         meals: prev.meals ? [...prev.meals, baseMealId] : [baseMealId]
//       }));
//       setAllMeals(prev => {
//         if (prev.find(m => m.id === baseMealId && m.type === 'meal')) {
//           return prev;
//         }
//         const newMeal = {
//           id: baseMealId,
//           name: mealDetails.attributes.name,
//           kcals: mealDetails.attributes.kcals,
//           protein: mealDetails.attributes.proteins,
//           fat: mealDetails.attributes.fats,
//           carbs: mealDetails.attributes.carbs,
//           type: 'meal',
//           baseMealId: baseMealId,
//           category: mealDetails.attributes.category || "Uten kategori",
//           attributes: mealDetails.attributes
//         };
//         return [...prev, newMeal];
//       });
//     }
//   };

//   const handleModalClose = () => {
//     setModalOpen(false);
//   };

//   const handleOpenModal = () => {
//     setModalOpen(true);
//   };

//   async function handleCopyPlan() {
//     try {
//       setIsLoading(true);
//       const copiedPlan = {
//         ...editedPlan,
//         name: editedPlan.name + ' - Kopi',
//         coach: coachId,
//         meals: mealPlan.attributes.meals.data.map(meal => String(meal.id)),
//         meal_instances: mealPlan.attributes.meal_instances.data.map(instance => String(instance.id)),
//         clients: []
//       };
//       const response = await axiosAuthFetch(
//         `${API_BASE_URL}/meal-plans`,
//         { data: copiedPlan },
//         'POST'
//       );
//       console.log("Plan copied successfully:", response);
//       alert('Plan kopiert!');
//       navigate(-1);
//     } catch (error) {
//       console.error("Error copying plan:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   function handleClientSelect(e) {
//     const selectedId = e.target.value;
//     if (!selectedId) return;
//     // Finn klientobjektet fra coachClients
//     const client = coachClients.find(c => c.id === selectedId);
//     if (client) {
//       // Sjekk om klienten allerede har en tilknyttet matplan
//       const existingMealPlans = client.attributes.meal_plans?.data || [];
//       if (existingMealPlans.length > 0) {
//         const confirmation = window.confirm(
//           "En klient kan kun ha en matplan - hvis du velger å tildele klienten denne planen blir den andre matplanen fjernet - fortsette?"
//         );
//         if (!confirmation) {
//           // Avbryt operasjonen hvis brukeren ikke bekrefter
//           return;
//         }
//         // Hvis det finnes en eksisterende plan, koble fra den første (eller den eneste) matplanen
//         const oldMealPlanId = existingMealPlans[0].id;
//         disconnectClientFromMealPlan(client.id, oldMealPlanId);
//       }
//       // Sjekk om klienten allerede er lagt til i den nye planen
//       if (editedPlan.clients && editedPlan.clients.includes(selectedId)) {
//         alert("Denne klienten er allerede lagt til.");
//         return;
//       }
//       // Legg til klienten i editedPlan (ny matplan)
//       setEditedPlan(prev => ({
//         ...prev,
//         clients: prev.clients ? [...prev.clients, selectedId] : [selectedId]
//       }));
//       // Oppdater visningen (clients-state) slik at den nye klienten vises
//       setClients(prev => {
//         if (prev.find(c => c.id === selectedId)) return prev;
//         return [...prev, client];
//       });
//     }
//   }

//   async function disconnectClientFromMealPlan(clientId, mealPlanId) {
//     try {
//       const payload = {
//         data: {
//           clients: {
//             disconnect: [clientId]
//           }
//         }
//       };
//       const response = await axiosAuthFetch(
//         `${API_BASE_URL}/meal-plans/${mealPlanId}`,
//         payload,
//         'PUT'
//       );
//       console.log(`Client ${clientId} disconnected from meal plan ${mealPlanId}:`, response);
//     } catch (error) {
//       console.error("Error disconnecting client from meal plan:", error);
//     }
//   }

//   async function deletePlan(id) {
//     const confirmed = window.confirm("Er du sikker på at du vil slette planen?");
//     if (!confirmed) return;

//     try{
//         setIsLoading(true);
//         const response = await axiosAuthFetch(`${API_BASE_URL}/meal-plans/${mealplanId}`,{},'delete');
//         console.log(response);
//         alert('Planen er slettet!');
//         navigate(-1);
//     } catch(error) {
//         console.error(error);
//     } finally {
//         setIsLoading(false);
//     }
//   }

//   async function disconnectClient(clientId) {
//     try {
//       setIsLoading(true);
//       const payload = {
//         data: {
//           clients: {
//             disconnect: [clientId]
//           }
//         }
//       };
//       const response = await axiosAuthFetch(
//         `${API_BASE_URL}/meal-plans/${mealplanId}`,
//         payload,
//         'PUT'
//       );
//       console.log("Client disconnected:", response);
//       setEditedPlan(prev => ({
//         ...prev,
//         clients: prev.clients ? prev.clients.filter(id => id !== clientId) : []
//       }));
//       setClients(prev => prev.filter(client => client.id !== clientId));
//     } catch (error) {
//       console.error("Error disconnecting client:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }

//   if (isLoading || !mealPlan || !mealPlan.attributes) {
//     return <div>Laster inn matplan...</div>;
//   }

//   return (
//     <>
//     <div className={`${styles.editButton} m-auto mt-4`} onClick={handleCopyPlan}>Kopier plan</div>
//       <div className={`${styles.removeButton} m-auto mt-4`} onClick={deletePlan}>Slett plan</div>

//       <ResponsiveModal isOpen={modalOpen} onClose={handleModalClose}>
//         <AddMealModal 
//           coachId={coachId}
//           onClose={handleModalClose}
//           onAddMeal={handleAddMealFromModal}
//         />
//       </ResponsiveModal>
//     </>

      
//   );
// }


import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { GetSpecificMealPlan } from '../../../hooks/newHooks/coachSide/mealPlans';
//import { axiosAuthFetch } from '../../../helpers/axios';
//import { API_BASE_URL } from '../../../constants';
//import { getLocalStorageItem } from '../../global/storage';
//import ResponsiveModal from '../../global/responsiveModal';
//import AddMealModal from '../../global/forms/addMealModal';
import styles from './styles.module.scss';
//import { getClients } from '../../../hooks/newHooks/coachSide/clientInfo';
import MacroDistributionChart from '../../makroChart';
import fallbackImage from '../../../images/mat.png';
import BackButton from '../../global/backButton';
//import MealDetail from '../../layouts/meals/detail';
//import { ClientSingleInstance, ClientSingleMeal } from '../../../hooks/specificMealPage';

export function ClientCard({ client }) {

    const username = client.attributes.username || '';
    const initials = username.substring(0, 2).toUpperCase();
  
    return (
      <div key={client.id} className={`${styles.clientCard} shadow rounded-3 p-3`}>
        <div className={styles.avatar}>
          {initials}
        </div>
        <div className={styles.clientName}>
          {username}
        </div>
      </div>
    );
  }

export default function SpecificMealPlanPage() {
  const { mealplanId } = useParams();
  //const userInfo = getLocalStorageItem('limitless-user');
  //const coachId = userInfo.user.coachID;
  const [isLoading, setIsLoading] = useState(false);
  const [mealPlan, setMealPlan] = useState(null);
  //const [editedPlan, setEditedPlan] = useState({});
  const [mealSlots, setMealSlots] = useState([]);
  //const [editing, setEditing] = useState(false);
  //const [modalOpen, setModalOpen] = useState(false);
  //const [allMeals, setAllMeals] = useState([]);
  //const [clients, setClients] = useState([]);
  //const [coachClients, setCoachClients] = useState([]);
  //const [selectedMealData, setSelectedMealData] = useState(null);
  //const [mealLoading, setMealLoading] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    async function getMealPlanData() {
      setIsLoading(true);
      try {
        const response = await GetSpecificMealPlan(mealplanId);
        console.log('MEALPLAN', response);
        const planData = response.data.mealPlan.data;
        const attributes = planData.attributes;
        setMealPlan(attributes);
        setMealSlots(attributes.meal_slot || []);
        //setClients(attributes.clients?.data || []);
      } catch (error) {
        console.error('Error fetching meal plan:', error);
      } finally {
        setIsLoading(false);
      }
    }
    getMealPlanData();
  }, [mealplanId]);

  const handleMealClick = async (mealId, type) => {
    if(type=== 'instance') {
      navigate(`/client/meal/instance/${mealId}`);
    }
    else {
      navigate(`/client/meal/meal/${mealId}`)
    };
    // setModalOpen(true);
    // setMealLoading(true);
    // try {
    //   let response;
    //   if (type === 'instance') {
    //     // Kall for meal_instance
    //     response = await ClientSingleInstance(mealId);
    //     setSelectedMealData({ ...response, isMealInstance: true });
    //   } else {
    //     response = await ClientSingleMeal(mealId);
    //     setSelectedMealData({ ...response, isMealInstance: false });
    //   }
    // } catch (error) {
    //   console.error('Error fetching meal data:', error);
    // } finally {
    //   setMealLoading(false);
    // }
  };

//   useEffect(() => {
//     async function fetchCoachClients() {
//       try {
//         const response = await getClients(coachId);
//         console.log('CLIENTS', response);
//         setCoachClients(response.coach.data.attributes.clients.data);
//       } catch (error) {
//         console.error("Error fetching coach clients:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//     fetchCoachClients();
//   }, [coachId]);

if(isLoading) {
    return(
        <>Laster inn matplan...</>
    )
}

if(mealPlan) {
    return(
        <div className="pt-3">
          <div className="col-2 mb-3 ms-2">
            <BackButton />
          </div>
          <div className={styles.infoContainer}>
            <div className={`${styles.overskrift} text-center`}>{mealPlan.name}</div>
            <div className={`${styles.beskrivelse} col-12 p-2 text-center`}>{mealPlan.description}</div>
            <div className={`${styles.duration} col-12 p-2 text-center`}>Varighet: {mealPlan.duration}</div>
            <div className={`${styles.duration} col-12 p-2 text-center`}>Mål: {mealPlan.goal}</div>
          </div>
            <div className={`${styles.infoContainer}`}>
                <MacroDistributionChart 
                    protein={mealPlan.protein}
                    carbs={mealPlan.carbs}
                    fat={mealPlan.fat}
                    kcals={mealPlan.total_kcals}
                />
                <div className={`text-center ${styles.kalorier} mt-3`}>Kalorier: {mealPlan.total_kcals} kcal</div>
                <div className={`d-flex align-items-center justify-content-between col-12 px-2 pb-3 mb-3`}>
                    <div className={`${styles.beskrivelse}`}>Protein: {mealPlan.protein} g</div>
                    <div className={`${styles.beskrivelse}`}>Carbs: {mealPlan.carbs} g</div>
                    <div className={`${styles.beskrivelse}`}>Fett: {mealPlan.fat} g</div>
                </div>
            </div>
            <div className={styles.mealSlotsDisplay}>
                {mealSlots.map((slot, index) => (
                    <div key={index} className={styles.categorySection}>
                        <h3>{slot.meal_name}</h3>
                        <div className={styles.horizontalScrollContainer}>
                        {/* Kort for meals */}
                        {slot.meals && slot.meals.data && slot.meals.data.length > 0 &&
                            slot.meals.data.map((meal, i) => {
                            const imageUrl =
                                meal.attributes.meal_images &&
                                meal.attributes.meal_images.data &&
                                meal.attributes.meal_images.data.length > 0
                                ? meal.attributes.meal_images.data[0].attributes.image.data.attributes.url
                                : fallbackImage;
                            return (
                                <div className={styles.container99}>
                                  <div
                                  key={`meal-${i}`}
                                  className={styles.mealCard}
                                  style={{ backgroundImage: `url(${imageUrl})` }}
                                  onClick={() => handleMealClick(meal.id, "meal")}
                                  >
                                  </div>
                                  <div className={styles.mealName}>
                                    {meal.attributes.name}
                                  </div>
                                </div>
                                
                            );
                            })
                        }
                        {/* Kort for meal_instances */}
                        {slot.meal_instances && slot.meal_instances.data && slot.meal_instances.data.length > 0 &&
                            slot.meal_instances.data.map((inst, i) => {
                            // Hent bildet fra den tilknyttede meal for instansen
                            const linkedMeal = inst.attributes.meal.data;
                            const imageUrl =
                                linkedMeal &&
                                linkedMeal.attributes.meal_images &&
                                linkedMeal.attributes.meal_images.data &&
                                linkedMeal.attributes.meal_images.data.length > 0
                                ? linkedMeal.attributes.meal_images.data[0].attributes.image.data.attributes.url
                                : fallbackImage;
                            return (
                              <div className={styles.container99}>
                                <div
                                  key={`inst-${i}`}
                                  className={styles.mealCard}
                                  style={{ backgroundImage: `url(${imageUrl})` }}
                                  onClick={() => handleMealClick(inst.id, "instance")}
                                  >
                                  {/* <div className={styles.cardOverlay}>
                                      {inst.attributes.variant_name}
                                  </div> */}
                                </div>
                                <div className={styles.mealName}>
                                  {linkedMeal.attributes.name}
                                </div>
                              </div>
                                
                            );
                            })
                        }
                        </div>
                    </div>
                ))}
            </div>
            {/* <ResponsiveModal 
                isOpen={modalOpen}
                onClose={()=> setModalOpen(false)}
            >
                {mealLoading ? (
                <p>Laster måltidsinformasjon...</p>
                ) : (
                <MealDetail mealData={selectedMealData} />
                )}
            </ResponsiveModal> */}
        </div>
    )
}
}
