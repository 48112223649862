import React  from 'react';
import NavCss from './nav.module.css';
import { NavLink } from 'react-router-dom';
import { getLocalStorageItem } from '../storage';
import { ReactComponent as HomeIcon } from '../../../images/icons/house.svg';
import { ReactComponent as ChatIcon } from '../../../images/icons/chat.svg';
import { ReactComponent as WeightIcon } from '../../../images/icons/weight.svg';
import { ReactComponent as UserIcon } from '../../../images/icons/user.svg';
import { ReactComponent as MealsIcon } from '../../../images/icons/fork.svg';

export default function NavBar() {

    const userInfo = getLocalStorageItem('limitless-user');
    const isCoach = userInfo.user.isCoach;
    const isAdmin = userInfo.user.isAdmin;


    if(isCoach || isAdmin){
        return(
        <div className={NavCss.container}>
            <NavLink to="/home" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}  aria-label="Hjem">
                <div className={NavCss.iconContainer}>
                    {/* <Image src={homeIcon} fluid alt="Home Icon" /> */}
                    <HomeIcon className={NavCss.icon} alt="Test Icon" />
                </div>
                <h2 className={NavCss.h2}>Hjem</h2>
            </NavLink>
            <NavLink to="/clients" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                <div className={NavCss.iconContainer}>
                    <UserIcon className={NavCss.icon} alt="User Icon" />
                </div>
                <h2 className={NavCss.h2}>Klienter</h2>
            </NavLink>
            <NavLink to="/training" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                <div className={NavCss.iconContainer}>
                    <WeightIcon className={NavCss.icon} alt="Weight Icon" />
                </div>
                <h2 className={NavCss.h2}>Trening</h2>
            </NavLink>
            <NavLink to="/communication" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                <div className={NavCss.iconContainer}>
                    <ChatIcon className={NavCss.icon} alt="Chat Icon" />
                </div>
                <h2 className={NavCss.h2}>Chat</h2>
            </NavLink>
            <NavLink to ="/meals" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                <div className={NavCss.iconContainer}>
                    <MealsIcon className={NavCss.icon} alt="Logout Icon" />
                </div>
                <h2 className={NavCss.h2}>Måltider</h2>
            </NavLink>
        </div>
    )
    }
    if(!isCoach) {
        return(
            <div className={NavCss.container}>
                <NavLink to="/home" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2} aria-label="Hjem">
                    <div className={NavCss.iconContainer}>
                        <HomeIcon className={NavCss.icon} alt="Test Icon" />
                    </div>
                    <h2 className={NavCss.h2}>Hjem</h2>
                </NavLink>
                <NavLink to="/profile" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                    <div className={NavCss.iconContainer}>
                        <UserIcon className={NavCss.icon} alt="User Icon" />
                    </div>
                    <h2 className={NavCss.h2}>Profil</h2>
                </NavLink>
                <NavLink to="/training" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                    <div className={NavCss.iconContainer}>
                        <WeightIcon className={NavCss.icon} alt="Weight Icon" />
                    </div>
                    <h2 className={NavCss.h2}>Trening</h2>
                </NavLink>
                <NavLink to="/chat" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                    <div className={NavCss.iconContainer}>
                        <ChatIcon className={NavCss.icon} alt="Chat Icon" />
                    </div>
                    <h2 className={NavCss.h2}>Chat</h2>
                </NavLink>
                <NavLink to ="/mealplans" className={({ isActive}) => isActive ? `${NavCss.container2} ${NavCss.activeLink}` : NavCss.container2}>
                    <div className={NavCss.iconContainer}>
                        <MealsIcon className={NavCss.icon} alt="Logout Icon" />
                    </div>
                    <h2 className={NavCss.h2}>Måltider</h2>
                </NavLink>
            </div>
        )
    }
}