import React, { useState, useEffect } from 'react';
import MealPlansList from '../../coaches/mealPlansList';
import { getLocalStorageItem } from '../../global/storage';
import GetClientMealPlans from '../../../hooks/newHooks/clientSide/getMealPlans';
import Loader from '../../global/loader';

export default function ClientSideMealPlansPage() {

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;
    const userId = user.id;
    const [mealPlans, setMealPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        async function getMealPlans() {
            const response = await GetClientMealPlans(userId);
            console.log(response);
            setMealPlans(response.data);
            setIsLoading(false);
        }
        getMealPlans();
//eslint-disable-next-line
    },[])

    if(isLoading) {
        return(
            <Loader />
        )
    }

    return (
        <div className="height">
            <h1 className="text-center mt-3">DINE MATPLANER</h1>
            <div>
                {mealPlans.length > 0 ? (
                    mealPlans.map((mealplan) => (
                        <MealPlansList key={mealplan.id} mealplan={mealplan}/>
                    ))
                ): (
                    <div>Ingen matplaner tilgjengelig</div>
                )}
                
            </div>
        </div>
    )
}