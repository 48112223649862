import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

export default function MacroDistributionChart({ protein, carbs, fat, title = "Makrofordeling" }) {
    // Beregn kalorier fra hver makronutrient
    const caloriesFromProtein = protein * 4;
    const caloriesFromCarbs = carbs * 4;
    const caloriesFromFat = fat * 9;
    const totalCalories = caloriesFromProtein + caloriesFromCarbs + caloriesFromFat;

    // Beregn prosentandel av hver makro
    const proteinPercentage = totalCalories > 0 ? (caloriesFromProtein / totalCalories) * 100 : 0;
    const carbsPercentage = totalCalories > 0 ? (caloriesFromCarbs / totalCalories) * 100 : 0;
    const fatPercentage = totalCalories > 0 ? (caloriesFromFat / totalCalories) * 100 : 0;

    const doughnutData = {
        labels: ['Protein', 'Karbohydrater', 'Fett'],
        datasets: [
            {
                data: [proteinPercentage, carbsPercentage, fatPercentage],
                backgroundColor: ['#2ecc71', '#e4e4e4', '#FF6384'],
                hoverBackgroundColor: ['#FFCE56', '#e9e9e9', '#FF6384'],
            },
        ],
    };

    const options = {
        cutout: '75%', // Øker cutout for en tynnere ring
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      };

    return (
        <div className="d-flex flex-column align-items-center py-2">
            <h4 className="text-center">{title}</h4>
            <div style={{ width: '250px', height: '250px' }}>
                <Doughnut data={doughnutData} options={options} />
            </div>
        </div>
    );
}
